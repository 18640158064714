
import HttpUtils from '../utils/HttpUtils'
import {getFilters} from "../utils/browserUtils";
const baseUrl = process.env.REACT_APP_BASE_API


async function queryProviderList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_provider_list", params,token)
}

async function providerRegister(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_provider_register", params,token)
}

async function providerModify(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_provider_modify", params,token)
}

async function removeProvider(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_provider_remove", params,token)
}

async function providerList(){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_provider_enable", {},token)
}


const ProviderApi ={
    queryProviderList:queryProviderList,
    providerRegister:providerRegister,
    providerModify:providerModify,
    removeProvider:removeProvider,
    providerList:providerList,
}

export default ProviderApi;

import HttpUtils from '../utils/HttpUtils'
import {getFilters} from "../utils/browserUtils";
const baseUrl = process.env.REACT_APP_BASE_API

async function queryPreorderList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_market_queryPreorder", params,token)
}

async function inserPreorderMsg(params){
    const token = getFilters("token");

    const res =  await HttpUtils.sendRequest(baseUrl, "aop_market_insertPreorder", params,token);
    console.log(res)

    return res;
}


async function deletePreorderMsg(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_market_deletePreorder", params,token)
}

async function resendEmailForPreorder(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_market_resendEmail", params,token)
}

async function queryContryList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_market_queryCountryList", params,token)
}

async function queryCityLatLongList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_market_queryCityLatLongList", params,token)
}

async function queryMinerListForHardware(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_market_queryMinerListForHardware", params,token)
}

async function terminateMinerList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_market_terminateMinerList", params,token)
}

async function powerQualityCheckByOwner(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_market_powerQualityCheckByOwner", params,token)
}




const MarketServiceApi ={
    queryPreorderList:queryPreorderList,
    inserPreorderMsg:inserPreorderMsg,
    deletePreorderMsg:deletePreorderMsg,
    resendEmailForPreorder:resendEmailForPreorder,
    queryCityLatLongList:queryCityLatLongList,
    queryContryList:queryContryList,
    queryMinerListForHardware:queryMinerListForHardware,
    terminateMinerList:terminateMinerList,
    powerQualityCheckByOwner:powerQualityCheckByOwner,
}

export default MarketServiceApi;

import React from "react";
import {Space, Table, Tag, Row, Col, Button, Modal, Input, message, Radio, Tree, Pagination} from 'antd';
import {PlusCircleOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import RoleApi from "../../../api/roleApi";
import MenuApi from "../../../api/menuApi";


class Role extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            columns:true,
            data:false,
            showAdd:false,
            showEdit:false,
            confirmLoading:false,
            selectedKeys:[],
            menuList:[],
            menuinfo:{
                name:undefined,
                menu:[],
            },
            roleSearch:{}
        };
        this.check = this.check.bind(this)
        this.onCheck = this.onCheck.bind(this)
        this.showAddModal = this.showAddModal.bind(this)
        this.handleAddCancel = this.handleAddCancel.bind(this)
        this.initMenuList = this.initMenuList.bind(this)
        this.handleAddOk = this.handleAddOk.bind(this)
        this.onNameChange = this.onNameChange.bind(this)
        this.handleEditOk = this.handleEditOk.bind(this)
        this.handleEditCancel = this.handleEditCancel.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.onStatusChange = this.onStatusChange.bind(this)
        this.checkMenu = this.checkMenu.bind(this)
        this.onPaginationChange = this.onPaginationChange.bind(this)
    }
    async componentWillMount() {
        await this.check();
        this.initMenuList();
    }
    async queryRoleList(){
        let roleList = await RoleApi.queryRoleAllList();
        return roleList;
    }
    async check() {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                render: (status) => {
                    let color = 'red'
                    let text = '已禁用'
                    if(status == 1){
                        color = 'green'
                        text = '正常'
                    }
                    return (
                        <Tag color={color} key={text}>{text}</Tag>
                    );
                }
            },
            {
                title: 'UpdateTime',
                dataIndex: 'updateTime',
                key: 'updateTime',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'CreateTime',
                dataIndex: 'createTime',
                key: 'createTime',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Action',
                key: 'action',
                fixed: 'right',
                width:200,
                render: (_, record) => (
                    <Space size="middle">
                        <Button
                            type="primary"
                            size='small'
                            icon={<EditOutlined />}
                            onClick={() => {
                                this.handleEdit(record)
                            }}>
                            Edit
                        </Button>
                        <Button size='small' type="primary" icon={<DeleteOutlined />} onClick={()=>{
                                Modal.confirm({
                                    title: 'Are you sure delete role：',
                                    icon: <ExclamationCircleOutlined />,
                                    content: record.name,
                                    okText: 'Yes',
                                    cancelText: 'Cancel',
                                    onOk:()=>{this.handleDelete(record)}
                                });
                            }}>
                            Delete
                        </Button>
                    </Space>
                ),
            },
        ];
        this.setState({columns:columns})
        // 查询角色列表
        let roleSearch = this.state.roleSearch
        let resp = await this.queryRoleList(roleSearch);
        if(!resp.error){
            let result = resp.result
            if(!result){
                return;
            }
            const data=[]
            if(result.list.length>0){
                this.setState({totalCount:result.count})
                result.list.forEach(item =>{
                    const user = {
                        id:item.id,
                        key:item.id,
                        name:item.name,
                        status:item.status,
                        updateTime:item.updateTime.replace("T"," ").replace(".000Z",""),
                        createTime:item.createTime.replace("T"," ").replace(".000Z",""),
                    }
                    data.push(user)
                })
            }
            this.setState({data:data})
        }else {
            this.setState({data:undefined})
        }
    }
    initMenuList(){
        MenuApi.queryMenuList().then(resp => {
            if(!resp.error){
                let result = [];
                if(!resp.result.length){
                    result.push(resp.result)
                }else {
                    result = resp.result
                }
                //
                let menuList = []
                result.forEach(item => {
                    if(item.parentId==null){
                        let menu = {
                            key: item.id,
                            title: item.name,
                            children: []
                        }
                        result.forEach(row => {
                            if(row.parentId == item.id){
                                let m = {
                                    key: row.id,
                                    title: row.name,
                                }
                                menu.children.push(m)
                            }
                        })
                        if(menu.children.length==0){
                            menu.children = undefined
                        }
                        menuList.push(menu)
                    }
                })
                this.setState({menuList:menuList})
                console.log(this.state.menuList)
            }
        })
    }
    onCheck(checkedKeys, e){
        this.setState({selectedKeys:checkedKeys})
        let menuinfo = this.state.menuinfo
        menuinfo.menu = checkedKeys
        this.setState({menuinfo:menuinfo})
    }
    handleAddCancel(){
        this.setState({showAdd:false})
        this.setState({selectedKeys:[]})
    }
    onNameChange(e){
        const name = e.target.value
        let menuinfo = this.state.menuinfo
        menuinfo.name = name
        this.setState({menuinfo:menuinfo})
    }
    handleAddOk(){
        const menuinfo = this.state.menuinfo;
        console.log("menuinfo:"+JSON.stringify(menuinfo))
        if(!menuinfo.name){
            message.error("请输入角色名称！")
            return;
        }
        if(menuinfo.menu.length==0){
            message.error("请至少选择一个菜单！")
            return;
        }
        RoleApi.addRole(menuinfo).then(resp => {
            if(!resp.error){
                message.success("角色添加成功！")
                this.setState({showAdd:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
        })
    }
    checkMenu(menus){
        const menuList = this.state.menuList
        menus.forEach(item => {
            menuList.forEach(menu => {

            })
        })
    }
    async handleDelete(record) {
        RoleApi.delRole({roleId: record.id}).then(resp => {
            if (!resp.error) {
                message.success("角色删除成功！")
                this.check();
            } else {
                message.error(resp.error.message)
            }
        })
    }
    showAddModal(){
        this.setState({showAdd:true})
    }
    handleEdit(role){
        const menuinfo = {
            name:role.name,
            roleId:role.id,
            status:role.status
        }
        RoleApi.queryRoleMenu({roleId:role.id}).then(resp => {
            let selectedKeys = []
            resp.result.forEach(item => {
                selectedKeys.push(item.menuId)
            })
            this.setState({selectedKeys:selectedKeys})
            this.setState({menuinfo:menuinfo})
            this.setState({showEdit:true})
        })

    }
    handleEditCancel(){
        this.setState({showEdit:false})
    }
    onStatusChange(e){
        let menuinfo = this.state.menuinfo
        menuinfo.status = e.target.value
        this.setState({menuinfo:menuinfo})
    }
    handleEditOk(){
        let menuinfo = this.state.menuinfo;
        console.log("userinfo:"+JSON.stringify(menuinfo))
        if(!menuinfo.name){
            message.error("请输入角色名称！")
            return;
        }
        if(!menuinfo.roleId){
            message.error("请至少分配一个菜单！")
            return;
        }
        if(!menuinfo.menu){
            menuinfo.menu = this.state.selectedKeys
        }
        //
        RoleApi.updateRole(menuinfo).then(resp => {
            console.log(resp)
            if(!resp.error){
                message.success("角色修改成功！")
                this.setState({showEdit:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
        })
    }
    onPaginationChange(page, pageSize){
        let roleSearch = this.state.roleSearch
        roleSearch.offset = page
        roleSearch.size = pageSize
        this.setState({roleSearch:roleSearch})
        this.check();
    }
    render() {
        //const { getFieldDecorator} = this.props.form
        return (
            <div style={{width:'100%',height:'100%',overflow:"hidden"}}>
                <div>
                    <Row>
                        <Col span={12}>
                            <span style={{fontWeight:'bold',fontSize:18}}>
                                Role Manage
                            </span>
                        </Col>
                        <Col span={12}>
                            <div style={{textAlign:'right'}}>
                                <Button size='small' onClick={this.showAddModal} type="primary" icon={<PlusCircleOutlined />}>Add</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
                {
                    this.state.totalCount>10?<div style={{textAlign:'right',paddingTop:15}}>
                        <Pagination onChange={this.onPaginationChange} size="small" total={this.state.totalCount} showSizeChanger showQuickJumper />
                    </div>:''
                }
                <div style={{marginTop:10,width:'100%',height:'100%',overflow:'auto'}}>
                    <Table pagination={false} size='small' columns={this.state.columns} dataSource={this.state.data} />
                </div>
                <Modal
                    title="Add Role"
                    open={this.state.showAdd}
                    destroyOnClose={true}
                    onOk={this.handleAddOk}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleAddCancel}>
                    <div>
                        <Row>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Name:</span>
                            </Col>
                            <Col span={20}>
                                <Input onChange={this.onNameChange} />
                            </Col>
                            <Col span={24} style={{height:10}}>

                            </Col>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Menu:</span>
                            </Col>
                            <Col span={20}>
                                <Tree checkable onCheck={this.onCheck} selectedKeys={this.state.selectedKeys} treeData={this.state.menuList}/>
                            </Col>
                        </Row>
                    </div>
                </Modal>
                <Modal
                    title="Edit Role"
                    open={this.state.showEdit}
                    onOk={this.handleEditOk}
                    destroyOnClose={true}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleEditCancel}>
                    <div>
                        <Row>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Name:</span>
                            </Col>
                            <Col span={20}>
                                <Input onChange={this.onNameChange} value={this.state.menuinfo.name}/>
                            </Col>
                            <Col span={24} style={{height:10}}>

                            </Col>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Role:</span>
                            </Col>
                            <Col span={20}>
                                <Tree checkable onCheck={this.onCheck} checkedKeys={this.state.selectedKeys} treeData={this.state.menuList}/>
                            </Col>
                            <Col span={24} style={{height:10}}>

                            </Col>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Status:</span>
                            </Col>
                            <Col span={20}>
                                <Radio.Group style={{marginTop:5}} onChange={this.onStatusChange} value={this.state.menuinfo.status}>
                                    <Radio value={1}>正常</Radio>
                                    <Radio value={2}>禁用</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default Role;

import React from "react";
import { Space, Table, Tag,Row,Col,Button,Modal,Input,Select,message,Pagination } from 'antd';
import {SearchOutlined, EditOutlined, StopOutlined, ExclamationCircleOutlined,PlusOutlined,PlusCircleOutlined,DeleteOutlined} from '@ant-design/icons';
import {copyText, formatDate} from '../../../utils/browserUtils';
import SocketMinerApi from "../../../api/SocketMinerApi"
import * as ethers from 'ethers'
const { TextArea } = Input;

class SocketMiner extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            columns:true,
            totalCount:0,
            showRegister:false,
            confirmLoading:false,
            showOnboard:false,
            statusList:[
                {value: "orderable", label: "已注册"},
                {value: "onboarded", label: "已绑定"},
                {value: "terminated", label: "已终止"}
            ],
            minerSearch:{

            },
            providerList:[],
            registerMiner:{
                deviceId:undefined,
                ownerAddress:undefined,
                expired_time:undefined
            },
        };
        this.check = this.check.bind(this)
        this.onStatusChange = this.onStatusChange.bind(this)
        this.onMinerAddressChange = this.onMinerAddressChange.bind(this)
        this.onPaginationChange = this.onPaginationChange.bind(this)
        this.SearchMiner = this.SearchMiner.bind(this)
        this.handleEditOk = this.handleEditOk.bind(this)
        this.handleEditCancel = this.handleEditCancel.bind(this)
        this.onEditExpiredTimeChange = this.onEditExpiredTimeChange.bind(this)
        this.handleShowEdit = this.handleShowEdit.bind(this)
        this.onCurrencyChange = this.onCurrencyChange.bind(this)
        this.registerMiner = this.registerMiner.bind(this)
        this.handleRegisterCancel = this.handleRegisterCancel.bind(this)
        this.onDeviceIdChange = this.onDeviceIdChange.bind(this)
        this.handleRegisterOk = this.handleRegisterOk.bind(this)
        this.handleOnboardCancel = this.handleOnboardCancel.bind(this)
        this.handleOnboardOk = this.handleOnboardOk.bind(this)
        this.onOwnerAddressChange = this.onOwnerAddressChange.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.onMinerExpiredTimeChange = this.onMinerExpiredTimeChange.bind(this)
    }
    async componentWillMount() {
        await this.check();
    }
    async queryMinerList(minerSearch){
        console.log(minerSearch.offset)
        let minerList = await SocketMinerApi.queryMinerList(minerSearch)
        return minerList;
    }
    async check() {
        const columns = [
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                width: 300,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Device Id',
                dataIndex: 'minerDeviceId',
                key: 'minerDeviceId',
                width: 300,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Miner Type',
                dataIndex: 'miner_type',
                width: 100,
                key: 'miner_type',
                render: (text) => {return <Tag color={'green'} key={"socketMiner"}>{'socketMiner'}</Tag>},
                
            },
            {
                title: 'Status',
                key: 'status',
                width: 100,
                dataIndex: 'status',
                render: (status) => {
                    let color = 'red'
                    let text = '已终止'
                    if(status === 'orderable'){
                        color = '#1677ff'
                        text = '已注册'
                    }
                    if(status === 'onboarded'){
                        color = 'green'
                        text = '已绑定'
                    }
                    if(status === 'terminated'){
                        color = 'red'
                        text = '已终止'
                    }
                    return (
                        <Tag color={color} key={text}>{text}</Tag>
                    );
                }
            },
            {
                title: 'Owner',
                dataIndex: 'owner',
                width: 300,
                ellipsis:true,
                key: 'owner',
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Create time',
                dataIndex: 'create_time',
                key: 'create_time',
                width: 300,
                render: (text) => {return <span>{text?formatDate(text):''}</span>},
            },
            {
                title: 'Action',
                key: 'action',
                fixed: 'right',
                render: (_, record) => (
                    <Space size="middle">
                        {
                            (record.status==='onboarded'||record.status==='terminated')?"":<Button
                                type="primary"
                                size='small'
                                disabled={false}
                                icon={<EditOutlined />}
                                onClick={() => {this.handleShowEdit(record)}}>
                                Onboard
                            </Button>

                            
                        }

                        {
                            (record.status==='orderable')?
                            <Button size='small' type="primary" icon={<DeleteOutlined />} onClick={()=>{
                                Modal.confirm({
                                title: 'Are you sure delete miner',
                                icon: <ExclamationCircleOutlined />,
                                content: record.name,
                                okText: 'Yes',
                                cancelText: 'Cancel',
                                onOk:()=>{this.handleDelete(record)}
                                    });
                                }}>
                                Delete
                                </Button>
                                :""
                        }
                    </Space>
                ),
            },
        ];
        this.setState({columns:columns})
        // 查询电站列表
        let resp = await this.queryMinerList(this.state.minerSearch);
        if(!resp.error){
            let result = resp.result
            if(!result){
                return;
            }
            const data=[]
            if(result.count>0){
                let minerSearch = this.state.minerSearch
                this.setState({totalCount:result.count})
                for (const item of result.list) {
                    if(minerSearch.status && minerSearch.status !== item.status){
                        continue;
                    }
                    if(minerSearch.address && minerSearch.address !== item.address){
                        continue;
                    }
                    const plant = {
                        key:item.address,
                        minerDeviceId:item.mac,
                        address:item.address,
                        miner_type:item.miner_type,
                        owner:item.owner,
                        // create_time:new Date(item.createTime * 1000).toLocaleDateString()  ,
                        create_time:item.createTime  ,
                        status:item.status
                    }
                    data.push(plant)
                }
            }else {
                this.setState({totalCount:0})
            }
            this.setState({plantList:data})
        }else {
            this.setState({plantList:undefined})
        }
    }
    onStatusChange(value){
        let minerSearch = this.state.minerSearch
        minerSearch.status = value
        this.setState({minerSearch:minerSearch})
    }
    onMinerAddressChange(e){
        let minerSearch = this.state.minerSearch
        minerSearch.address = e.target.value
        this.setState({minerSearch:minerSearch})
    }
    onPaginationChange(page, pageSize){
        let minerSearch = this.state.minerSearch
        minerSearch.offset = page
        minerSearch.size = pageSize
        this.setState({minerSearch:minerSearch})
        this.SearchMiner()
    }
    onDeviceIdChange(e){
        let registerMiner = this.state.registerMiner
        console.log(registerMiner);
        registerMiner.deviceId = e.target.value
        this.setState({registerMiner:registerMiner})
    }

    onMinerExpiredTimeChange(e){
        let registerMiner = this.state.registerMiner
        registerMiner.expired_time = e.target.value
        this.setState({registerMiner:registerMiner})
    }

    onOwnerAddressChange(e){
        let registerMiner = this.state.registerMiner
        console.log(registerMiner);
        registerMiner.ownerAddress = e.target.value
        this.setState({registerMiner:registerMiner})
    }
    SearchMiner(){
        this.check()
    }
    formatDateStr(value){
        if(value){
            return value.replace("T"," ").replace(".000Z","")
        }else {
            return ''
        }
    }
    handleEditOk(){
        const editMinerInfo = this.state.editMinerInfo;
        console.log("editMinerInfo:"+JSON.stringify(editMinerInfo))
        if(!editMinerInfo.address || editMinerInfo.address.length !== 42){
            message.error("Miner信息异常，请刷新页面后重试！")
            return;
        }
        if(!editMinerInfo.expired_time){
            message.error("过期天数不能为空！")
            return;
        }
        if(!editMinerInfo.price.amount){
            message.error("过期天数不能为空！")
            return;
        }
        if(Number(editMinerInfo.price.amount)<=0){
            message.error("请输入正确的过期天数！")
            return;
        }
        if(!editMinerInfo.price.currency){
            message.error("请选择用于支付的货币！")
            return;
        }
        //
        RemoteMinerApi.updateMiner(editMinerInfo).then(resp => {
            console.log(resp)
            if(!resp.error){
                message.success("Miner信息修改成功！")
                this.setState({showEdit:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
        })
    }

    handleOnboardOk(){
        let registerMiner = this.state.registerMiner
        if(!registerMiner.ownerAddress){
            message.error("请输入Miner 钱包地址！")
            return;
        }
        if(!ethers.utils.isAddress(registerMiner.ownerAddress)){
            message.error("请输入正确的钱包地址！")
            return;
        }

        SocketMinerApi.onboardMiners(registerMiner.deviceId,registerMiner.ownerAddress).then(resp => {
            if(!resp.error){
                message.success("Standard Miner onboard成功！")
                this.setState({showOnboard:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
            this.setState({confirmLoading:false})
        })

    }
    handleEditCancel(){
        this.setState({showEdit:false})
    }
    onEditExpiredTimeChange(e){
        let editMinerInfo = this.state.editMinerInfo
        editMinerInfo.expired_time = e.target.value
        this.setState({editMinerInfo:editMinerInfo})
    }
    handleShowEdit(record){
        // let editMinerInfo = this.state.editMinerInfo;
        // editMinerInfo.address = record.address
        // editMinerInfo.expired_time = Math.round(record.expired_time/86400)
        // editMinerInfo.price = record.priceDetail
        // this.setState({editMinerInfo:editMinerInfo})
        // console.log("editMinerInfo:"+JSON.stringify(editMinerInfo))
        
        // this.setState({showEdit:true})
        let registerMiner = this.state.registerMiner
        registerMiner.deviceId = record.minerDeviceId
        this.setState({registerMiner:registerMiner})
        this.setState({showOnboard:true})
    }

    handleDelete(record){

        if(!record.minerDeviceId){
            message.error("Miner所属的deviceId 为空！")
            return;
        }
        this.setState({confirmLoading:true})
        SocketMinerApi.deleteMiner(record.minerDeviceId).then(resp => {
            if(!resp.error){
                message.success("Socket Miner删除成功！")
                this.check()
            }else {
                message.error(resp.error.message)
            }
            this.setState({confirmLoading:false})
        })
    }
    onCurrencyChange(value){
        let editMinerInfo = this.state.editMinerInfo;
        editMinerInfo.price.currency = value
        this.setState({editMinerInfo:editMinerInfo})
    }

    registerMiner(){
        this.setState({showRegister:true})
    }
    handleRegisterOk(){
        let registerMiner = this.state.registerMiner
        if(!registerMiner.deviceId){
            message.error("请输入Miner所属的deviceId！")
            return;
        }
        if(!registerMiner.expired_time){
            message.error("请输入Miner所属的expired_time！")
            return;
        }
        this.setState({confirmLoading:true})
        SocketMinerApi.registerMiners(registerMiner).then(resp => {
            if(!resp.error){
                message.success("Standard Miner注册成功！")
                this.setState({showRegister:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
            this.setState({confirmLoading:false})
        })
    }
    handleRegisterCancel(){
        this.setState({showRegister:false})
    }

    handleOnboardCancel(){
        this.setState({showOnboard:false})
    }

    render() {
        return (
            <div style={{width:'100%',height:'100%',overflow:"hidden"}}>
                <Row>
                    <Col span={4}>
                        <span style={{fontWeight:'bold',fontSize:18}}>
                            Miner Manage
                        </span>
                    </Col>
                    <Col span={20}>
                        <div style={{textAlign:'right'}}>
                            <span>
                                Address:<Input onChange={this.onMinerAddressChange} size='small' style={{width:180}} placeholder="miner address" allowClear />
                            </span>
                            <span style={{marginLeft:10}}>Status:
                            <Select size='small' style={{width:150}} placeholder="Select a status" onChange={this.onStatusChange} allowClear options={this.state.statusList} />
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' onClick={this.SearchMiner} type="primary" icon={<SearchOutlined />}>Search</Button>
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' danger onClick={this.registerMiner} icon={<PlusCircleOutlined />}>Register Miner</Button>
                            </span>
                        </div>
                    </Col>
                </Row>
                {this.state.totalCount>10?<div style={{textAlign:'right',paddingTop:15}}><Pagination onChange={this.onPaginationChange} size="small" total={this.state.totalCount} showSizeChanger showQuickJumper /></div>:''}
                <div style={{marginTop:10,width:'100%',height:'100%',overflow:'auto'}}>
                    <Table scroll={{x: 1620}} size='small' pagination={false} columns={this.state.columns} dataSource={this.state.plantList} />
                </div>
                <Modal width='800px' title="Register Miner" open={this.state.showRegister} destroyOnClose={true} onOk={this.handleRegisterOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleRegisterCancel}>
                    <Row>
                        {
                            <Col span={24} style={{height:10}}></Col>
                        }
                        {
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Device Id:</span></Col>
                        }
                        {
                            <Col span={20}><TextArea rows={2} onChange={this.onDeviceIdChange} /></Col>
                        }
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Expired Time:</span>
                        </Col>
                        <Col span={20}>
                            <Input readOnly={this.state.confirmLoading} type='number' placeholder="expired time of this miner" onChange={this.onMinerExpiredTimeChange} suffix="days"/>
                        </Col>
                    </Row>
                </Modal>

                <Modal width='800px' title="Onboard Miner" open={this.state.showOnboard} destroyOnClose={true} onOk={this.handleOnboardOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleOnboardCancel}>
                    <Row>
                        {
                            <Col span={24} style={{height:10}}></Col>
                        }
                        {
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Owner Address:</span></Col>
                        }
                        {
                            <Col span={20}><TextArea rows={2} onChange={this.onOwnerAddressChange} /></Col>
                        }
                    </Row>
                </Modal>
            </div>
        )
    }
}

export default SocketMiner;

import React from "react";
import { Space, Table, Tag,Row,Col,Button,Modal,Input,Select,message,Pagination } from 'antd';
import {SearchOutlined, EditOutlined, StopOutlined, ExclamationCircleOutlined,PlusOutlined,PlusCircleOutlined} from '@ant-design/icons';
import RemoteMinerApi from "../../../api/remoteMinerApi";
import {copyText, formatDate} from '../../../utils/browserUtils';
import StandardMinerApi from "../../../api/StandardMinerApi";
import ProviderApi from "../../../api/ProviderApi";
const { TextArea } = Input;

class StandardMiner extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            columns:true,
            totalCount:0,
            showRegister:false,
            confirmLoading:false,
            statusList:[
                {value: "1", label: "已注册"},
                {value: "2", label: "已绑定"},
                {value: "3", label: "已终止"}
            ],
            minerSearch:{

            },
            providerList:[],
            registerMiner:{
                minerType:3
            },
            minerTypeList:[
                {label:'Current Transformer Classic',value:1},
                {label:'Smart Logger Pro',value:2},
                {label:'Smart Logger Classic',value:3},
            ]
        };
        this.check = this.check.bind(this)
        this.onStatusChange = this.onStatusChange.bind(this)
        this.onMinerAddressChange = this.onMinerAddressChange.bind(this)
        this.onPlantAddressChange = this.onPlantAddressChange.bind(this)
        this.onPaginationChange = this.onPaginationChange.bind(this)
        this.SearchMiner = this.SearchMiner.bind(this)
        this.handleEditOk = this.handleEditOk.bind(this)
        this.handleEditCancel = this.handleEditCancel.bind(this)
        this.onEditExpiredTimeChange = this.onEditExpiredTimeChange.bind(this)
        this.handleShowEdit = this.handleShowEdit.bind(this)
        this.onPriceChange = this.onPriceChange.bind(this)
        this.onCurrencyChange = this.onCurrencyChange.bind(this)
        this.registerMiner = this.registerMiner.bind(this)
        this.handleRegisterCancel = this.handleRegisterCancel.bind(this)
        this.queryProviderList = this.queryProviderList.bind(this)
        this.onProviderChange = this.onProviderChange.bind(this)
        this.onMinerAmountChange = this.onMinerAmountChange.bind(this)
        this.onMinerTypeChange = this.onMinerTypeChange.bind(this)
        this.onPlantConfigChange = this.onPlantConfigChange.bind(this)
        this.handleRegisterOk = this.handleRegisterOk.bind(this)
    }
    async componentWillMount() {
        await this.check();
        await this.queryProviderList();
    }
    async queryMinerList(minerSearch){
        let minerList = await StandardMinerApi.queryMinerList(minerSearch)
        return minerList;
    }
    async check() {
        const columns = [
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                width: 200,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Activation Code',
                dataIndex: 'minerAcode',
                key: 'minerAcode',
                width: 200,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Provider Name',
                dataIndex: 'providerName',
                key: 'providerName',
                width: 180,
                render:(text) => <span>{text}</span>
            },
            {
                title: 'Miner Type',
                dataIndex: 'miner_type',
                width: 200,
                key: 'miner_type',
                render: (miner_type) => {
                    let text = '已终止'
                    if(miner_type === 4){
                        text = 'Smart Logger Classic'
                    }
                    if(miner_type === 5){
                        text = 'Smart Logger Lite'
                    }
                    if(miner_type === 6){
                        text = 'Smart Logger Pro'
                    }
                    if(miner_type === 7){
                        text = 'Current Transformer Classic'
                    }
                    if(miner_type === 8){
                        text = 'Current Transformer Pro'
                    }
                    return (
                        <Tag key={text}>{text}</Tag>
                    );
                }
            },
            {
                title: 'Status',
                key: 'status',
                width: 120,
                dataIndex: 'status',
                render: (status) => {
                    let color = 'red'
                    let text = '已终止'
                    if(status === 1){
                        color = '#1677ff'
                        text = '已注册'
                    }
                    if(status === 2){
                        color = 'green'
                        text = '已绑定'
                    }
                    if(status === 3){
                        color = 'red'
                        text = '已终止'
                    }
                    return (
                        <Tag color={color} key={text}>{text}</Tag>
                    );
                }
            },
            {
                title: 'Plant Info',
                dataIndex: 'plant',
                width: 250,
                key: 'plant',
                render: (text) => <span>{(text!='null' && text!=undefined)?text:''}</span>,
            },
            {
                title: 'Owner',
                dataIndex: 'owner',
                width: 200,
                ellipsis:true,
                key: 'owner'
            },
            {
                title: 'Create time',
                dataIndex: 'create_time',
                key: 'create_time',
                width: 200,
                render: (text) => {return <span>{this.formatDateStr(text)}</span>},
            },
            {
                title: 'Action',
                key: 'action',
                fixed: 'right',
                render: (_, record) => (
                    <Space size="middle">
                        {
                            (record.status==='onboarded'||record.status==='terminated')?"":<Button
                                type="primary"
                                size='small'
                                disabled={true}
                                icon={<EditOutlined />}
                                onClick={() => {this.handleShowEdit(record)}}>
                                Edit
                            </Button>
                        }
                    </Space>
                ),
            },
        ];
        this.setState({columns:columns})
        // 查询电站列表
        let resp = await this.queryMinerList(this.state.minerSearch);
        if(!resp.error){
            let result = resp.result
            if(!result){
                return;
            }
            const data=[]
            if(result.count>0){
                this.setState({totalCount:result.count})
                for (const item of result.list) {
                    const plant = {
                        key:item.miner_address,
                        providerName:item.providerName,
                        providerAddress:item.providerAddress,
                        minerAcode:item.activation_code,
                        address:item.miner_address,
                        plant:JSON.stringify(item.plant_config),
                        provider_id:item.provider_id,
                        miner_type:item.miner_type,
                        owner:item.owner_address,
                        create_time:item.create_time,
                        status:item.miner_status
                    }
                    data.push(plant)
                }
            }else {
                this.setState({totalCount:0})
            }
            this.setState({plantList:data})
        }else {
            this.setState({plantList:undefined})
        }
    }
    queryProviderList(){
        ProviderApi.providerList().then(resp => {
            console.log(resp);
            if(!resp.error){
                const list = []
                resp.result.forEach(item => {
                    list.push({value: item.address, label: item.name+" - "+item.address})
                })
                this.setState({providerList:list})
            }
        })
    }
    onStatusChange(value){
        let minerSearch = this.state.minerSearch
        minerSearch.status = value
        this.setState({minerSearch:minerSearch})
    }
    onMinerAddressChange(e){
        let minerSearch = this.state.minerSearch
        minerSearch.address = e.target.value
        this.setState({minerSearch:minerSearch})
    }
    onPlantAddressChange(e){
        let minerSearch = this.state.minerSearch
        minerSearch.plant_id = e.target.value
        this.setState({minerSearch:minerSearch})
    }
    onPaginationChange(page, pageSize){
        let minerSearch = this.state.minerSearch
        minerSearch.offset = page
        minerSearch.size = pageSize
        this.setState({minerSearch:minerSearch})
        this.SearchMiner()
    }
    onProviderChange(value){
        let registerMiner = this.state.registerMiner
        registerMiner.providerAddress = value
        this.setState({registerMiner:registerMiner})
    }
    onMinerAmountChange(value){
        const minerAmount = value.target.value
        let registerMiner = this.state.registerMiner
        registerMiner.minerAmount = minerAmount
        this.setState({registerMiner:registerMiner})
    }
    onMinerTypeChange(value){
        let registerMiner = this.state.registerMiner
        registerMiner.minerType = value
        this.setState({registerMiner:registerMiner})
    }
    onPlantConfigChange(e){
        let registerMiner = this.state.registerMiner
        registerMiner.plantConfig = e.target.value
        this.setState({registerMiner:registerMiner})
    }
    SearchMiner(){
        this.check()
    }
    formatDateStr(value){
        if(value){
            return value.replace("T"," ").replace(".000Z","")
        }else {
            return ''
        }
    }
    handleEditOk(){
        const editMinerInfo = this.state.editMinerInfo;
        console.log("editMinerInfo:"+JSON.stringify(editMinerInfo))
        if(!editMinerInfo.address || editMinerInfo.address.length !== 42){
            message.error("Miner信息异常，请刷新页面后重试！")
            return;
        }
        if(!editMinerInfo.expired_time){
            message.error("过期天数不能为空！")
            return;
        }
        if(!editMinerInfo.price.amount){
            message.error("过期天数不能为空！")
            return;
        }
        if(Number(editMinerInfo.price.amount)<=0){
            message.error("请输入正确的过期天数！")
            return;
        }
        if(!editMinerInfo.price.currency){
            message.error("请选择用于支付的货币！")
            return;
        }
        //
        RemoteMinerApi.updateMiner(editMinerInfo).then(resp => {
            console.log(resp)
            if(!resp.error){
                message.success("Miner信息修改成功！")
                this.setState({showEdit:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
        })
    }
    handleEditCancel(){
        this.setState({showEdit:false})
    }
    onEditExpiredTimeChange(e){
        let editMinerInfo = this.state.editMinerInfo
        editMinerInfo.expired_time = e.target.value
        this.setState({editMinerInfo:editMinerInfo})
    }
    handleShowEdit(record){
        let editMinerInfo = this.state.editMinerInfo;
        editMinerInfo.address = record.address
        editMinerInfo.expired_time = Math.round(record.expired_time/86400)
        editMinerInfo.price = record.priceDetail
        this.setState({editMinerInfo:editMinerInfo})
        console.log("editMinerInfo:"+JSON.stringify(editMinerInfo))
        this.setState({showEdit:true})
    }
    onPriceChange(e){
        let editMinerInfo = this.state.editMinerInfo;
        editMinerInfo.price.amount = e.target.value
        this.setState({editMinerInfo:editMinerInfo})
    }
    onCurrencyChange(value){
        let editMinerInfo = this.state.editMinerInfo;
        editMinerInfo.price.currency = value
        this.setState({editMinerInfo:editMinerInfo})
    }

    registerMiner(){
        this.setState({showRegister:true})
    }
    handleRegisterOk(){
        let registerMiner = this.state.registerMiner
        if(!registerMiner.providerAddress){
            message.error("请选择Miner所属的Provider！")
            return;
        }
        if(registerMiner.minerType == 1 && !registerMiner.minerAmount){
            message.error("Miner Amount不能为空！")
            return;
        }
        if(registerMiner.minerType == 2 && !registerMiner.plantConfig){
            message.error("Plant Config信息不能为空！")
            return;
        }
        if(registerMiner.minerType == 3 && !registerMiner.plantConfig){
            message.error("Plant Config信息不能为空！")
            return;
        }
        this.setState({confirmLoading:true})
        StandardMinerApi.registerMiners(registerMiner).then(resp => {
            if(!resp.error){
                message.success("Standard Miner注册成功！")
                this.setState({showRegister:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
            this.setState({confirmLoading:false})
        })
    }
    handleRegisterCancel(){
        this.setState({showRegister:false})
    }

    render() {
        return (
            <div style={{width:'100%',height:'100%',overflow:"hidden"}}>
                <Row>
                    <Col span={4}>
                        <span style={{fontWeight:'bold',fontSize:18}}>
                            Miner Manage
                        </span>
                    </Col>
                    <Col span={20}>
                        <div style={{textAlign:'right'}}>
                            <span>
                                Address:<Input onChange={this.onMinerAddressChange} size='small' style={{width:180}} placeholder="miner address" allowClear />
                            </span>
                            <span style={{marginLeft:10}}>Status:
                            <Select size='small' style={{width:150}} placeholder="Select a status" onChange={this.onStatusChange} allowClear options={this.state.statusList} />
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' onClick={this.SearchMiner} type="primary" icon={<SearchOutlined />}>Search</Button>
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' danger onClick={this.registerMiner} icon={<PlusCircleOutlined />}>Register Miner</Button>
                            </span>
                        </div>
                    </Col>
                </Row>
                {this.state.totalCount>10?<div style={{textAlign:'right',paddingTop:15}}><Pagination onChange={this.onPaginationChange} size="small" total={this.state.totalCount} showSizeChanger showQuickJumper /></div>:''}
                <div style={{marginTop:10,width:'100%',height:'100%',overflow:'auto'}}>
                    <Table scroll={{x: 1620}} size='small' pagination={false} columns={this.state.columns} dataSource={this.state.plantList} />
                </div>
                <Modal width='800px' title="Register Miner" open={this.state.showRegister} destroyOnClose={true} onOk={this.handleRegisterOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleRegisterCancel}>
                    <Row>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Miner Type:</span>
                        </Col>
                        <Col span={20}>
                            <Select value={this.state.registerMiner.minerType} style={{width:'100%'}} placeholder="Select a miner type" onChange={this.onMinerTypeChange} allowClear options={this.state.minerTypeList} />
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Provider:</span>
                        </Col>
                        <Col span={20}>
                            <Select style={{width:'100%'}} placeholder="Select a provider" onChange={this.onProviderChange} allowClear options={this.state.providerList} />
                        </Col>
                        {
                            this.state.registerMiner.minerType==3?<Col span={24} style={{height:10}}></Col>:''
                        }
                        {
                            this.state.registerMiner.minerType==3?<Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Plant Config:</span></Col>:''
                        }
                        {
                            this.state.registerMiner.minerType==3?<Col span={20}><TextArea rows={10} onChange={this.onPlantConfigChange} /></Col>:''
                        }
                        {
                            this.state.registerMiner.minerType==2?<Col span={24} style={{height:10}}></Col>:''
                        }
                        {
                            this.state.registerMiner.minerType==2?<Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Plant Config:</span></Col>:''
                        }
                        {
                            this.state.registerMiner.minerType==2?<Col span={20}><TextArea rows={10} onChange={this.onPlantConfigChange} /></Col>:''
                        }
                        {
                            this.state.registerMiner.minerType==1?<Col span={24} style={{height:10}}></Col>:''
                        }
                        {
                            this.state.registerMiner.minerType==1?<Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Miner Amount:</span></Col>:''
                        }
                        {
                            this.state.registerMiner.minerType==1?<Col span={20}><Input type='number' onChange={this.onMinerAmountChange} /></Col>:''
                        }
                    </Row>
                </Modal>
            </div>
        )
    }
}

export default StandardMiner;

import {getFilters} from "../utils/browserUtils";
import HttpUtils from '../utils/HttpUtils'
const baseUrl = process.env.REACT_APP_BASE_API


async function queryRoleList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_role_list", params,token)
}

async function queryRoleAllList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_role_alllist", params,token)
}

async function addRole(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_role_add", params,token)
}

async function delRole(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_role_del", params,token)
}

async function queryRoleMenu(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_role_info", params,token)
}

async function updateRole(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_role_update", params,token)
}

const RoleApi ={
    queryRoleList:queryRoleList,
    queryRoleAllList:queryRoleAllList,
    addRole:addRole,
    delRole:delRole,
    queryRoleMenu:queryRoleMenu,
    updateRole:updateRole,
}

export default RoleApi;

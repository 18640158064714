import React from "react";

class Dashboard extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            isLoad:true,
            token:false,
        };
        this.check = this.check.bind(this)
    }
    async componentWillMount() {
        await this.check();
    }
    async check() {

    }
    render() {
        return (
            <div>
                <h2> ^_^&nbsp;&nbsp;&nbsp;Welcome to Arkreen Admin</h2>
            </div>
        )
    }
}

export default Dashboard;

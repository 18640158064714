import React from "react";
import {Space, Table, Tag, Row, Col, Button, Modal, Input, Select, message, Pagination,Radio} from 'antd';
import {PlusCircleOutlined, EditOutlined, StopOutlined, ExclamationCircleOutlined,PlusOutlined} from '@ant-design/icons';
import RemoteMinerApi from "../../../api/remoteMinerApi";
import {copyText, formatDate} from '../../../utils/browserUtils';
import PlantServiceApi from "../../../api/PlantServiceApi";
import { ethers } from "ethers";
import axios from "axios";
import {getFilters} from "../../../utils/browserUtils";

const { TextArea } = Input;
const s3Url = process.env.REACT_APP_S3_URL

class Plant extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            columns:true,
            totalCount:0,
            data:false,
            showAdd:false,
            showCreate:false,
            showEdit:false,
            showChange:false,
            confirmLoading:false,
            createText:'OK',
            plantList:[],
            selectPlantList:[],
            ShineMonitorPlantList:[],
            ShineMonitor_1_PlantList:[],
            CommonApi_PlantList:[],
            installPlantInfo:{
                provider:undefined,
                name:undefined,
                pn:undefined,
                sn:undefined,
                devcode:undefined,
                devaddr:undefined,
                ratedPower:undefined,
                location:{
                    lat:undefined,
                    lon:undefined
                },
                deviceId:undefined,
            },
            plantInfo:{
                provider:undefined,
                stationId:undefined,
                deviceId:undefined,
                plantLink:undefined,
                imageLink:undefined,
                locationType:'GCS',
                name:undefined,
                pn:undefined,
                sn:undefined,
                devcode:undefined,
                devaddr:undefined,
                power:undefined,
                latitude:undefined,
                longitude:undefined,
                owner:undefined,
                region:undefined,
                
            },
            editPlantInfo:{

            },
            selectedFile:null,
            uploadedUrl:'',
            fileName:'',
            providerList:[
                {value: "Solarman", label: "Solarman"},
                {value: "ShineMonitor", label: "ShineMonitor"},
                {value: "ShineMonitor_1", label: "ShineMonitor_1"},
                {value: "common", label: "common"}
            ],
            plantFrom:[
                {value: "Arkreen", label: "Arkreen"},
                {value: "PlantMiner", label: "PlantMiner"},
            ],
            locationTypeList:[
                {value: "GCS", label: "GCS"}
            ],
            currencyList:[
                {value: "0x6c28fF02d3A132FE52D022db1f25a33d91caeCA2", label: "gAKRE - 0x6c28fF02d3A132FE52D022db1f25a33d91caeCA2"},
                {value: "0x960c67b8526e6328b30ed2c2faea0355beb62a83", label: "gAKRE(生产) - 0x960c67b8526e6328b30ed2c2faea0355beb62a83"},
                {value: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174", label: "USDC - 0x2791bca1f2de4661ed88a30c99a7a9449aa84174"},
                {value: "0x9C5653339E0B3A99262997FbB541E2562f3361C9", label: "USDC(测试) - 0x9C5653339E0B3A99262997FbB541E2562f3361C9"},
                {value: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270", label: "WMATIC - 0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270"},
            ],
            plantToList:[
                {value: "Arkreen", label: "Arkreen"},
                {value: "CSP", label: "CSP"},
            ],
            
            createRemoteMinerInfo:{
                crm_type:2,
            },
            plantSearch:{},
            
            createMinerByPlantsInfo:{
                crm_type:2,
                plantIdList:[],
               
            },
            createMinersByPlants:false,
            plantAmountInfo:{minerCount:0,amount:0},
            countryList : [
                    {value: "Afghanistan",label: "Afghanistan"},
                    {value: "Albania",label: "Albania"},
                    {value: "Algeria",label: "Algeria"},
                    {value: "Andorra",label: "Andorra"},
                    {value: "Angola",label: "Angola"},
                    {value: "Antarctica",label: "Antarctica"},
                    {value: "Antigua and Barbuda",label: "Antigua and Barbuda"},
                    {value: "Argentina",label: "Argentina"},
                    {value: "Armenia",label: "Armenia"},
                    {value: "Ashmore and Cartier Islands",label: "Ashmore and Cartier Islands"},
                    {value: "Australia",label: "Australia"},
                    {value: "Indian Ocean Territories",label: "Indian Ocean Territories"},
                    {value: "Heard Island and McDonald Islands",label: "Heard Island and McDonald Islands"},
                    {value: "Norfolk Island",label: "Norfolk Island"},
                    {value: "Austria",label: "Austria"},
                    {value: "Azerbaijan",label: "Azerbaijan"},
                    {value: "Bahrain",label: "Bahrain"},
                    {value: "Bangladesh",label: "Bangladesh"},
                    {value: "Barbados",label: "Barbados"},
                    {value: "Belarus",label: "Belarus"},
                    {value: "Belgium",label: "Belgium"},
                    {value: "Belize",label: "Belize"},
                    {value: "Benin",label: "Benin"},
                    {value: "Bhutan",label: "Bhutan"},
                    {value: "Bolivia",label: "Bolivia"},
                    {value: "Bosnia and Herzegovina",label: "Bosnia and Herzegovina"},
                    {value: "Botswana",label: "Botswana"},
                    {value: "Brazil",label: "Brazil"},
                    {value: "Brunei",label: "Brunei"},
                    {value: "Bulgaria",label: "Bulgaria"},
                    {value: "Burkina Faso",label: "Burkina Faso"},
                    {value: "Burundi",label: "Burundi"},
                    {value: "Cambodia",label: "Cambodia"},
                    {value: "Cameroon",label: "Cameroon"},
                    {value: "Canada",label: "Canada"},
                    {value: "Cape Verde",label: "Cape Verde"},
                    {value: "Central African Republic",label: "Central African Republic"},
                    {value: "Chad",label: "Chad"},
                    {value: "Chile",label: "Chile"},
                    {value: "China",label: "China"},
                    {value: "Hong Kong S.A.R.",label: "Hong Kong S.A.R."},
                    {value: "Macao S.A.R",label: "Macao S.A.R"},
                    {value: "Colombia",label: "Colombia"},
                    {value: "Comoros",label: "Comoros"},
                    {value: "Costa Rica",label: "Costa Rica"},
                    {value: "Croatia",label: "Croatia"},
                    {value: "Cuba",label: "Cuba"},
                    {value: "Cyprus",label: "Cyprus"},
                    {value: "Czech Republic",label: "Czech Republic"},
                    {value: "Democratic Republic of the Congo",label: "Democratic Republic of the Congo"},
                    {value: "Denmark",label: "Denmark"},
                    {value: "Greenland",label: "Greenland"},
                    {value: "Faroe Islands",label: "Faroe Islands"},
                    {value: "Djibouti",label: "Djibouti"},
                    {value: "Dominica",label: "Dominica"},
                    {value: "Dominican Republic",label: "Dominican Republic"},
                    {value: "East Timor",label: "East Timor"},
                    {value: "Ecuador",label: "Ecuador"},
                    {value: "Egypt",label: "Egypt"},
                    {value: "El Salvador",label: "El Salvador"},
                    {value: "Equatorial Guinea",label: "Equatorial Guinea"},
                    {value: "Eritrea",label: "Eritrea"},
                    {value: "Estonia",label: "Estonia"},
                    {value: "Ethiopia",label: "Ethiopia"},
                    {value: "Federated States of Micronesia",label: "Federated States of Micronesia"},
                    {value: "Fiji",label: "Fiji"},
                    {value: "Aland",label: "Aland"},
                    {value: "Finland",label: "Finland"},
                    {value: "French Southern and Antarctic Lands",label: "French Southern and Antarctic Lands"},
                    {value: "Saint Barthelemy",label: "Saint Barthelemy"},
                    {value: "France",label: "France"},
                    {value: "Wallis and Futuna",label: "Wallis and Futuna"},
                    {value: "Saint Martin",label: "Saint Martin"},
                    {value: "Saint Pierre and Miquelon",label: "Saint Pierre and Miquelon"},
                    {value: "New Caledonia",label: "New Caledonia"},
                    {value: "French Polynesia",label: "French Polynesia"},
                    {value: "Gabon",label: "Gabon"},
                    {value: "Gambia",label: "Gambia"},
                    {value: "Georgia",label: "Georgia"},
                    {value: "Germany",label: "Germany"},
                    {value: "Ghana",label: "Ghana"},
                    {value: "Greece",label: "Greece"},
                    {value: "Grenada",label: "Grenada"},
                    {value: "Guatemala",label: "Guatemala"},
                    {value: "Guinea",label: "Guinea"},
                    {value: "Guinea Bissau",label: "Guinea Bissau"},
                    {value: "Guyana",label: "Guyana"},
                    {value: "Haiti",label: "Haiti"},
                    {value: "Honduras",label: "Honduras"},
                    {value: "Hungary",label: "Hungary"},
                    {value: "Iceland",label: "Iceland"},
                    {value: "India",label: "India"},
                    {value: "Indonesia",label: "Indonesia"},
                    {value: "Iran",label: "Iran"},
                    {value: "Iraq",label: "Iraq"},
                    {value: "Ireland",label: "Ireland"},
                    {value: "Israel",label: "Israel"},
                    {value: "Palestine",label: "Palestine"},
                    {value: "Italy",label: "Italy"},
                    {value: "Ivory Coast",label: "Ivory Coast"},
                    {value: "Jamaica",label: "Jamaica"},
                    {value: "Japan",label: "Japan"},
                    {value: "Jordan",label: "Jordan"},
                    {value: "Siachen Glacier",label: "Siachen Glacier"},
                    {value: "Kazakhstan",label: "Kazakhstan"},
                    {value: "Kenya",label: "Kenya"},
                    {value: "Kiribati",label: "Kiribati"},
                    {value: "Kosovo",label: "Kosovo"},
                    {value: "Kuwait",label: "Kuwait"},
                    {value: "Kyrgyzstan",label: "Kyrgyzstan"},
                    {value: "Laos",label: "Laos"},
                    {value: "Latvia",label: "Latvia"},
                    {value: "Lebanon",label: "Lebanon"},
                    {value: "Lesotho",label: "Lesotho"},
                    {value: "Liberia",label: "Liberia"},
                    {value: "Libya",label: "Libya"},
                    {value: "Liechtenstein",label: "Liechtenstein"},
                    {value: "Lithuania",label: "Lithuania"},
                    {value: "Luxembourg",label: "Luxembourg"},
                    {value: "Macedonia",label: "Macedonia"},
                    {value: "Madagascar",label: "Madagascar"},
                    {value: "Malawi",label: "Malawi"},
                    {value: "Malaysia",label: "Malaysia"},
                    {value: "Maldives",label: "Maldives"},
                    {value: "Mali",label: "Mali"},
                    {value: "Malta",label: "Malta"},
                    {value: "Marshall Islands",label: "Marshall Islands"},
                    {value: "Mauritania",label: "Mauritania"},
                    {value: "Mauritius",label: "Mauritius"},
                    {value: "Mexico",label: "Mexico"},
                    {value: "Moldova",label: "Moldova"},
                    {value: "Monaco",label: "Monaco"},
                    {value: "Mongolia",label: "Mongolia"},
                    {value: "Montenegro",label: "Montenegro"},
                    {value: "Morocco",label: "Morocco"},
                    {value: "Mozambique",label: "Mozambique"},
                    {value: "Myanmar",label: "Myanmar"},
                    {value: "Namibia",label: "Namibia"},
                    {value: "Nauru",label: "Nauru"},
                    {value: "Nepal",label: "Nepal"},
                    {value: "Aruba",label: "Aruba"},
                    {value: "Curaçao",label: "Curaçao"},
                    {value: "Netherlands",label: "Netherlands"},
                    {value: "Sint Maarten",label: "Sint Maarten"},
                    {value: "Cook Islands",label: "Cook Islands"},
                    {value: "New Zealand",label: "New Zealand"},
                    {value: "Niue",label: "Niue"},
                    {value: "Nicaragua",label: "Nicaragua"},
                    {value: "Niger",label: "Niger"},
                    {value: "Nigeria",label: "Nigeria"},
                    {value: "North Korea",label: "North Korea"},
                    {value: "Northern Cyprus",label: "Northern Cyprus"},
                    {value: "Norway",label: "Norway"},
                    {value: "Oman",label: "Oman"},
                    {value: "Pakistan",label: "Pakistan"},
                    {value: "Palau",label: "Palau"},
                    {value: "Panama",label: "Panama"},
                    {value: "Papua New Guinea",label: "Papua New Guinea"},
                    {value: "Paraguay",label: "Paraguay"},
                    {value: "Peru",label: "Peru"},
                    {value: "Philippines",label: "Philippines"},
                    {value: "Poland",label: "Poland"},
                    {value: "Portugal",label: "Portugal"},
                    {value: "Qatar",label: "Qatar"},
                    {value: "Republic of Congo",label: "Republic of Congo"},
                    {value: "Republic of Serbia",label: "Republic of Serbia"},
                    {value: "Romania",label: "Romania"},
                    {value: "Russia",label: "Russia"},
                    {value: "Rwanda",label: "Rwanda"},
                    {value: "Saint Kitts and Nevis",label: "Saint Kitts and Nevis"},
                    {value: "Saint Lucia",label: "Saint Lucia"},
                    {value: "Saint Vincent and the Grenadines",label: "Saint Vincent and the Grenadines"},
                    {value: "Samoa",label: "Samoa"},
                    {value: "San Marino",label: "San Marino"},
                    {value: "Sao Tome and Principe",label: "Sao Tome and Principe"},
                    {value: "Saudi Arabia",label: "Saudi Arabia"},
                    {value: "Senegal",label: "Senegal"},
                    {value: "Seychelles",label: "Seychelles"},
                    {value: "Sierra Leone",label: "Sierra Leone"},
                    {value: "Singapore",label: "Singapore"},
                    {value: "Slovakia",label: "Slovakia"},
                    {value: "Slovenia",label: "Slovenia"},
                    {value: "Solomon Islands",label: "Solomon Islands"},
                    {value: "Somalia",label: "Somalia"},
                    {value: "Somaliland",label: "Somaliland"},
                    {value: "South Africa",label: "South Africa"},
                    {value: "South Korea",label: "South Korea"},
                    {value: "South Sudan",label: "South Sudan"},
                    {value: "Spain",label: "Spain"},
                    {value: "Sri Lanka",label: "Sri Lanka"},
                    {value: "Sudan",label: "Sudan"},
                    {value: "Suriname",label: "Suriname"},
                    {value: "Swaziland",label: "Swaziland"},
                    {value: "Sweden",label: "Sweden"},
                    {value: "Switzerland",label: "Switzerland"},
                    {value: "Syria",label: "Syria"},
                    {value: "Tajikistan",label: "Tajikistan"},
                    {value: "Thailand",label: "Thailand"},
                    {value: "The Bahamas",label: "The Bahamas"},
                    {value: "Togo",label: "Togo"},
                    {value: "Tonga",label: "Tonga"},
                    {value: "Trinidad and Tobago",label: "Trinidad and Tobago"},
                    {value: "Tunisia",label: "Tunisia"},
                    {value: "Turkey",label: "Turkey"},
                    {value: "Taiwan",label: "Taiwan"},
                    {value: "Turkmenistan",label: "Turkmenistan"},
                    {value: "Uganda",label: "Uganda"},
                    {value: "Ukraine",label: "Ukraine"},
                    {value: "United Arab Emirates",label: "United Arab Emirates"},
                    {value: "Anguilla",label: "Anguilla"},
                    {value: "Bermuda",label: "Bermuda"},
                    {value: "Falkland Islands",label: "Falkland Islands"},
                    {value: "United Kingdom",label: "United Kingdom"},
                    {value: "Guernsey",label: "Guernsey"},
                    {value: "Isle of Man",label: "Isle of Man"},
                    {value: "Cayman Islands",label: "Cayman Islands"},
                    {value: "Jersey",label: "Jersey"},
                    {value: "British Indian Ocean Territory",label: "British Indian Ocean Territory"},
                    {value: "Montserrat",label: "Montserrat"},
                    {value: "Pitcairn Islands",label: "Pitcairn Islands"},
                    {value: "South Georgia and South Sandwich Islands",label: "South Georgia and South Sandwich Islands"},
                    {value: "Saint Helena",label: "Saint Helena"},
                    {value: "British Virgin Islands",label: "British Virgin Islands"},
                    {value: "Turks and Caicos Islands",label: "Turks and Caicos Islands"},
                    {value: "United Republic of Tanzania",label: "United Republic of Tanzania"},
                    {value: "American Samoa",label: "American Samoa"},
                    {value: "Guam",label: "Guam"},
                    {value: "Northern Mariana Islands",label: "Northern Mariana Islands"},
                    {value: "Puerto Rico",label: "Puerto Rico"},
                    {value: "United States of America",label: "United States of America"},
                    {value: "United States Virgin Islands",label: "United States Virgin Islands"},
                    {value: "Uruguay",label: "Uruguay"},
                    {value: "Uzbekistan",label: "Uzbekistan"},
                    {value: "Vanuatu",label: "Vanuatu"},
                    {value: "Vatican",label: "Vatican"},
                    {value: "Venezuela",label: "Venezuela"},
                    {value: "Vietnam",label: "Vietnam"},
                    {value: "Western Sahara",label: "Western Sahara"},
                    {value: "Yemen",label: "Yemen"},
                    {value: "Zambia",label: "Zambia"},
                    {value: "Zimbabwe",label: "Zimbabwe"},
            ]
        };
        this.check = this.check.bind(this)
        this.showAddModal = this.showAddModal.bind(this)
        this.handleAddCancel = this.handleAddCancel.bind(this)
        this.onProviderChange = this.onProviderChange.bind(this)
        this.onLocationTypeChange = this.onLocationTypeChange.bind(this)
        this.onStationIdChange = this.onStationIdChange.bind(this)
        this.onPnChange = this.onPnChange.bind(this)
        this.onSnChange = this.onSnChange.bind(this)
        this.onCommonDeviceIdChange = this.onCommonDeviceIdChange.bind(this)
        this.onPlantLinkChange = this.onPlantLinkChange.bind(this)
        this.onDevaddrChange = this.onDevaddrChange.bind(this)
        this.onDevcodeChange = this.onDevcodeChange.bind(this)
        this.onPowerChange = this.onPowerChange.bind(this)
        this.onLatitudeChange = this.onLatitudeChange.bind(this)
        this.onLongitudeChange = this.onLongitudeChange.bind(this)
        this.onRegionChange = this.onRegionChange.bind(this)
        this.onAmountChange = this.onAmountChange.bind(this)
        this.onExpiredTimeChange = this.onExpiredTimeChange.bind(this)
        this.handleCreateRemoteMiners = this.handleCreateRemoteMiners.bind(this)
        this.handleCreateCancel = this.handleCreateCancel.bind(this)
        this.onCurrencyChange = this.onCurrencyChange.bind(this)
        this.onPriceChange = this.onPriceChange.bind(this)
        this.onCreateAmountChange = this.onCreateAmountChange.bind(this)
        this.onMinerExpiredTimeChange = this.onMinerExpiredTimeChange.bind(this)
        this.handleCreateOk = this.handleCreateOk.bind(this)
        this.onPaginationChange = this.onPaginationChange.bind(this)
        this.handleShowEdit = this.handleShowEdit.bind(this)
        this.handleAddOk = this.handleAddOk.bind(this)
        this.handleEditOk = this.handleEditOk.bind(this)
        this.handleEditCancel = this.handleEditCancel.bind(this)
        this.onEditExpiredTimeChange = this.onEditExpiredTimeChange.bind(this)
        this.queryPlantList = this.queryPlantList.bind(this)
        this.onCrmTypeChange = this.onCrmTypeChange.bind(this)
        this.queryAllPlantList = this.queryAllPlantList.bind(this)
        this.onPlantChange = this.onPlantChange.bind(this)
        this.queryInstalledPlantListFromRM = this.queryInstalledPlantListFromRM.bind(this)
        this.showCreateMiners = this.showCreateMiners.bind(this)
        this.rowSelectionChange = this.rowSelectionChange.bind(this)
        this.handleShowChange = this.handleShowChange.bind(this)
        this.handleChangeCancel = this.handleChangeCancel.bind(this)
        this.handleChangeOk = this.handleChangeOk.bind(this)
        this.onPlantOwnerChange = this.onPlantOwnerChange.bind(this)
        this.searchPlant = this.searchPlant.bind(this)
        this.onPlantAddressChange = this.onPlantAddressChange.bind(this)
        this.onDeviceIdChange = this.onDeviceIdChange.bind(this)
        this.onPlantSourceChange = this.onPlantSourceChange.bind(this)
        this.onPlant2Change = this.onPlant2Change.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleUpload = this.handleUpload.bind(this)
        this.handleFileChange = this.handleFileChange.bind(this)
    }
    async componentWillMount() {
        await this.check();
    }
    async queryPlantList(){
        let plantSearch = this.state.plantSearch
        let plantList = await RemoteMinerApi.queryPlantList(plantSearch)
        return plantList;
    }

    async queryInstalledPlantListFromRM(){
        let params = {
            offset:1,
            size:100,
            status:'active'
        }
        // let plantSearch = this.state.plantSearch
        let plantList = await RemoteMinerApi.queryPlantList(params)
        return plantList;
    }

    async queryAllPlantList(){
        let params = {};
        let plantList = await PlantServiceApi.queryAllPlantsList(params);
        return plantList;
    }

    async queryCommonApiPlantList(model){
        let params = {deviceModel:model};
        let plantList = await PlantServiceApi.queryCommonPlantsListByModel(params);
        return plantList;
    }

    async check() {
        const columns = [
            {
                title: 'Plant Address',
                dataIndex: 'plant_id',
                key: 'plant_id',
                width: 200,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Status',
                key: 'status',
                width: 80,
                dataIndex: 'status',
                render: (status) => {
                    let color = 'red'
                    let text = '已终止'
                    if(status === 'active'){
                        color = 'green'
                        text = '已激活'
                    }
                    return (
                        <Tag color={color} key={text}>{text}</Tag>
                    );
                }
            },
            {
                title: 'Provider',
                dataIndex: 'provider',
                key: 'provider',
                width: 150,
            },
            {
                title: 'From',
                dataIndex: 'from',
                key: 'from',
                width: 100,
                render: (from) => {
                    let color = 'red'
                    if(from === 'Arkreen'){
                        color = 'green'
                    }
                    return (
                        <Tag color={color} key={from}>{from}</Tag>
                    );
                }
            },
            {
                title: 'Power',
                dataIndex: 'power',
                key: 'power',
                width: 120,
                render: (text) => <span>{Number(text).toFixed(2)+" W"}</span>,
            },
            {
                title: 'Region',
                dataIndex: 'region',
                width: 100,
                key: 'region',
            },
            {
                title: 'Device',
                dataIndex: 'pn',
                width: 200,
                key: 'pn',
            },
            {
                title: 'Miners',
                dataIndex: 'amount',
                width: 150,
                align:'center',
                key: 'amount',
                render:(_,record) => (
                    <span>{(record.minerCount===undefined?0:record.minerCount)+"/"+record.amount}</span>
                ),
            },
            {
                title: 'Expired Time',
                dataIndex: 'expired_time',
                key: 'expired_time',
                width: 180,
                render: (text) => {return <span>{formatDate(text)}</span>},
            },
            {
                title: 'Install Time',
                dataIndex: 'install_time',
                key: 'install_time',
                width: 180,
                render: (text) => {return <span>{formatDate(text)}</span>},
            },
            {
                title: 'Action',
                key: 'action',
                fixed: 'right',
                render: (_, record) => (
                    <Space size="middle">
                        {
                            record.status!=='active'?"":<Button
                                type="primary"
                                size='small'
                                icon={<EditOutlined />}
                                onClick={() => { this.handleShowEdit(record) }}>
                                Edit
                            </Button>
                        }
                        {
                            record.status!=='active'?"":<Button size='small' type="primary" icon={<StopOutlined />}
                                                                onClick={()=>{
                                                                    Modal.confirm({
                                                                        title: 'Are you sure terminate this plant：',
                                                                        icon: <ExclamationCircleOutlined />,
                                                                        content: record.plant_id,
                                                                        okText: 'Yes',
                                                                        cancelText: 'Cancel',
                                                                        onOk:()=>{this.handleTerminate(record)}
                                                                    });}}>终止</Button>
                        }
                        {
                            (record.status !== 'active' || ((Number(record.amount) - Number(record.minerCount?record.minerCount:0))<=0))?"":<Button type="primary" size='small' icon={<PlusOutlined />}
                                onClick={() => {this.handleCreateRemoteMiners(record)}}>
                                创建Miners
                            </Button>
                        }
                        {
                            <Button
                                type="primary"
                                size='small'
                                icon={<EditOutlined />}
                                onClick={() => { this.handleShowChange(record) }}>
                                Change
                            </Button>
                        }
                    </Space>
                ),
            },
        ];
        this.setState({columns:columns})
        // 查询电站列表
        let resp = await this.queryPlantList();
        if(!resp.error){
            let result = resp.result
            if(result){
                const data=[]
            if(result.count>0){
                this.setState({totalCount:result.count})
                console.log('result.list = ',result.list);
                result.list.forEach(item =>{
                    const plantSearch = this.state.plantSearch;
                    let json_accessdata = JSON.parse(item.access_data);
                    let device = undefined;
                    if(item.provider == 'owon'){
                        device = json_accessdata.params.mac;
                    }else if(item.provider == 'ShineMonitor' || item.provider == 'ShineMonitor_1'){
                        device = json_accessdata.params.pn;
                    }else if(item.provider == 'homeAssistant'){
                        device = json_accessdata.params.deviceId;
                    }else if(item.provider == 'enphase'){
                        device = json_accessdata.params.deviceId;
                    }else if(item.provider == 'common'){
                        device = json_accessdata.params.deviceId;
                    }
                    console.log(plantSearch.deviceId);
                    console.log(plantSearch.from);
                    if(!plantSearch.deviceId || plantSearch.deviceId == device){
                        const plant = {
                            key:item.plant_id,
                            plant_id:item.plant_id,
                            provider:item.provider,
                            power:item.power,
                            energy:item.energy,
                            region:item.region,
                            amount:item.amount,
                            minerCount:item.minerCount,
                            install_time:item.install_time,
                            expired_time:item.expired_time,
                            status:item.status,
                            pn:device,
                            from:item.owner?'PlantMiner':'Arkreen'
                        }

                        if(!plantSearch.from || plant.from == plantSearch.from){
                            data.push(plant)
                        }
    
                        
                    }
                    
                })
            }else {
                this.setState({totalCount:0})
            }
            this.setState({plantList:data})
            }
            
        }else {
            this.setState({plantList:undefined})
        }
        let installedPlantPn = [];
        let installedRes = await this.queryInstalledPlantListFromRM();
        if(!installedRes.error){
            let result = installedRes.result
            if(result){
                const data=[]
        
                    result.list.forEach(item =>{
                        
                        let json_accessdata = JSON.parse(item.access_data);
                        installedPlantPn.push(json_accessdata.params.pn);
    
                    })
                
            }
            
        }


        let res = await this.queryAllPlantList();
        if(!res.error){
            let result = res.result
            if(!result){
                return;
            }
            console.log(result)
            console.log(installedPlantPn)
            const ShineMonitordata=[];
            const ShineMonitor_1_data=[]
                result.forEach(item =>{

                    // if(!installedPlantPn.includes(item.pn)){
                        const value = {
                            provider:item.provider,
                            name:item.name,
                            pn:item.pn,
                            sn:item.sn,
                            devcode:item.devcode,
                            devaddr:item.devaddr,
                            ratedPower:item.ratedPower,
                            location:item.location,
                            region:item.country
                        }
                        const plant = {
                            label: item.name + '-' + item.pn + '-' + item.sn,
                            value:JSON.stringify(value)
                        }
                        if(item.provider === 'ShineMonitor'){
                            ShineMonitordata.push(plant)
                        }else if(item.provider === 'ShineMonitor_1'){
                            ShineMonitor_1_data.push(plant)
                        }
                        
                    // }

                })
                ShineMonitordata.sort((a, b) => a.label.localeCompare(b.label));
                ShineMonitor_1_data.sort((a, b) => a.label.localeCompare(b.label));
            this.setState({ShineMonitorPlantList:ShineMonitordata})
            this.setState({ShineMonitor_1_PlantList:ShineMonitor_1_data})
            // console.log('111 ' + data.length);
            
        }else{
            this.setState({ShineMonitorPlantList:undefined});
            this.setState({ShineMonitor_1_PlantList:undefined});
        }
        const deviceModel = 'RedexInverter'
        res = await this.queryCommonApiPlantList(deviceModel);
        if(!res.error){
            let result = res.result
            if(!result){
                return;
            }
            const CommonApiPlantdata=[];
                result.forEach(item =>{

                    // if(!installedPlantPn.includes(item.pn)){
                        const value = {
                            provider:'common',
                            deviceId:item.deviceId,
                            deviceModel:item.deviceModel,
                            deviceName:item.deviceName,
                            region:item.country,
                            city:item.city,
                            ratedPower:item.ratedPower,
                        }
                        const plant = {
                            label: item.deviceId + '-' + item.country,
                            value:JSON.stringify(value)
                        }
                        CommonApiPlantdata.push(plant)

                })
                CommonApiPlantdata.sort((a, b) => a.label.localeCompare(b.label));
            this.setState({CommonApi_PlantList:CommonApiPlantdata})
            // console.log('111 ' + data.length);
            
        }else{
            this.setState({CommonApi_PlantList:undefined});
        }
    }
    onProviderChange(value){
        console.log(value)
        let plantInfo = this.state.plantInfo
        plantInfo.provider = value

        let PlantList = this.state.selectPlantList;

        this.setState({plantInfo:plantInfo})
        
        if(value === 'ShineMonitor'){
            PlantList = this.state.ShineMonitorPlantList;
            this.setState({selectPlantList:PlantList});
        }else if(value === 'ShineMonitor_1'){
            PlantList = this.state.ShineMonitor_1_PlantList;
            this.setState({selectPlantList:PlantList});
        }else if(value === 'common'){
            PlantList = this.state.CommonApi_PlantList;
            this.setState({selectPlantList:PlantList});
        }
                
    }

    onPlantChange(value){
        if(!value || value.length == 0){
            return;
        }
        let installPlantInfo = this.state.installPlantInfo
        let plant = JSON.parse(value)
        console.log(plant)
        let info = {
            provider:plant.provider,
            name:plant.name,
            pn:plant.pn,
            sn:plant.sn,
            devcode:plant.devcode,
            devaddr:plant.devaddr,
            ratedPower:plant.ratedPower,
            location:{
                lat:0,
                lon:0
            },
            region:plant.region,
            deviceId:plant.deviceId
        }
        if(plant.location && plant.location.lat){
            info.location.lat = new Number(plant.location.lat);
        }
        if(plant.location && plant.location.lon){
            info.location.lon = new Number(plant.location.lon);
        }
        this.setState({installPlantInfo:info})
        
        //change plant info
        let plantInfo = this.state.plantInfo
        plantInfo.pn = info.pn;
        plantInfo.sn = info.sn;
        plantInfo.devcode = info.devcode;
        plantInfo.devaddr = info.devaddr;
        plantInfo.power = info.ratedPower;
        plantInfo.latitude = info.location.lat;
        plantInfo.longitude = info.location.lon;
        plantInfo.region = info.region;
        plantInfo.deviceId = info.deviceId
        this.setState({plantInfo:plantInfo})
    }
    onLocationTypeChange(value){
        let plantInfo = this.state.plantInfo
        plantInfo.locationType = value
        this.setState({plantInfo:plantInfo})
    }
    onStationIdChange(e){
        const stationId = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.stationId = stationId
        this.setState({plantInfo:plantInfo})
    }
    onPnChange(e){
        const pn = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.pn = pn
        this.setState({plantInfo:plantInfo})
    }
    onCommonDeviceIdChange(e){
        const deviceId = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.deviceId = deviceId
        this.setState({plantInfo:plantInfo})
    }
    onPlantLinkChange(e){
        const plantLink = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.plantLink = plantLink
        this.setState({plantInfo:plantInfo})
    }
    onSnChange(e){
        const sn = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.sn = sn
        this.setState({plantInfo:plantInfo})
    }
    onDevaddrChange(e){
        const devaddr = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.devaddr = devaddr
        this.setState({plantInfo:plantInfo})
    }
    onDevcodeChange(e){
        const devcode = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.devcode = devcode
        this.setState({plantInfo:plantInfo})
    }
    onPowerChange(e){
        console.log('23232323')
        console.log(e)
        const power = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.power = power
        this.setState({plantInfo:plantInfo})
    }
    onLatitudeChange(e){
        const latitude = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.latitude = latitude
        this.setState({plantInfo:plantInfo})
    }
    onLongitudeChange(e){
        const longitude = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.longitude = longitude
        this.setState({plantInfo:plantInfo})
    }
    onRegionChange(value){
        let plantInfo = this.state.plantInfo
        if(!value || value.length == 0){
            error.message('region error')
            plantInfo.region = undefined
            this.setState({plantInfo:plantInfo})
            return;
        }
        const region = value[0]
        plantInfo.region = region
        this.setState({plantInfo:plantInfo})
    }
    onAmountChange(e){
        const amount = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.amount = amount
        this.setState({plantInfo:plantInfo})
    }
    onExpiredTimeChange(e){
        const expiredTime = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.expiredTime = expiredTime
        this.setState({plantInfo:plantInfo})
    }
    onPlantOwnerChange(e){
        const owner = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.owner = owner
        this.setState({plantInfo:plantInfo})
        
    }
    handleAddOk(){
        const plantInfo = this.state.plantInfo;
        console.log("plantInfo:"+JSON.stringify(plantInfo))
        if(plantInfo){
            if(!plantInfo.provider){
                message.error("请选择电站提供方")
                return;
            }
            if(plantInfo.provider == 'Solarman' && !plantInfo.stationId){
                message.error("请输入目标电站ID")
                return;
            }
            if(plantInfo.provider == 'ShineMonitor' && !plantInfo.pn){
                message.error("请输入目标电站pn号")
                return;
            }
            if(plantInfo.provider == 'ShineMonitor' && !plantInfo.sn){
                message.error("请输入目标电站sn号")
                return;
            }
            if(plantInfo.provider == 'ShineMonitor' && !plantInfo.devaddr){
                message.error("请输入目标电站devaddr")
                return;
            }
            if(plantInfo.provider == 'ShineMonitor' && !plantInfo.devcode){
                message.error("请输入目标电站devcode")
                return;
            }
            if(plantInfo.provider == 'common' && !plantInfo.deviceId){
                message.error("请输入目标电站deviceId")
                return;
            }
            if(!plantInfo.locationType){
                message.error("请选择选择位置类型")
                return;
            }
            if(!plantInfo.latitude){
                message.error("请输入维度（latitude）")
                return;
            }
            if(!plantInfo.longitude){
                message.error("请输入经度（longitude）")
                return;
            }
            if(!plantInfo.power){
                message.error("请输入电站总功率")
                return;
            }
            if(!plantInfo.region){
                message.error("请输入Region信息")
                return;
            }
            if(!plantInfo.amount){
                message.error("请输入生成的Miner数量")
                return;
            }
            if(!plantInfo.expiredTime){
                message.error("请输入电站过期时间（天）")
                return;
            }
            if(plantInfo.owner && !ethers.utils.isAddress(plantInfo.owner)){
                message.error("请输入正确的owner地址")
                return;
            }
            //
            this.setState({confirmLoading:true})
            RemoteMinerApi.installPlant(plantInfo).then(resp => {
                if(!resp.error){
                    console.log(resp)
                    message.success("电站安装成功，电站地址："+resp.result)
                    this.check();
                    this.handleAddCancel();
                }else {
                    message.error(resp.error.message)
                }
                this.setState({confirmLoading:false})
            })
            //
        }else {
            message.error("请输入电站安装参数")

        }
    }
    handleAddCancel(){
        let Info = {
            provider:undefined,
            stationId:undefined,
            locationType:'GCS',
            name:undefined,
            pn:undefined,
            sn:undefined,
            devcode:undefined,
            devaddr:undefined,
            power:undefined,
            latitude:undefined,
            longitude:undefined,
            
        };
        this.setState({plantInfo:Info});
        this.setState({showAdd:false})
    }
    handleCreateRemoteMiners(record){
        const createRemoteMinerInfo = {
            plant_id: record.plant_id,
            desAmount: record.amount-(record.minerCount?record.minerCount:0),
            crm_type:2,
            price:9.9,
            expiredTime:365,
            power:Math.round(record.power/record.amount),
            from:record.from,
        }
        this.setState({createRemoteMinerInfo:{}})
        this.setState({createRemoteMinerInfo:createRemoteMinerInfo})
        this.setState({showCreate:true})
    }
    handleCreateCancel(){
        console.log('handleCreateCancel')
        this.setState({showCreate:false})
        this.setState({createMinersByPlants:false});
    }
    onCurrencyChange(value){
        if(this.state.createMinersByPlants){
            let createMinerByPlantsInfo = this.state.createMinerByPlantsInfo
            createMinerByPlantsInfo.currency = value
            this.setState({createMinerByPlantsInfo:createMinerByPlantsInfo})
        }else{
            let createRemoteMinerInfo = this.state.createRemoteMinerInfo
            createRemoteMinerInfo.currency = value
            this.setState({createRemoteMinerInfo:createRemoteMinerInfo})
        }

    }
    onPriceChange(e){
        if(this.state.createMinersByPlants){
            const price = e.target.value
            let createMinerByPlantsInfo = this.state.createMinerByPlantsInfo
            createMinerByPlantsInfo.price = price
            this.setState({createMinerByPlantsInfo:createMinerByPlantsInfo})
        }else{
            const price = e.target.value
            let createRemoteMinerInfo = this.state.createRemoteMinerInfo
            createRemoteMinerInfo.price = price
            this.setState({createRemoteMinerInfo:createRemoteMinerInfo})
        }

    }
    onCreateAmountChange(e){
        const amount = e.target.value
        if(this.state.createMinersByPlants){
            let createMinerByPlantsInfo = this.state.createMinerByPlantsInfo
            createMinerByPlantsInfo.amount = amount
            this.setState({createMinerByPlantsInfo:createMinerByPlantsInfo})
        }else{
            let createRemoteMinerInfo = this.state.createRemoteMinerInfo
            createRemoteMinerInfo.amount = amount
            this.setState({createRemoteMinerInfo:createRemoteMinerInfo})
        }
        
    }

    onMinerExpiredTimeChange(e){
        const expiredTime = e.target.value
        if(this.state.createMinersByPlants){
            let createMinerByPlantsInfo = this.state.createMinerByPlantsInfo
            createMinerByPlantsInfo.expiredTime = expiredTime
            this.setState({createMinerByPlantsInfo:createMinerByPlantsInfo})
        }else{
            let createRemoteMinerInfo = this.state.createRemoteMinerInfo
            createRemoteMinerInfo.expiredTime = expiredTime
            this.setState({createRemoteMinerInfo:createRemoteMinerInfo})
        }

    }
    handleCreateOk(){
        console.log('handleCreateOk, createMinersByPlants = ',this.state.createMinersByPlants)
        if(this.state.createMinersByPlants){
            let createMinerByPlantsInfo = this.state.createMinerByPlantsInfo
            //
            if(!createMinerByPlantsInfo.plantIdList || createMinerByPlantsInfo.plantIdList.length === 0){
                message.error("电站信息异常，请刷新页面重试")
                return;
            }
            if(!createMinerByPlantsInfo.price){
                message.error("请输入Remote Miner单价")
                return;
            }
            if(!createMinerByPlantsInfo.currency){
                message.error("请选择用于支付的货币")
                return;
            }
            if(!createMinerByPlantsInfo.expiredTime){
                message.error("请输入过期时间（天）")
                return;
            }
            if(!createMinerByPlantsInfo.amount){
                message.error("请输入要创建的Remote Miner数量")
                return;
            }
            if(createMinerByPlantsInfo.amount>createMinerByPlantsInfo.desAmount){
                message.error("创建的Remote Miner数量不得多余"+createMinerByPlantsInfo.desAmount+"个")
                return;
            }
            //
            this.setState({confirmLoading:true})
            this.setState({createText:"Creating..."})
            RemoteMinerApi.createRemoteMinersNew(createMinerByPlantsInfo).then(resp => {
                if(!resp.error){
                    message.success("创建RemoteMiner成功")
                    this.check();
                    this.handleCreateCancel();
                }else {
                    message.error(resp.error.message)
                }
                this.setState({createText:"OK"})
                this.setState({confirmLoading:false})
            })
        }else{
            let createRemoteMinerInfo = this.state.createRemoteMinerInfo
            //
            if(!createRemoteMinerInfo.plant_id){
                message.error("电站信息异常，请刷新页面重试")
                return;
            }
            if(!createRemoteMinerInfo.price){
                message.error("请输入Remote Miner单价")
                return;
            }
            if(!createRemoteMinerInfo.currency){
                message.error("请选择用于支付的货币")
                return;
            }
            if(!createRemoteMinerInfo.expiredTime){
                message.error("请输入过期时间（天）")
                return;
            }
            if(!createRemoteMinerInfo.amount){
                message.error("请输入要创建的Remote Miner数量")
                return;
            }
            if(createRemoteMinerInfo.amount>createRemoteMinerInfo.desAmount){
                message.error("创建的Remote Miner数量不得多余"+createRemoteMinerInfo.desAmount+"个")
                return;
            }
            if(createRemoteMinerInfo.owner && !ethers.utils.isAddress(createRemoteMinerInfo.owner)){
                message.error("owner's address error of this plant")
                return;
            }
            if(createRemoteMinerInfo.from =='PlantMiner' && createRemoteMinerInfo.crm_type !== 1){
                message.error("plant miner only used for single purchase")
                return;
            }
            if(createRemoteMinerInfo.crm_type == 2 && createRemoteMinerInfo.power != 100){
                message.error("批量100W不能创建其他功率的miner")
                return;
            }
            if(createRemoteMinerInfo.crm_type == 3  && createRemoteMinerInfo.power != 5){
                message.error("批量固定5W，目前只能创建5W的miner")
                return;
            }
            if(createRemoteMinerInfo.crm_type == 4  && createRemoteMinerInfo.power != 500 && createRemoteMinerInfo.power != 1000){
                message.error("批量Pro，目前只能创建500W和1000W的miner")
                return;
            }
            //
            this.setState({confirmLoading:true})
            this.setState({createText:"Creating..."})
            RemoteMinerApi.createRemoteMiners(createRemoteMinerInfo).then(resp => {
                if(!resp.error){
                    message.success("创建RemoteMiner成功")
                    this.check();
                    this.handleCreateCancel();
                }else {
                    message.error(resp.error.message)
                }
                this.setState({createText:"OK"})
                this.setState({confirmLoading:false})
            })
        }
        
    }
    async handleTerminate(record) {
        console.log(record)
        RemoteMinerApi.terminatePlant(record.plant_id).then(resp =>{
            if(!resp.error){
                message.success("电站终止成功")
                this.check();
            }else {
                message.error(resp.error.message)
            }
        })
    }
    onPaginationChange(page, pageSize){
        let plantSearch = this.state.plantSearch
        plantSearch.offset = page
        plantSearch.size = pageSize
        this.setState({plantSearch:plantSearch})
        this.check();
    }
    showAddModal(){
        this.setState({showAdd:true})
    }
    onCrmTypeChange(e){
        console.log(e.target.value)
        if(this.state.createMinersByPlants){
            let minerInfo =  this.state.createMinerByPlantsInfo;
            minerInfo.crm_type = e.target.value;
            this.setState({createMinerByPlantsInfo:minerInfo});
        }else{
            let createRemoteMinerInfo = this.state.createRemoteMinerInfo
            createRemoteMinerInfo.crm_type = e.target.value
            if(e.target.value == 1 || e.target.value == 3){
                createRemoteMinerInfo.expiredTime = 90;
            }else if(e.target.value == 2){
                createRemoteMinerInfo.expiredTime = 365;
            }else if(e.target.value == 4){
                createRemoteMinerInfo.expiredTime = 365;
            }
            this.setState({createRemoteMinerInfo:createRemoteMinerInfo})
            
        }
  
    }


    handleEditOk(){
        const editPlantInfo = this.state.editPlantInfo;
        console.log("editPlantInfo:"+JSON.stringify(editPlantInfo))
        if(!editPlantInfo.plant_id || editPlantInfo.plant_id.length !== 42){
            message.error("电站信息异常，请刷新页面后重试！")
            return;
        }
        if(!editPlantInfo.expired_time){
            message.error("过期天数不能为空！")
            return;
        }
        //
        RemoteMinerApi.updatePlant(editPlantInfo).then(resp => {
            console.log(resp)
            if(!resp.error){
                message.success("电站信息修改成功！")
                this.setState({showEdit:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
        })
    }
    handleChangeOk(){
        this.setState({confirmLoading:true})
        const editPlantInfo = this.state.editPlantInfo;
        console.log("editPlantInfo:"+JSON.stringify(editPlantInfo))
        if(!editPlantInfo.plant_id || editPlantInfo.plant_id.length !== 42){
            message.error("电站信息异常，请刷新页面后重试！")
            this.setState({confirmLoading:false})
            return;
        }
        //
        let data = {
            plantId:editPlantInfo.plant_id,
            to:editPlantInfo.to
        }
        RemoteMinerApi.changePlant(data).then(resp => {
            console.log(resp)
            if(!resp.error){
                message.success("电站信息修改成功！")
                this.setState({showChange:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
            this.setState({confirmLoading:false})
        })
    }
    handleEditCancel(){
        this.setState({showEdit:false})
    }
    handleChangeCancel(){
        this.setState({showChange:false})
    }
    onEditExpiredTimeChange(e){
        let editPlantInfo = this.state.editPlantInfo
        editPlantInfo.expired_time = e.target.value
        this.setState({editPlantInfo:editPlantInfo})
    }
    handleShowEdit(record){
        let editPlantInfo = this.state.editPlantInfo;
        editPlantInfo.plant_id = record.plant_id
        const t = Math.round(Date.now() / 1000)
        const time = record.expired_time - t;
        editPlantInfo.expired_time = Math.round(time/86400)
        this.setState({editPlantInfo:editPlantInfo})
        this.setState({showEdit:true})
    }
    handleShowChange(record){
        let editPlantInfo = this.state.editPlantInfo;
        editPlantInfo.plant_id = record.plant_id
        editPlantInfo.from = record.from
        this.setState({editPlantInfo:editPlantInfo})
        console.log(this.state.editPlantInfo)
        this.setState({showChange:true})
    }

    rowSelectionChange(selectedRowKeys,selectedRows){
        console.log(selectedRows);
        let minerInfo = this.state.createMinerByPlantsInfo;
        // minerInfo.selectedRowKeys = selectedRows;
        minerInfo.plantIdList = selectedRowKeys;
        
        let minerCount = 0;
        let amount = 0;
        let power = 0;
        selectedRows.forEach((item)=>{
            minerCount+= item.minerCount;
            amount += item.amount;
            power += item.power / item.amount;
        })
        this.setState({plantAmountInfo:{minerCount:minerCount,amount:amount}});
        minerInfo.desAmount = amount - minerCount;
        minerInfo.power = power / selectedRows.length;
        minerInfo.price = 9.9;
        minerInfo.expiredTime = 365;
        console.log('minerInfo.power = ',minerInfo.power)
        this.setState({createMinerByPlantsInfo:minerInfo});
    }
    showCreateMiners(){
        this.setState({createMinersByPlants:true});
        this.setState({showCreate:true})
    }
    searchPlant(){
        this.check()
    }

    onPlantAddressChange(e){
        let plantSearch = this.state.plantSearch
        plantSearch.plant_id = e.target.value
        this.setState({plantSearch:plantSearch})
    }

    onDeviceIdChange(e){
        let plantSearch = this.state.plantSearch
        plantSearch.deviceId = e.target.value
        this.setState({plantSearch:plantSearch})
    }

    onPlantSourceChange(value){
        let plantSearch = this.state.plantSearch
        plantSearch.from = value
        this.setState({plantSearch:plantSearch})
        console.log('value : ',value);

    }

    onPlant2Change(value){
        let editPlantInfo = this.state.editPlantInfo;
        editPlantInfo.to = value
        this.setState({editPlantInfo:editPlantInfo})
    }

    handleClick(){
        document.getElementById('fileInput').click();
      };
    handleFileChange(event){
        const file = event.target.files[0];
        if(file){
            this.setState({selectedFile:file});
            this.setState({fileName:file.name});
        }
      };
    handleUpload(){
        if (!this.state.selectedFile) {
          message.error('请先选择一个文件!');
          return;
        }
        let token = getFilters("token");
    
        const formData = new FormData();
        formData.append('file', this.state.selectedFile);
        formData.append('filePath','public/plant/');
        // token = token.replace(/"/g,'');
        token = token.substring(1);
        token = token.substring(0,token.length-1)
        console.log('token : ',token)
        try {
            console.log('s3Url : ',s3Url);
        axios.post(s3Url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'authorization':'Bearer '+ token
            },
          }).then(response => {
                // 假设响应包含已上传文件的 URL
                // setUploadedUrl(response.data.url);
                console.log('response : ',response);
                const code = response.data.code;
                if(code == 0){
                    this.setState({uploadedUrl:response.data.data})
                    let plantInfo = this.state.plantInfo
                    plantInfo.imageLink = response.data.data
                    this.setState({plantInfo:plantInfo})
                }else{
                    message.error('upload failed !!!');
                }
                
          });
    

        } catch (error) {
          console.error('上传文件时出错:', error);
          message.error('upload failed !!!');
        }
      };


    render() {
        return (
            <div style={{width:'100%',height:'100%',overflow:"hidden"}}>
                <Row>
                    <Col span={12}>
                            <span style={{fontWeight:'bold',fontSize:18}}>
                                Plant Manage
                            </span>
                    </Col>
                    <Col span={12}>
                        <div style={{textAlign:'right'}}>
                            <Button style={{marginRight:'10px'}} size='small' onClick={this.showAddModal} type="primary" icon={<PlusCircleOutlined />}>安装电站</Button>
                            <Button size='small' onClick={this.showCreateMiners} type="primary" icon={<PlusCircleOutlined />}>交叉创建miner</Button>
                        </div>
                    </Col>
                </Row>
                <Row style={{marginTop:10}}>
                    <Col span={24}>
                        <div style={{textAlign:'right'}}>
                            <span style={{marginLeft:10}}>
                                Plant Address:<Input onChange={this.onPlantAddressChange} size='small' style={{width:250}} placeholder="plant address " allowClear />
                            </span>
                            <span style={{marginLeft:10}}>
                                DeviceId:<Input onChange={this.onDeviceIdChange} size='small' style={{width:250}} placeholder="device id" allowClear />
                            </span>
                            <span style={{marginLeft:10}}>Plant From:
                            <Select size='small' style={{width:150}} placeholder="Select a source" onChange={this.onPlantSourceChange} allowClear options={this.state.plantFrom} />
                            </span>
                            <Button style={{marginLeft:10}} size='small' onClick={this.searchPlant} type="primary" icon={<PlusCircleOutlined />}>Search</Button>
                        </div>
                    </Col>
                </Row>
                <div style={{marginTop:10,width:'100%',height:'100%',overflow:'auto'}}>
                    {
                        this.state.totalCount>10?<div style={{textAlign:'right',paddingTop:15,paddingBottom:15}}>
                            <Pagination onChange={this.onPaginationChange} size="small" total={this.state.totalCount} showSizeChanger showQuickJumper />
                        </div>:''
                    }
                    <Table rowSelection={{getCheckboxProps:(record, index)=>{if(record.minerCount >= record.amount || record.status != 'active' || record.from != 'Arkreen'){return { disabled: true };}},onChange:(selectedRowKeys,selectedRows)=>{this.rowSelectionChange(selectedRowKeys,selectedRows)} }} scroll={{x: 1775}} size='small' pagination={false} columns={this.state.columns} dataSource={this.state.plantList} />
                </div>
                <Modal width='800px' title="Install Plant" destroyOnClose={true} open={this.state.showAdd} onOk={this.handleAddOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleAddCancel}>
                    <Row>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Provider:</span>
                        </Col>
                        <Col span={20}>
                            <Select style={{width:'100%'}} placeholder="Select a provider" onChange={this.onProviderChange} allowClear options={this.state.providerList} />
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        {
                            this.state.plantInfo.provider === 'Solarman'?<>
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>StationId:</span>
                                </Col>
                                <Col span={20}>
                                    <Input type='number' placeholder="station id" onChange={this.onStationIdChange} />
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                            </>:<></>
                        }
                        {
                            (this.state.plantInfo.provider === 'ShineMonitor' || this.state.plantInfo.provider === 'ShineMonitor_1')?<>
                                
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>Plant:</span>
                                </Col>
                                <Col span={20}>
                                <Select mode="tags" style={{width:'100%'}} placeholder="Select a plant" onChange={this.onPlantChange} allowClear options={this.state.selectPlantList} 
                                optionRender={(option) => (<Space>{option.data.value}</Space>)}  />
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                                
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>PN:</span>
                                </Col>
                                <Col span={20}>
                                    <Input placeholder="pn" onChange={this.onPnChange} value={this.state.plantInfo.pn} />
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>SN:</span>
                                </Col>
                                <Col span={20}>
                                    <Input placeholder="sn" onChange={this.onSnChange} value={this.state.plantInfo.sn}/>
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>Devaddr:</span>
                                </Col>
                                <Col span={20}>
                                    <Input placeholder="devaddr" onChange={this.onDevaddrChange} value={this.state.plantInfo.devaddr}/>
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>Devcode:</span>
                                </Col>
                                <Col span={20}>
                                    <Input placeholder="devcode" onChange={this.onDevcodeChange} value={this.state.plantInfo.devcode}/>
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                            </>:<></>
                        }
                        {
                            (this.state.plantInfo.provider === 'common')?<>
                                
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>Plant:</span>
                                </Col>
                                <Col span={20}>
                                <Select mode="tags" style={{width:'100%'}} placeholder="Select a plant" onChange={this.onPlantChange} allowClear options={this.state.selectPlantList} 
                                optionRender={(option) => (<Space>{option.data.value}</Space>)}  />
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                                
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>deviceId:</span>
                                </Col>
                                <Col span={20}>
                                    <Input placeholder="deviceId" onChange={this.onCommonDeviceIdChange} value={this.state.plantInfo.deviceId} />
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                                
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>Plant link:</span>
                                </Col>
                                <Col span={20}>
                                    <Input placeholder="plant link" onChange={this.onPlantLinkChange}  />
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Image Link:</span></Col>
                                <Col span={0}><input id="fileInput" type="file" onChange={this.handleFileChange}  style={{ display: 'none' }} /> </Col>
                                { this.state.uploadedUrl? <Col span={14}><TextArea  type="text"  value={this.state.plantInfo.imageLink}  readOnly /> </Col> :
                                    this.state.selectedFile? <Col span={14}><TextArea  type="text"  value={this.state.fileName} readOnly /> </Col> :
                                    <div></div>}
                                <Col span={3}> <Button type="primary" onClick={this.handleClick}>选择文件</Button> </Col>
                                <Col span={3}> <Button type="primary" onClick={this.handleUpload}>上传文件</Button> </Col>
                                <Col span={24} style={{height:10}}></Col>
                            </>:<></>
                        }
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Location:</span>
                        </Col>
                        <Col span={20}>
                            <Row>
                                <Col span={8}>
                                    <Select value={this.state.plantInfo.locationType} style={{width:'100%'}} placeholder="Location type" onChange={this.onLocationTypeChange} allowClear options={this.state.locationTypeList} />
                                </Col>
                                <Col span={8} style={{paddingLeft:5}}>
                                    <Input type='number' placeholder="latitude" onChange={this.onLatitudeChange} value={this.state.plantInfo.latitude} />
                                </Col>
                                <Col span={8} style={{paddingLeft:5}}>
                                    <Input type='number' placeholder="longitude" onChange={this.onLongitudeChange} value={this.state.plantInfo.longitude}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Power:</span>
                        </Col>
                        <Col span={20}>
                            <Input placeholder="plant total power" onChange={this.onPowerChange} suffix="W"  value={this.state.plantInfo.power}/>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Region:</span>
                        </Col>
                        <Col span={20}>
                            {/* <Input placeholder="region" onChange={this.onRegionChange} value={this.state.plantInfo.region}/> */}
                            <Select mode="tags" style={{width:'100%'}} placeholder={this.state.plantInfo.region} onChange={this.onRegionChange} allowClear options={this.state.countryList} 
                                optionRender={(option) => (<Space>{option.data.value}</Space>)}  />
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Miner Amount:</span>
                        </Col>
                        <Col span={20}>
                            <Input type='number' placeholder="miner amount of this plant" onChange={this.onAmountChange} />
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Expired Time:</span>
                        </Col>
                        <Col span={20}>
                            <Input type='number' placeholder="expired time of this plant" onChange={this.onExpiredTimeChange} suffix="days"/>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Owner:</span>
                        </Col>
                        <Col span={20}>
                            <Input  placeholder="owner's address of this plant" onChange={this.onPlantOwnerChange} />
                        </Col>
                    </Row>
                </Modal>
                <Modal okText={this.state.createText} width='800px' title="Create Remote Miners" destroyOnClose={true} open={this.state.showCreate} onOk={this.handleCreateOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleCreateCancel}>
                    <Row>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>目标电站:</span>
                        </Col>
                        {
                            this.state.createMinersByPlants? 
                            <Col span={20}>
                            {
                            this.state.createMinerByPlantsInfo.plantIdList.map((item)=> <div key={Math.random()}> <span style={{lineHeight:'32px'}}>{item}</span></div>)
                            }
                            </Col>
                            :
                             <Col span={20}>
                            <span style={{lineHeight:'32px'}}>{this.state.createRemoteMinerInfo.plant_id}</span>
                            </Col> 
                            
                        }
                        
                        {/* <Col span={20}>
                            <span style={{lineHeight:'32px'}}>{this.state.createRemoteMinerInfo.plant_id}</span>
                        </Col> */}
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>创建类型:</span>
                        </Col>
                        <Col span={20}>
                            <span style={{lineHeight:'32px'}}>
                                <Radio.Group onChange={this.onCrmTypeChange} value={this.state.createRemoteMinerInfo.crm_type}>
                                    <Radio value={1}>单个创建</Radio>
                                    <Radio value={2}>批量-固定100W</Radio>
                                    <Radio value={3}>批量-固定5W</Radio>
                                    <Radio value={4}>批量-Pro</Radio>
                                </Radio.Group>
                            </span>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Power:</span>
                        </Col>
                        <Col span={20}>
                            <span style={{lineHeight:'32px',color:'red'}}> {this.state.createMinersByPlants? this.state.createMinerByPlantsInfo.power : this.state.createRemoteMinerInfo.power}  W</span>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Price:</span>
                        </Col>
                        <Col span={20}>
                            <Input readOnly={this.state.confirmLoading} type='number' placeholder="price" onChange={this.onPriceChange} value={this.state.createMinersByPlants? this.state.createMinerByPlantsInfo.price : this.state.createRemoteMinerInfo.price}/>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Currency:</span>
                        </Col>
                        <Col span={20}>
                            <Select style={{width:'100%'}} placeholder="Select a currency" onChange={this.onCurrencyChange} allowClear options={this.state.currencyList} />
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Expired Time:</span>
                        </Col>
                        <Col span={20}>
                            <Input readOnly={this.state.confirmLoading} type='number' placeholder="expired time of this miner" onChange={this.onMinerExpiredTimeChange} suffix="days" value={this.state.createMinersByPlants? this.state.createMinerByPlantsInfo.expiredTime : this.state.createRemoteMinerInfo.expiredTime}/>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Create Amount:</span>
                        </Col>
                        <Col span={20}>
                            <Input readOnly={this.state.confirmLoading} type='number' placeholder="Create miner amount of this plant" onChange={this.onCreateAmountChange} />
                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>

                        </Col>
                        
                        <Col span={20}>
                            {
                                this.state.createMinersByPlants?
                                    <span style={{color:'orange'}}>
                                
                                        剩余可创建数量：{this.state.plantAmountInfo.amount-this.state.plantAmountInfo.minerCount}
                                    </span>
                                :
                                    <span style={{color:'orange'}}>
                                
                                剩余可创建数量：{this.state.createRemoteMinerInfo.desAmount}
                                </span>
                            }
                            {/* <span style={{color:'orange'}}>
                                
                                剩余可创建数量：{this.state.createRemoteMinerInfo.desAmount}
                            </span> */}
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        {
                            ((this.state.confirmLoading==false) || (this.state.createRemoteMinerInfo.amount<10))?"":<Col span={24}><span style={{fontSize:18,paddingLeft:30}}>创建的Remote Miner 数量较多，请耐心等待...</span></Col>
                        }
                    </Row>
                </Modal>
                <Modal width='800px' title="Edit Plant Info" destroyOnClose={true} open={this.state.showEdit} onOk={this.handleEditOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleEditCancel}>
                    <Row>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>目标电站:</span>
                        </Col> 
                            <Col span={20}>
                                <span style={{lineHeight:'32px'}}>{this.state.editPlantInfo.plant_id}</span>
                            </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>剩余过期天数:</span>
                        </Col>
                        <Col span={20}>
                            <Input value={this.state.editPlantInfo.expired_time} type='number' placeholder="expired time of this plant" onChange={this.onEditExpiredTimeChange} suffix="days"/>
                        </Col>
                    </Row>
                </Modal>

                <Modal width='800px' title="Change Plant Info" destroyOnClose={true} open={this.state.showChange} onOk={this.handleChangeOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleChangeCancel}>
                    <Row>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>目标电站:</span>
                        </Col> 
                            <Col span={20}>
                                <span style={{lineHeight:'32px'}}>{this.state.editPlantInfo.plant_id}</span>
                            </Col>
                        <Col span={24} style={{height:10}}></Col>
                        {this.state.editPlantInfo.from == 'PlantMiner' ? <>
                        
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>to :</span>
                            </Col> 
                            <Col span={20}>
                            {/* <span style={{lineHeight:'32px'}}>{this.state.editPlantInfo.plant_id}</span> */}
                            <Select style={{width:'100%'}} placeholder="Select a plant type" onChange={this.onPlant2Change} allowClear options={this.state.plantToList} />
                            </Col>
                            <Col span={24} style={{height:10}}></Col>
                        
                            
                            </>:<></>}
                    </Row>
                </Modal>
            </div>
        )
    }
}

export default Plant;

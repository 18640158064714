import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './views/App';
import reportWebVitals from './reportWebVitals';
// admin-web
console.log("run at env: "+process.env.REACT_APP_ENV)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App  />
);

reportWebVitals();

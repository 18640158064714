import {getFilters} from "../utils/browserUtils";
import HttpUtils from '../utils/HttpUtils'
import {getMetaMaskLoginUserAddress} from "../utils/metamaskUtils";
const baseUrl = process.env.REACT_APP_BASE_API

async function getUserInfo(){
    const token = getFilters("token");
    const address = await getMetaMaskLoginUserAddress();
    return await HttpUtils.sendRequest(baseUrl, "aop_getUserInfo", {address:address },token)
}

async function userLogin(params){
    console.log("process.env.NODE_ENV:"+process.env.NODE_ENV+" ===> baseUrl:"+baseUrl)
    return await HttpUtils.sendRequest(baseUrl, "aop_login", params)
}

async function userLogout(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_logout", params,token)
}

async function queryUserList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_user_list", params,token)
}

async function addUser(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_user_add", params,token)
}

async function delUser(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_user_del", params,token)
}

async function updateUser(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_user_update", params,token)
}

const UserApi ={
    getUserInfo:getUserInfo,
    userLogin:userLogin,
    userLogout:userLogout,
    queryUserList:queryUserList,
    addUser:addUser,
    delUser:delUser,
    updateUser:updateUser,
}

export default UserApi;

import {getFilters} from "../utils/browserUtils";
import HttpUtils from '../utils/HttpUtils'
const baseUrl = process.env.REACT_APP_BASE_API


async function queryMenuList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_menu_list", params,token)
}

async function queryAllMenuList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_menu_alllist", params,token)
}

async function updateMenu(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_menu_update", params,token)
}

async function addMenu(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_menu_add", params,token)
}

async function delMenu(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_menu_del", params,token)
}

async function queryParentMenu(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_menu_parent", params,token)
}

const MenuApi ={
    queryMenuList:queryMenuList,
    queryAllMenuList:queryAllMenuList,
    updateMenu:updateMenu,
    addMenu:addMenu,
    queryParentMenu:queryParentMenu,
    delMenu:delMenu,
}

export default MenuApi;

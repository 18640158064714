import React from "react";
import { Space, Table, List,Row,Col,Button,Modal,Input,Select,message,Pagination } from 'antd';
import {SearchOutlined, EditOutlined, StopOutlined, ExclamationCircleOutlined,PlusOutlined,PlusCircleOutlined,DeleteOutlined} from '@ant-design/icons';
import {copyText, formatDate} from '../../../utils/browserUtils';
import MarketServiceApi from '../../../api/MarketServiceApi'
import * as ethers from 'ethers'
const { TextArea } = Input;

class HardwarePurch extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            columns:[],
            columnsExpandable:[],
            totalCount:0,
            minerSearch:{
                owner:undefined
            },
            ownerList:[],
            ownerRowsCount:[],
            terminateList:[],
            minerList:[],
            terminateOwner:undefined,
            showTerminateInfo:false,
            loading:false,
            ownerMinerMap:undefined
        };
        this.check = this.check.bind(this)
        this.onOwnerChange = this.onOwnerChange.bind(this)
        this.SearchMiners = this.SearchMiners.bind(this)
        this.rowSelectionChange = this.rowSelectionChange.bind(this)
        this.showTerminate = this.showTerminate.bind(this)
        this.handleTerminateCancel = this.handleTerminateCancel.bind(this)
        this.handleTerminateOk = this.handleTerminateOk.bind(this)
        this.expandedRowRender = this.expandedRowRender.bind(this)
        this.makeItems = this.makeItems.bind(this)
        
        
    }
    async componentWillMount() {
        await this.check();
    }
    
    async check() {
        // const columns = [
        //     {
        //         title: 'Owner',
        //         dataIndex: 'owner',
        //         key: 'owner',
        //         width: 500,
        //         ellipsis:true,
        //         // render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
        //         render: (text, record, index) => {
        //             // 如果不是该名称的第一行，则不渲染该单元格
        //             if (index > 0 && record.owner === this.state.ownerList[index - 1].owner) {
        //               return {
        //                 props: {
        //                     colSpan: 0, // 不渲染该单元格
        //                   },
        //               };
        //             }
        //             return {
        //               children: text,
        //               props: {
        //                 rowSpan:this.state.ownerRowsCount[text],
        //               },
        //             };
        //         }
              
        //     },
        //     {
        //         title: 'Amount',
        //         dataIndex: 'amount',
        //         key: 'amount',
        //         width: 100,
        //         ellipsis:true,
        //         render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
                
        //     },
        //     {
        //         title: 'Miners',
        //         dataIndex: 'miners',
        //         width: 500,
        //         key: 'miners',                
        //     },
        //     {
        //         title: 'OnboardTime',
        //         dataIndex: 'onboardTime',
        //         width: 200,
        //         key: 'onboardTime',
        //         render: (text) => {return <span>{text?formatDate(text):''}</span>},                
        //     },
        //     {
        //         title: 'Action',
        //         key: 'action',
        //         fixed: 'right',
        //         render: (_, record) => (
        //             <Space size="middle">
        //                 {
        //                     (record.status==='unCreated')?<Button
        //                         type="primary"
        //                         size='small'
        //                         disabled={false}
        //                         icon={<EditOutlined />}
        //                         onClick={() => {this.handleCreatePlantMIner(record)}}>
        //                         create
        //                     </Button>:""

                            
        //                 }
        //             </Space>
        //         ),
        //     },
        // ];
        const columns = [
            {
                title: 'Owner',
                dataIndex: 'owner',
                key: 'owner',
                width: 500,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                width: 100,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
                
            },
            {
                title: 'Action',
                key: 'action',
                fixed: 'right',
            },
        ];
        const columnsExpandable = [
            { title: 'index', dataIndex: 'index', key: 'index' },
            { title: 'miner', dataIndex: 'miner', key: 'miner' },
            { title: 'onboardTime', dataIndex: 'onboardTime', key: 'onboardTime' },
            { title: 'action', dataIndex: 'action', key: 'action' },
        ];
        this.setState({columns:columns})
        this.setState({columnsExpandable:columnsExpandable})
        // 查询miner列表
        const resp = await this.queryMinerListForHardware(this.state.minerSearch);
        if(!resp.error){
            let result = resp.result
            if(!result){
                return;
            }
            const data=[]
            const ownerRowsCount = [];
            const ownerMap = new Map();
            if(result.total>0){
                let minerSearch = this.state.minerSearch
                this.setState({totalCount:result.total});
                
                for (const item of result.list) {
                    const minerList = item.minerList;
                    ownerRowsCount[item.owner] = item.miner_count;
                    let index = 0;
                    const List = []
                    for(const miner of minerList){
                        const preroderList = {
                            owner:item.owner,
                            miner:miner.miner,
                            onboardTime:miner.onboardTime,
                            index:++index,
                            key:index
                        }
                        
                        List.push(preroderList)
                    }
                    ownerMap.set(item.owner,List);
                    data.push({key:item.owner,owner:item.owner,amount:minerList.length})

                }
            }else {
                this.setState({totalCount:0})
            }
            this.setState({ownerList:data})
            this.setState({ownerMinerMap:ownerMap});
            this.setState({ownerRowsCount:ownerRowsCount})
        }
        
    }
    
    formatDateStr(value){
        if(value){
            return value.replace("T"," ").replace(".000Z","")
        }else {
            return ''
        }
    }
    async queryMinerListForHardware(minerSearch){
        let minerList = await MarketServiceApi.queryMinerListForHardware(minerSearch)
        return minerList;
    }

    onOwnerChange(e){
        let minerSearch = this.state.minerSearch
        minerSearch.owner = e.target.value
        this.setState({minerSearch:minerSearch})
    }

    SearchMiners(){
        this.check()
    }

    rowSelectionChange(selectedRowKeys,selectedRows){
        console.log("selectedRows : ",selectedRows);
        console.log('selectedRowKeys :',selectedRowKeys);
        this.setState({terminateList:selectedRows});
    }
    showTerminate(){
        let terminateList = this.state.terminateList
        const minerList = [];
        let owner = undefined;
        let flag = false
        terminateList.forEach(item =>{
            minerList.push(item.miner)
            if(!owner){
                owner = item.owner;
            }else{
                if(item.owner != owner){
                    flag = true;
                }
            }
        })
        if(flag){
            message.error("只能终止一个owner的设备");
            this.setState({terminateOwner:undefined});
            this.setState({minerList:[]})
            this.setState({showTerminateInfo:false})
        }else{
            this.setState({terminateOwner:owner});
            this.setState({minerList:minerList})
            this.setState({showTerminateInfo:true})
        }
        console.log('minerList : ',this.state.minerList)

    }
    handleTerminateCancel(){
        this.setState({minerList:[]})
        this.setState({showTerminateInfo:false})
    }

    handleTerminateOk(){
        const minerList = this.state.minerList
        const owner = this.state.terminateOwner
        if(minerList.length == 0){
            message.error("请输入选择miner！")
            return;
        }
        if(!owner){
            message.error("owner 错误！")
            return;
        }
        this.setState({confirmLoading:true})
        const params = {
            owner:owner,
            minerList:minerList
        }
        MarketServiceApi.terminateMinerList(params).then(resp => {
            if(!resp.error && !resp.result.error){
                message.success("终止miners成功！")
                this.setState({showTerminateInfo:false})
                this.check()
            }else {
                if(resp.error){
                    message.error(resp.error.message)
                }else{
                    message.error(resp.result.error.message)
                }
                
            }
            this.setState({confirmLoading:false})
        })
    }

    expandedRowRender(expandedRows) {
        
        const owner = expandedRows.owner;
        const map = this.state.ownerMinerMap;
        const minerList = map.get(owner);
        const dataList = []
        minerList.forEach((item) => {
            const data = {
                key:item.index,
                index:item.index,
                miner:item.miner,
                owner:item.owner,
                // onboardTime:item.type,
                // status:formatStatusIcon(item.status?'success':'danger',item.status?'已启用':'已禁用'),
                // count:item.count,
                // reward:item.reward,
                onboardTime:formatDate(item.onboardTime,'yyyy-MM-dd hh:mm:ss'),
                // createTime:formatDate(item.createTime*1000,'yyyy-MM-dd HH:mm:ss'),
                // operation: <div style={{display: 'flex', gap: '16px'}}>
                //     <div><ArkreenNativeButton onClick={() => updateSonMissionStatus(item._id, !item.status)}
                //                               type={item.status ? 'danger' : 'success'}
                //                               style={{width: '100px'}}>{item.status ? '禁用' : '启用'}</ArkreenNativeButton>
                //     </div>
                //     <ArkreenNativeButton style={{width: '100px'}} type='warning' onClick={() => updateSonInfo(item)}>修改</ArkreenNativeButton>
                // </div>
            }
            dataList.push(data)
        })

        return <Table rowSelection={{onChange:(selectedRowKeys,selectedRows)=>{this.rowSelectionChange(selectedRowKeys,selectedRows)} }} scroll={{x: 1 }} size='small' pagination={false} columns={this.state.columnsExpandable} dataSource={dataList} bordered></Table>

    };

     makeItems() {
        const n = 40;
        const items = new Array<Object>(n);
        for (let i = 0; i < n; ++i) {
            items[i] = {}
        }
        return items;
    }



    render() {
        return (
            <div style={{width:'100%',height:'100%'}}>
                <Row>
                    <Col span={4}>
                        <span style={{fontWeight:'bold',fontSize:18}}>
                            Hardware Miners Manage
                        </span>
                    </Col>
                    <Col span={20}>
                        <div style={{textAlign:'right'}}>
                            <span>
                                owner:<Input onChange={this.onOwnerChange} size='small' style={{width:180}} placeholder="owner" allowClear />
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' onClick={this.SearchMiners} type="primary" icon={<SearchOutlined />}>Search</Button>
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' danger onClick={this.showTerminate} icon={<PlusCircleOutlined />}>terminate miner</Button>
                            </span>
                        </div>
                    </Col>
                </Row>
                {this.state.totalCount>10?<div style={{textAlign:'right',paddingTop:15}}><Pagination onChange={this.onPaginationChange} size="small" total={this.state.totalCount} showSizeChanger showQuickJumper /></div>:''}
                <div style={{marginTop:10,width:'100%',height:'100%',overflow:'auto'}}>
                    <Table  
                        scroll={{x: 1 }} 
                        size='small' 
                        pagination={false} 
                        columns={this.state.columns} 
                        dataSource={this.state.ownerList} 
                        style={{tableLayout:'inherit'}}
                        expandable={{ expandedRowRender:this.expandedRowRender, defaultExpandedRowKeys: ['0'] }}
                    />
                </div>

                <Modal width='800px' title="Miners" open={this.state.showTerminateInfo} destroyOnClose={true} onOk={this.handleTerminateOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleTerminateCancel}>
                    <Row>
                        <Col span={24} style={{height:10}}></Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Owner:</span></Col>
                        <Col span={20}><TextArea rows={2} value={this.state.terminateOwner} /></Col>
                        <Col span={24} style={{height:10}}></Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>MinerList:</span></Col>
                        <Col span={20}><List size="small"  bordered dataSource={this.state.minerList} renderItem={(item) => <List.Item>{item}</List.Item>}/></Col>
                    </Row>
                </Modal>
  
            </div>
        )
    }
}

export default HardwarePurch;

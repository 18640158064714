import HttpUtils from '../utils/HttpUtils'
import {getFilters} from "../utils/browserUtils";
const baseUrl = process.env.REACT_APP_BASE_API


async function queryCouponList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_coupon_list", params,token)
}

async function createCoupon(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_coupon_create", params,token)
}

async function deleteCoupon(coupon){
    const params = {
        coupon:coupon
    }
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_coupon_delete", params,token)
}


const CouponApi ={
    queryCouponList:queryCouponList,
    createCoupon:createCoupon,
    deleteCoupon:deleteCoupon,
}

export default CouponApi;

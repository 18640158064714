import HttpUtils from '../utils/HttpUtils'
import {getFilters} from "../utils/browserUtils";
const baseUrl = process.env.REACT_APP_BASE_API

async function airdropAkre(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_airdropAkre", params,token)
}

async function queryAirdropRecord(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_queryAirdropRecord", params,token)
}


const TokenServiceApi ={
    airdropAkre:airdropAkre,
    queryAirdropRecord:queryAirdropRecord,
}

export default TokenServiceApi;

import React from "react";
import { Space, Table, Tag,Row,Col,Button,Modal,Input,Select,message,Pagination } from 'antd';
import {SearchOutlined, EditOutlined, StopOutlined, ExclamationCircleOutlined,PlusOutlined,PlusCircleOutlined,DeleteOutlined} from '@ant-design/icons';
import {copyText, formatDate} from '../../../utils/browserUtils';
import commonUtils from '../../../utils/commonUtils'
import PlantMinerApi from "../../../api/PlantMinerApi"
import PlantServiceApi from "../../../api/PlantServiceApi"
import RemoteMinerApi from "../../../api/remoteMinerApi"
import * as ethers from 'ethers'
import {getFilters} from "../../../utils/browserUtils";
import axios from "axios";
const { TextArea } = Input;

const s3Url = process.env.REACT_APP_S3_URL




class PlantMiner extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            columns:true,
            totalCount:0,
            showRegister:false,
            confirmLoading:false,
            showOnboard:false,
            showAdd:false,
            selectedFile:null,
            uploadedUrl:'',
            fileName:'',
            statusList:[
                {value: "orderable", label: "已注册"},
                {value: "onboarded", label: "已绑定"},
                {value: "terminated", label: "已终止"}
            ],
            providerList:[
                {value: "ShineMonitor", label: "ShineMonitor"},
                {value: "owon", label: "OWON"},
                {value: "homeAssistant", label: "homeAssistant"},
                {value: "enphase", label: "enphase"},
                {value: "common", label: "common"}
            ],
            minerSearch:{

            },
            registerMiner:{
                deviceId:undefined,
                ownerAddress:undefined,
                expired_time:undefined,
                provider:undefined,
                pn:undefined,
                sn:undefined,
                mac:undefined,
                deviceId:undefined,
                address:undefined,
                panelLink:undefined,
                country:undefined,
                city:undefined,
                ratedPower:undefined,
            },
            installPlant:{
                pn:undefined,
                sn:undefined,
                mac:undefined,
                deviceId:undefined,
                provider:undefined,
                devaddr:undefined,
                devcode:undefined,
                latitude:undefined,
                longitude:undefined,
                power:undefined,
                region:undefined,
                amount:undefined,
                expiredTime:730,
                owner:undefined,
                locationType:'GCS'

            },
            countryList : [
                {value: "Afghanistan",label: "Afghanistan"},
                {value: "Albania",label: "Albania"},
                {value: "Algeria",label: "Algeria"},
                {value: "Andorra",label: "Andorra"},
                {value: "Angola",label: "Angola"},
                {value: "Antarctica",label: "Antarctica"},
                {value: "Antigua and Barbuda",label: "Antigua and Barbuda"},
                {value: "Argentina",label: "Argentina"},
                {value: "Armenia",label: "Armenia"},
                {value: "Ashmore and Cartier Islands",label: "Ashmore and Cartier Islands"},
                {value: "Australia",label: "Australia"},
                {value: "Indian Ocean Territories",label: "Indian Ocean Territories"},
                {value: "Heard Island and McDonald Islands",label: "Heard Island and McDonald Islands"},
                {value: "Norfolk Island",label: "Norfolk Island"},
                {value: "Austria",label: "Austria"},
                {value: "Azerbaijan",label: "Azerbaijan"},
                {value: "Bahrain",label: "Bahrain"},
                {value: "Bangladesh",label: "Bangladesh"},
                {value: "Barbados",label: "Barbados"},
                {value: "Belarus",label: "Belarus"},
                {value: "Belgium",label: "Belgium"},
                {value: "Belize",label: "Belize"},
                {value: "Benin",label: "Benin"},
                {value: "Bhutan",label: "Bhutan"},
                {value: "Bolivia",label: "Bolivia"},
                {value: "Bosnia and Herzegovina",label: "Bosnia and Herzegovina"},
                {value: "Botswana",label: "Botswana"},
                {value: "Brazil",label: "Brazil"},
                {value: "Brunei",label: "Brunei"},
                {value: "Bulgaria",label: "Bulgaria"},
                {value: "Burkina Faso",label: "Burkina Faso"},
                {value: "Burundi",label: "Burundi"},
                {value: "Cambodia",label: "Cambodia"},
                {value: "Cameroon",label: "Cameroon"},
                {value: "Canada",label: "Canada"},
                {value: "Cape Verde",label: "Cape Verde"},
                {value: "Central African Republic",label: "Central African Republic"},
                {value: "Chad",label: "Chad"},
                {value: "Chile",label: "Chile"},
                {value: "China",label: "China"},
                {value: "Hong Kong S.A.R.",label: "Hong Kong S.A.R."},
                {value: "Macao S.A.R",label: "Macao S.A.R"},
                {value: "Colombia",label: "Colombia"},
                {value: "Comoros",label: "Comoros"},
                {value: "Costa Rica",label: "Costa Rica"},
                {value: "Croatia",label: "Croatia"},
                {value: "Cuba",label: "Cuba"},
                {value: "Cyprus",label: "Cyprus"},
                {value: "Czech Republic",label: "Czech Republic"},
                {value: "Democratic Republic of the Congo",label: "Democratic Republic of the Congo"},
                {value: "Denmark",label: "Denmark"},
                {value: "Greenland",label: "Greenland"},
                {value: "Faroe Islands",label: "Faroe Islands"},
                {value: "Djibouti",label: "Djibouti"},
                {value: "Dominica",label: "Dominica"},
                {value: "Dominican Republic",label: "Dominican Republic"},
                {value: "East Timor",label: "East Timor"},
                {value: "Ecuador",label: "Ecuador"},
                {value: "Egypt",label: "Egypt"},
                {value: "El Salvador",label: "El Salvador"},
                {value: "Equatorial Guinea",label: "Equatorial Guinea"},
                {value: "Eritrea",label: "Eritrea"},
                {value: "Estonia",label: "Estonia"},
                {value: "Ethiopia",label: "Ethiopia"},
                {value: "Federated States of Micronesia",label: "Federated States of Micronesia"},
                {value: "Fiji",label: "Fiji"},
                {value: "Aland",label: "Aland"},
                {value: "Finland",label: "Finland"},
                {value: "French Southern and Antarctic Lands",label: "French Southern and Antarctic Lands"},
                {value: "Saint Barthelemy",label: "Saint Barthelemy"},
                {value: "France",label: "France"},
                {value: "Wallis and Futuna",label: "Wallis and Futuna"},
                {value: "Saint Martin",label: "Saint Martin"},
                {value: "Saint Pierre and Miquelon",label: "Saint Pierre and Miquelon"},
                {value: "New Caledonia",label: "New Caledonia"},
                {value: "French Polynesia",label: "French Polynesia"},
                {value: "Gabon",label: "Gabon"},
                {value: "Gambia",label: "Gambia"},
                {value: "Georgia",label: "Georgia"},
                {value: "Germany",label: "Germany"},
                {value: "Ghana",label: "Ghana"},
                {value: "Greece",label: "Greece"},
                {value: "Grenada",label: "Grenada"},
                {value: "Guatemala",label: "Guatemala"},
                {value: "Guinea",label: "Guinea"},
                {value: "Guinea Bissau",label: "Guinea Bissau"},
                {value: "Guyana",label: "Guyana"},
                {value: "Haiti",label: "Haiti"},
                {value: "Honduras",label: "Honduras"},
                {value: "Hungary",label: "Hungary"},
                {value: "Iceland",label: "Iceland"},
                {value: "India",label: "India"},
                {value: "Indonesia",label: "Indonesia"},
                {value: "Iran",label: "Iran"},
                {value: "Iraq",label: "Iraq"},
                {value: "Ireland",label: "Ireland"},
                {value: "Israel",label: "Israel"},
                {value: "Palestine",label: "Palestine"},
                {value: "Italy",label: "Italy"},
                {value: "Ivory Coast",label: "Ivory Coast"},
                {value: "Jamaica",label: "Jamaica"},
                {value: "Japan",label: "Japan"},
                {value: "Jordan",label: "Jordan"},
                {value: "Siachen Glacier",label: "Siachen Glacier"},
                {value: "Kazakhstan",label: "Kazakhstan"},
                {value: "Kenya",label: "Kenya"},
                {value: "Kiribati",label: "Kiribati"},
                {value: "Kosovo",label: "Kosovo"},
                {value: "Kuwait",label: "Kuwait"},
                {value: "Kyrgyzstan",label: "Kyrgyzstan"},
                {value: "Laos",label: "Laos"},
                {value: "Latvia",label: "Latvia"},
                {value: "Lebanon",label: "Lebanon"},
                {value: "Lesotho",label: "Lesotho"},
                {value: "Liberia",label: "Liberia"},
                {value: "Libya",label: "Libya"},
                {value: "Liechtenstein",label: "Liechtenstein"},
                {value: "Lithuania",label: "Lithuania"},
                {value: "Luxembourg",label: "Luxembourg"},
                {value: "Macedonia",label: "Macedonia"},
                {value: "Madagascar",label: "Madagascar"},
                {value: "Malawi",label: "Malawi"},
                {value: "Malaysia",label: "Malaysia"},
                {value: "Maldives",label: "Maldives"},
                {value: "Mali",label: "Mali"},
                {value: "Malta",label: "Malta"},
                {value: "Marshall Islands",label: "Marshall Islands"},
                {value: "Mauritania",label: "Mauritania"},
                {value: "Mauritius",label: "Mauritius"},
                {value: "Mexico",label: "Mexico"},
                {value: "Moldova",label: "Moldova"},
                {value: "Monaco",label: "Monaco"},
                {value: "Mongolia",label: "Mongolia"},
                {value: "Montenegro",label: "Montenegro"},
                {value: "Morocco",label: "Morocco"},
                {value: "Mozambique",label: "Mozambique"},
                {value: "Myanmar",label: "Myanmar"},
                {value: "Namibia",label: "Namibia"},
                {value: "Nauru",label: "Nauru"},
                {value: "Nepal",label: "Nepal"},
                {value: "Aruba",label: "Aruba"},
                {value: "Curaçao",label: "Curaçao"},
                {value: "Netherlands",label: "Netherlands"},
                {value: "Sint Maarten",label: "Sint Maarten"},
                {value: "Cook Islands",label: "Cook Islands"},
                {value: "New Zealand",label: "New Zealand"},
                {value: "Niue",label: "Niue"},
                {value: "Nicaragua",label: "Nicaragua"},
                {value: "Niger",label: "Niger"},
                {value: "Nigeria",label: "Nigeria"},
                {value: "North Korea",label: "North Korea"},
                {value: "Northern Cyprus",label: "Northern Cyprus"},
                {value: "Norway",label: "Norway"},
                {value: "Oman",label: "Oman"},
                {value: "Pakistan",label: "Pakistan"},
                {value: "Palau",label: "Palau"},
                {value: "Panama",label: "Panama"},
                {value: "Papua New Guinea",label: "Papua New Guinea"},
                {value: "Paraguay",label: "Paraguay"},
                {value: "Peru",label: "Peru"},
                {value: "Philippines",label: "Philippines"},
                {value: "Poland",label: "Poland"},
                {value: "Portugal",label: "Portugal"},
                {value: "Qatar",label: "Qatar"},
                {value: "Republic of Congo",label: "Republic of Congo"},
                {value: "Republic of Serbia",label: "Republic of Serbia"},
                {value: "Romania",label: "Romania"},
                {value: "Russia",label: "Russia"},
                {value: "Rwanda",label: "Rwanda"},
                {value: "Saint Kitts and Nevis",label: "Saint Kitts and Nevis"},
                {value: "Saint Lucia",label: "Saint Lucia"},
                {value: "Saint Vincent and the Grenadines",label: "Saint Vincent and the Grenadines"},
                {value: "Samoa",label: "Samoa"},
                {value: "San Marino",label: "San Marino"},
                {value: "Sao Tome and Principe",label: "Sao Tome and Principe"},
                {value: "Saudi Arabia",label: "Saudi Arabia"},
                {value: "Senegal",label: "Senegal"},
                {value: "Seychelles",label: "Seychelles"},
                {value: "Sierra Leone",label: "Sierra Leone"},
                {value: "Singapore",label: "Singapore"},
                {value: "Slovakia",label: "Slovakia"},
                {value: "Slovenia",label: "Slovenia"},
                {value: "Solomon Islands",label: "Solomon Islands"},
                {value: "Somalia",label: "Somalia"},
                {value: "Somaliland",label: "Somaliland"},
                {value: "South Africa",label: "South Africa"},
                {value: "South Korea",label: "South Korea"},
                {value: "South Sudan",label: "South Sudan"},
                {value: "Spain",label: "Spain"},
                {value: "Sri Lanka",label: "Sri Lanka"},
                {value: "Sudan",label: "Sudan"},
                {value: "Suriname",label: "Suriname"},
                {value: "Swaziland",label: "Swaziland"},
                {value: "Sweden",label: "Sweden"},
                {value: "Switzerland",label: "Switzerland"},
                {value: "Syria",label: "Syria"},
                {value: "Tajikistan",label: "Tajikistan"},
                {value: "Thailand",label: "Thailand"},
                {value: "The Bahamas",label: "The Bahamas"},
                {value: "Togo",label: "Togo"},
                {value: "Tonga",label: "Tonga"},
                {value: "Trinidad and Tobago",label: "Trinidad and Tobago"},
                {value: "Tunisia",label: "Tunisia"},
                {value: "Turkey",label: "Turkey"},
                {value: "Taiwan",label: "Taiwan"},
                {value: "Turkmenistan",label: "Turkmenistan"},
                {value: "Uganda",label: "Uganda"},
                {value: "Ukraine",label: "Ukraine"},
                {value: "United Arab Emirates",label: "United Arab Emirates"},
                {value: "Anguilla",label: "Anguilla"},
                {value: "Bermuda",label: "Bermuda"},
                {value: "Falkland Islands",label: "Falkland Islands"},
                {value: "United Kingdom",label: "United Kingdom"},
                {value: "Guernsey",label: "Guernsey"},
                {value: "Isle of Man",label: "Isle of Man"},
                {value: "Cayman Islands",label: "Cayman Islands"},
                {value: "Jersey",label: "Jersey"},
                {value: "British Indian Ocean Territory",label: "British Indian Ocean Territory"},
                {value: "Montserrat",label: "Montserrat"},
                {value: "Pitcairn Islands",label: "Pitcairn Islands"},
                {value: "South Georgia and South Sandwich Islands",label: "South Georgia and South Sandwich Islands"},
                {value: "Saint Helena",label: "Saint Helena"},
                {value: "British Virgin Islands",label: "British Virgin Islands"},
                {value: "Turks and Caicos Islands",label: "Turks and Caicos Islands"},
                {value: "United Republic of Tanzania",label: "United Republic of Tanzania"},
                {value: "American Samoa",label: "American Samoa"},
                {value: "Guam",label: "Guam"},
                {value: "Northern Mariana Islands",label: "Northern Mariana Islands"},
                {value: "Puerto Rico",label: "Puerto Rico"},
                {value: "United States of America",label: "United States of America"},
                {value: "United States Virgin Islands",label: "United States Virgin Islands"},
                {value: "Uruguay",label: "Uruguay"},
                {value: "Uzbekistan",label: "Uzbekistan"},
                {value: "Vanuatu",label: "Vanuatu"},
                {value: "Vatican",label: "Vatican"},
                {value: "Venezuela",label: "Venezuela"},
                {value: "Vietnam",label: "Vietnam"},
                {value: "Western Sahara",label: "Western Sahara"},
                {value: "Yemen",label: "Yemen"},
                {value: "Zambia",label: "Zambia"},
                {value: "Zimbabwe",label: "Zimbabwe"},
        ]
        };
        this.check = this.check.bind(this)
        this.onStatusChange = this.onStatusChange.bind(this)
        this.onMinerAddressChange = this.onMinerAddressChange.bind(this)
        this.onPaginationChange = this.onPaginationChange.bind(this)
        this.SearchMiner = this.SearchMiner.bind(this)
        this.handleEditOk = this.handleEditOk.bind(this)
        this.handleEditCancel = this.handleEditCancel.bind(this)
        this.onEditExpiredTimeChange = this.onEditExpiredTimeChange.bind(this)
        this.handleShowEdit = this.handleShowEdit.bind(this)
        this.onCurrencyChange = this.onCurrencyChange.bind(this)
        this.registerMiner = this.registerMiner.bind(this)
        this.handleRegisterCancel = this.handleRegisterCancel.bind(this)
        this.onDeviceIdChange = this.onDeviceIdChange.bind(this)
        this.handleRegisterOk = this.handleRegisterOk.bind(this)
        this.handleOnboardCancel = this.handleOnboardCancel.bind(this)
        this.handleOnboardOk = this.handleOnboardOk.bind(this)
        this.onOwnerAddressChange = this.onOwnerAddressChange.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.onMinerExpiredTimeChange = this.onMinerExpiredTimeChange.bind(this)
        this.onProviderChange = this.onProviderChange.bind(this)
        this.onDevicePnChange = this.onDevicePnChange.bind(this)
        this.onDeviceSnChange = this.onDeviceSnChange.bind(this)
        this.onDeviceMacChange = this.onDeviceMacChange.bind(this)
        this.handlePowerPlant = this.handlePowerPlant.bind(this)
        this.onLatitudeChange = this.onLatitudeChange.bind(this)
        this.onLongitudeChange = this.onLongitudeChange.bind(this)
        this.onRegionChange = this.onRegionChange.bind(this)
        this.onAmountChange = this.onAmountChange.bind(this)
        this.onExpiredTimeChange = this.onExpiredTimeChange.bind(this)
        this.onPlantOwnerChange = this.onPlantOwnerChange.bind(this)
        this.handleAddOk = this.handleAddOk.bind(this)
        this.handleAddCancel = this.handleAddCancel.bind(this)
        this.onPowerChange = this.onPowerChange.bind(this)
        this.onPanelLinkChange = this.onPanelLinkChange.bind(this)
        this.onCountryChange = this.onCountryChange.bind(this)
        this.onCityChange = this.onCityChange.bind(this)
        this.onDeviceIdChange = this.onDeviceIdChange.bind(this)
        this.onMinerRatedPowerChange = this.onMinerRatedPowerChange.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleFileChange = this.handleFileChange.bind(this)
        this.handleUpload = this.handleUpload.bind(this)
    }
    async componentWillMount() {
        await this.check();
    }
    async queryMinerList(minerSearch){
        console.log(minerSearch.offset)
        let minerList = await PlantMinerApi.queryMinerList(minerSearch)
        return minerList;
    }
    async check() {
        const columns = [
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                width: 300,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Device Id',
                dataIndex: 'minerDeviceId',
                key: 'minerDeviceId',
                width: 250,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Miner Type',
                dataIndex: 'miner_type',
                width: 100,
                key: 'miner_type',
                render: (text) => {return <Tag color={'green'} key={"plantMiner"}>{'plantMiner'}</Tag>},
                
            },
            {
                title: 'Provider',
                dataIndex: 'Provider',
                width: 110,
                key: 'Provider',
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
                
            },
            {
                title: 'Status',
                key: 'status',
                width: 100,
                dataIndex: 'status',
                render: (status) => {
                    let color = 'red'
                    let text = '已终止'
                    if(status === 'orderable'){
                        color = '#1677ff'
                        text = '已注册'
                    }
                    if(status === 'onboarded'){
                        color = 'green'
                        text = '已绑定'
                    }
                    if(status === 'terminated'){
                        color = 'red'
                        text = '已终止'
                    }
                    return (
                        <Tag color={color} key={text}>{text}</Tag>
                    );
                }
            },
            {
                title: 'Owner',
                dataIndex: 'owner',
                width: 250,
                ellipsis:true,
                key: 'owner',
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'ratedPower(W)',
                dataIndex: 'ratedPower',
                width: 150,
                ellipsis:true,
                key: 'ratedPower',
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Create time',
                dataIndex: 'create_time',
                key: 'create_time',
                width: 250,
                render: (text) => {return <span>{text?formatDate(text):''}</span>},
            },
            {
                title: 'Action',
                key: 'action',
                fixed: 'right',
                render: (_, record) => (
                    <Space size="middle">
                        {
                            (record.status==='onboarded'||record.status==='terminated')?"":<Button
                                type="primary"
                                size='small'
                                disabled={false}
                                icon={<EditOutlined />}
                                onClick={() => {this.handleShowEdit(record)}}>
                                Onboard
                            </Button>

                            
                        }

                        {
                            (record.status==='orderable')?
                            <Button size='small' type="primary" icon={<DeleteOutlined />} onClick={()=>{
                                Modal.confirm({
                                title: 'Are you sure delete miner',
                                icon: <ExclamationCircleOutlined />,
                                content: record.name,
                                okText: 'Yes',
                                cancelText: 'Cancel',
                                onOk:()=>{this.handleDelete(record)}
                                    });
                                }}>
                                Delete
                                </Button>
                                :""
                        }

                        {
                            (record.status ==='terminated')?"":<Button
                                type="primary"
                                size='small'
                                disabled={false}
                                icon={<EditOutlined />}
                                onClick={() => {this.handlePowerPlant(record)}}>
                                PowerPlant
                            </Button>

                            
                        }
                    </Space>
                ),
            },
        ];
        this.setState({columns:columns})
        // 查询电站列表
        let resp = await this.queryMinerList(this.state.minerSearch);
        if(!resp.error){
            let result = resp.result
            if(!result){
                return;
            }
            const data=[]
            if(result.count>0){
                let minerSearch = this.state.minerSearch
                this.setState({totalCount:result.count})
                for (const item of result.list) {
                    if(minerSearch.status && minerSearch.status !== item.status){
                        continue;
                    }
                    if(minerSearch.address && minerSearch.address !== item.address){
                        continue;
                    }
                    const plant = {
                        key:item.address,
                        minerDeviceId:item.deviceId,
                        address:item.address,
                        miner_type:item.miner_type,
                        Provider:item.provider,
                        owner:item.owner,
                        // create_time:new Date(item.createTime * 1000).toLocaleDateString()  ,
                        create_time:item.createTime  ,
                        status:item.status,
                        ratedPower:item.ratedPower,
                    }
                    data.push(plant)
                }
            }else {
                this.setState({totalCount:0})
            }
            this.setState({plantList:data})
        }else {
            this.setState({plantList:undefined})
        }
    }
    onStatusChange(value){
        let minerSearch = this.state.minerSearch
        minerSearch.status = value
        this.setState({minerSearch:minerSearch})
    }
    onMinerAddressChange(e){
        let minerSearch = this.state.minerSearch
        minerSearch.address = e.target.value
        this.setState({minerSearch:minerSearch})
    }
    onPaginationChange(page, pageSize){
        let minerSearch = this.state.minerSearch
        minerSearch.offset = page
        minerSearch.size = pageSize
        this.setState({minerSearch:minerSearch})
        this.SearchMiner()
    }
    onDeviceIdChange(e){
        let registerMiner = this.state.registerMiner
        console.log(registerMiner);
        registerMiner.deviceId = e.target.value
        this.setState({registerMiner:registerMiner})
    }
    onDevicePnChange(e){
        console.log(e.target.value)
        let registerMiner = this.state.registerMiner
        registerMiner.pn = e.target.value
        this.setState({registerMiner:registerMiner})
    }
    onDeviceSnChange(e){
        let registerMiner = this.state.registerMiner
        registerMiner.sn = e.target.value
        this.setState({registerMiner:registerMiner})
    }

    onDeviceMacChange(e){
        let registerMiner = this.state.registerMiner
        registerMiner.mac = e.target.value
        this.setState({registerMiner:registerMiner})
    }

    onDeviceIdChange(e){
        let registerMiner = this.state.registerMiner
        registerMiner.deviceId = e.target.value
        this.setState({registerMiner:registerMiner})
    }
    onPanelLinkChange(e){
        let registerMiner = this.state.registerMiner
        registerMiner.panelLink = e.target.value
        this.setState({registerMiner:registerMiner})
    }

    onCountryChange(value){
        // let registerMiner = this.state.registerMiner
        // registerMiner.country = e.target.value
        // this.setState({registerMiner:registerMiner})
        let registerMiner = this.state.registerMiner
        if(!value || value.length == 0){
            error.message('country error')
            registerMiner.country = undefined
            this.setState({registerMiner:registerMiner})
            return;
        }
        const country = value[0]
        registerMiner.country = country
        this.setState({registerMiner:registerMiner})
    }

    onCityChange(e){
        let registerMiner = this.state.registerMiner
        registerMiner.city = e.target.value
        this.setState({registerMiner:registerMiner})
    }

    onMinerExpiredTimeChange(e){
        let registerMiner = this.state.registerMiner
        registerMiner.expired_time = e.target.value
        this.setState({registerMiner:registerMiner})
    }

    onMinerRatedPowerChange(e){
        let registerMiner = this.state.registerMiner
        registerMiner.ratedPower = Number(e.target.value)
        this.setState({registerMiner:registerMiner})
    }

    onOwnerAddressChange(e){
        let registerMiner = this.state.registerMiner
        console.log(registerMiner);
        registerMiner.ownerAddress = e.target.value
        this.setState({registerMiner:registerMiner})
    }
    SearchMiner(){
        this.check()
    }
    formatDateStr(value){
        if(value){
            return value.replace("T"," ").replace(".000Z","")
        }else {
            return ''
        }
    }
    handleEditOk(){
        const editMinerInfo = this.state.editMinerInfo;
        console.log("editMinerInfo:"+JSON.stringify(editMinerInfo))
        if(!editMinerInfo.address || editMinerInfo.address.length !== 42){
            message.error("Miner信息异常，请刷新页面后重试！")
            return;
        }
        if(!editMinerInfo.expired_time){
            message.error("过期天数不能为空！")
            return;
        }
        if(!editMinerInfo.price.amount){
            message.error("过期天数不能为空！")
            return;
        }
        if(Number(editMinerInfo.price.amount)<=0){
            message.error("请输入正确的过期天数！")
            return;
        }
        if(!editMinerInfo.price.currency){
            message.error("请选择用于支付的货币！")
            return;
        }
        //
        RemoteMinerApi.updateMiner(editMinerInfo).then(resp => {
            console.log(resp)
            if(!resp.error){
                message.success("Miner信息修改成功！")
                this.setState({showEdit:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
        })
    }

    handleOnboardOk(){
        let registerMiner = this.state.registerMiner
        if(!registerMiner.ownerAddress){
            message.error("请输入Miner 钱包地址！")
            return;
        }
        if(!ethers.utils.isAddress(registerMiner.ownerAddress)){
            message.error("请输入正确的钱包地址！")
            return;
        }

        PlantMinerApi.onboardMiners(registerMiner.address,registerMiner.ownerAddress).then(resp => {
            if(!resp.error && !resp.result.error){
                message.success("Standard Miner onboard成功！")
                this.setState({showOnboard:false})
                this.check()
            }else {
                if(resp.error){
                    message.error(resp.error.message)
                }else{
                    message.error(resp.result.error.message)
                }
                
            }
            this.setState({confirmLoading:false})
        })

    }
    handleEditCancel(){
        this.setState({showEdit:false})
    }
    onEditExpiredTimeChange(e){
        let editMinerInfo = this.state.editMinerInfo
        editMinerInfo.expired_time = e.target.value
        this.setState({editMinerInfo:editMinerInfo})
    }
    handleShowEdit(record){
        // let editMinerInfo = this.state.editMinerInfo;
        // editMinerInfo.address = record.address
        // editMinerInfo.expired_time = Math.round(record.expired_time/86400)
        // editMinerInfo.price = record.priceDetail
        // this.setState({editMinerInfo:editMinerInfo})
        // console.log("editMinerInfo:"+JSON.stringify(editMinerInfo))
        
        // this.setState({showEdit:true})
        let registerMiner = this.state.registerMiner
        registerMiner.address = record.address
        this.setState({registerMiner:registerMiner})
        this.setState({showOnboard:true})
    }

    async handlePowerPlant(record){
        console.log(record)
        let installPlant = this.state.installPlant;
        let model = undefined;
        if(record.Provider == 'ShineMonitor' || record.Provider == 'owon' ){
            const deviceId = JSON.parse(record.minerDeviceId) ;
            model = {
                provider:record.Provider,
                pn:deviceId.pn,
                sn:deviceId.sn,
                mac: deviceId.mac?commonUtils.reverseHexStringBytes(deviceId.mac) :deviceId.mac,
                deviceId:undefined
            }
        }else if(record.Provider == 'homeAssistant' || record.Provider == 'enphase' || record.Provider == 'common'){
            model = {
                provider:record.Provider,
                pn:undefined,
                sn:undefined,
                mac: undefined,
                deviceId:record.minerDeviceId
            }
        }
        if(!model){
            message.error('deviceId error')
            return;
        }
       
        console.log('model = ',model)
        const res = await PlantServiceApi.queryPlantDataByDeviceId(model);
        const plantdata = res.result;
        console.log(plantdata)
        if(record.Provider == 'ShineMonitor'){
            installPlant.pn = plantdata.pn;
            installPlant.sn = plantdata.sn;
            installPlant.devaddr = plantdata.devaddr;
            installPlant.devcode = plantdata.devcode;
            installPlant.owner = record.address;
            installPlant.amount = plantdata.amount;
            installPlant.latitude = plantdata.location.lat;
            installPlant.longitude = plantdata.location.lon;
            // installPlant.power = plantdata.ratedPower;
            installPlant.region = plantdata.country;
            installPlant.provider = plantdata.provider;
        }else if(record.Provider == 'owon'){
            const deviceId = JSON.parse(record.minerDeviceId) ;
            installPlant.owner = record.address;
            installPlant.provider = record.Provider;
            installPlant.mac = deviceId.mac;
        }else if(record.Provider == 'homeAssistant' || record.Provider == 'enphase' || record.Provider == 'common'){
            installPlant.owner = record.address;
            installPlant.provider = record.Provider;
            installPlant.deviceId = record.minerDeviceId;
            // installPlant.power = plantdata.ratedPower;
            installPlant.region = plantdata.country;
        }
        installPlant.power = record.ratedPower ? Number(record.ratedPower) : 0;
        
        this.setState({installPlant:installPlant})
        this.setState({showAdd:true})
    }

    handleDelete(record){

        if(!record.minerDeviceId){
            message.error("Miner所属的deviceId 为空！")
            return;
        }
        this.setState({confirmLoading:true})
        PlantMinerApi.deleteMiner(record.minerDeviceId).then(resp => {
            if(!resp.error){
                message.success("Socket Miner删除成功！")
                this.check()
            }else {
                message.error(resp.error.message)
            }
            this.setState({confirmLoading:false})
        })
    }
    onCurrencyChange(value){
        let editMinerInfo = this.state.editMinerInfo;
        editMinerInfo.price.currency = value
        this.setState({editMinerInfo:editMinerInfo})
    }

    registerMiner(){
        this.setState({showRegister:true})
    }
    handleRegisterOk(){
        let registerMiner = this.state.registerMiner
        if(!registerMiner.provider){
            message.error("请输入选择provider！")
            return;
        }
        if(!registerMiner.expired_time){
            message.error("请输入Miner所属的expired_time！")
            return;
        }

        if(!registerMiner.ratedPower){
            message.error("请输入Miner所属的ratedPower！")
            return;
        }
        switch (registerMiner.provider) {
            case 'ShineMonitor':
                if(!registerMiner.pn){
                    message.error("请输入Miner所属的pn！")
                    return;
                }
                if(!registerMiner.sn){
                    message.error("请输入Miner所属的sn！")
                    return;
                }
                const deviceId = {pn:registerMiner.pn,sn:registerMiner.sn};
                registerMiner.deviceId = JSON.stringify(deviceId);
                break;
            case 'owon':
                if(!registerMiner.mac){
                    message.error("请输入Miner所属的mac！")
                    return;
                }
                
                registerMiner.deviceId = JSON.stringify({mac:registerMiner.mac});
                break;
            case 'homeAssistant':
                if(!registerMiner.deviceId){
                    message.error("请输入Miner所属的deviceId！")
                    return;
                }
                
                registerMiner.deviceId = registerMiner.deviceId;
                break;
            case 'enphase':
                if(!registerMiner.deviceId){
                    message.error("请输入Miner所属的deviceId！")
                    return;
                }
                
                registerMiner.deviceId = registerMiner.deviceId;
                break;
            case 'common':
                if(!registerMiner.deviceId){
                    message.error("请输入Miner所属的deviceId！")
                    return;
                }
                
                registerMiner.deviceId = registerMiner.deviceId;
                break;
        
            default:
                message.error("请输入Miner所属的provider 错误！")
                return;
                
        }
        if(!registerMiner.panelLink){
            message.error("请输入Miner所属的光伏板图片链接！")
            return;
        }
        if(!registerMiner.country){
            message.error("请输入Miner所属的光伏板所在国家信息！")
            return;
        }
        if(!registerMiner.city){
            message.error("请输入Miner所属的光伏板所在城市信息！")
            return;
        }
        this.setState({confirmLoading:true})
        PlantMinerApi.registerMiners(registerMiner).then(resp => {
            if(!resp.error && !resp.result.error){
                message.success("Plant Miner注册成功！")
                this.setState({showRegister:false})
                this.check()
            }else {
                if(resp.error){
                    message.error(resp.error.message)
                }else{
                    message.error(resp.result.error.message)
                }
                
            }
            this.setState({confirmLoading:false})
        })
    }
    handleRegisterCancel(){
        this.setState({showRegister:false})
        this.setState({selectedFile:null})
        this.setState({uploadedUrl:''})
        this.setState({fileName:''})
    }

    handleOnboardCancel(){
        this.setState({showOnboard:false})
    }

    onProviderChange(value){
        let registerMiner = this.state.registerMiner
        registerMiner.provider = value
        this.setState({registerMiner:registerMiner})
    }

    onLatitudeChange(e){
        const latitude = e.target.value
        let installPlant = this.state.installPlant
        installPlant.latitude = latitude
        this.setState({installPlant:installPlant})
    }

    onLongitudeChange(e){
        const longitude = e.target.value
        let installPlant = this.state.installPlant
        installPlant.longitude = longitude
        this.setState({installPlant:installPlant})
    }
    onRegionChange(value){
        let installPlant = this.state.installPlant
        if(!value || value.length == 0){
            error.message('region error')
            installPlant.region = undefined
            this.setState({installPlant:installPlant})
            return;
        }
        const region = value[0]
        installPlant.region = region
        this.setState({installPlant:installPlant})
    }
    onPowerChange(e){
        const power = e.target.value
        let installPlant = this.state.installPlant
        installPlant.power = power
        this.setState({installPlant:installPlant})
    }
    onAmountChange(e){
        const amount = e.target.value
        let installPlant = this.state.installPlant
        installPlant.amount = amount
        this.setState({installPlant:installPlant})
    }
    onExpiredTimeChange(e){
        const expiredTime = e.target.value
        let installPlant = this.state.installPlant
        installPlant.expiredTime = expiredTime
        this.setState({installPlant:installPlant})
    }
    onPlantOwnerChange(e){
        const address = e.target.value
        let installPlant = this.state.installPlant
        installPlant.owner = address
        this.setState({installPlant:installPlant})
    }
    handleClick(){
        document.getElementById('fileInput').click();
      };
    handleFileChange(event){
        const file = event.target.files[0];
        if(file){
            this.setState({selectedFile:file});
            this.setState({fileName:file.name});
        }
      };
    handleUpload(){
        if (!this.state.selectedFile) {
          message.error('请先选择一个文件!');
          return;
        }
        let token = getFilters("token");
    
        const formData = new FormData();
        formData.append('file', this.state.selectedFile);
        formData.append('filePath','public/plant/');
        // token = token.replace(/"/g,'');
        token = token.substring(1);
        token = token.substring(0,token.length-1)
        console.log('token : ',token)
        try {
            console.log('s3Url : ',s3Url);
        axios.post(s3Url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'authorization':'Bearer '+ token
            },
          }).then(response => {
                // 假设响应包含已上传文件的 URL
                // setUploadedUrl(response.data.url);
                console.log('response : ',response);
                const code = response.data.code;
                if(code == 0){
                    this.setState({uploadedUrl:response.data.data})
                    let registerMiner = this.state.registerMiner
                    registerMiner.panelLink = response.data.data
                    this.setState({registerMiner:registerMiner})
                }else{
                    message.error('upload failed !!!');
                }
                
          });
    

        } catch (error) {
          console.error('上传文件时出错:', error);
          message.error('upload failed !!!');
        }
      };

    handleAddOk(){
        const installPlant = this.state.installPlant;
        console.log("installPlant:"+JSON.stringify(installPlant))
        if(installPlant){
            if(!installPlant.provider){
                message.error("请选择电站提供方")
                return;
            }
            if(installPlant.provider == 'owon' && !installPlant.mac){
                message.error("请输入目标电站mac")
                return;
            }
            if(installPlant.provider == 'ShineMonitor' && !installPlant.pn){
                message.error("请输入目标电站pn号")
                return;
            }
            if(installPlant.provider == 'ShineMonitor' && !installPlant.sn){
                message.error("请输入目标电站sn号")
                return;
            }
            if(installPlant.provider == 'homeAssistant' && !installPlant.deviceId ){
                message.error("请输入目标电站deviceId号")
                return;
            }
            if(installPlant.provider == 'enphase' && !installPlant.deviceId ){
                message.error("请输入目标电站deviceId号")
                return;
            }
            if(installPlant.provider == 'common' && !installPlant.deviceId ){
                message.error("请输入目标电站deviceId号")
                return;
            }
            if(installPlant.provider == 'ShineMonitor' && !installPlant.devaddr){
                message.error("请输入目标电站devaddr")
                return;
            }
            if(installPlant.provider == 'ShineMonitor' && !installPlant.devcode){
                message.error("请输入目标电站devcode")
                return;
            }
            if(!installPlant.latitude){
                message.error("请输入维度（latitude）")
                return;
            }
            if(!installPlant.longitude){
                message.error("请输入经度（longitude）")
                return;
            }
            if(!installPlant.power){
                message.error("请输入电站总功率")
                return;
            }
            if(!installPlant.region){
                message.error("请输入Region信息")
                return;
            }
            if(!installPlant.amount){
                message.error("请输入生成的Miner数量")
                return;
            }
            if(!installPlant.expiredTime){
                message.error("请输入电站过期时间（天）")
                return;
            }
            if(!ethers.utils.isAddress(installPlant.owner)){
                message.error("请输入正确的owner地址")
                return;
            }
            //
            this.setState({confirmLoading:true})
            RemoteMinerApi.installPlant(installPlant).then(resp => {
                if(!resp.error && resp.result && !resp.result.error){
                    console.log(resp)
                    message.success("电站安装成功，电站地址："+resp.result)
                    this.check();
                    this.handleAddCancel();
                }else {
                    if(resp.error){
                        message.error(resp.error.message)
                    }else if(resp.result){
                        message.error(resp.result.error.message)
                    }else{
                        message.error('安装失败')
                    }
                    
                }
                this.setState({confirmLoading:false})
            })
            //
        }else {
            message.error("请输入电站安装参数")

        }
    }
    handleAddCancel(){
        let Info = {
            pn:undefined,
            sn:undefined,
            mac:undefined,
            provider:undefined,
            devaddr:undefined,
            devcode:undefined,
            latitude:undefined,
            longitude:undefined,
            power:undefined,
            region:undefined,
            amount:undefined,
            expiredTime:undefined,
            owner:undefined,
            locationType:'GCS',
            
        };
        this.setState({installPlant:Info});
        this.setState({showAdd:false})
    }

    

    render() {
        return (
            <div style={{width:'100%',height:'100%',overflow:"hidden"}}>
                <Row>
                    <Col span={4}>
                        <span style={{fontWeight:'bold',fontSize:18}}>
                            Miner Manage
                        </span>
                    </Col>
                    <Col span={20}>
                        <div style={{textAlign:'right'}}>
                            <span>
                                Address:<Input onChange={this.onMinerAddressChange} size='small' style={{width:180}} placeholder="miner address" allowClear />
                            </span>
                            <span style={{marginLeft:10}}>Status:
                            <Select size='small' style={{width:150}} placeholder="Select a status" onChange={this.onStatusChange} allowClear options={this.state.statusList} />
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' onClick={this.SearchMiner} type="primary" icon={<SearchOutlined />}>Search</Button>
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' danger onClick={this.registerMiner} icon={<PlusCircleOutlined />}>Register Miner</Button>
                            </span>
                        </div>
                    </Col>
                </Row>
                {this.state.totalCount>10?<div style={{textAlign:'right',paddingTop:15}}><Pagination onChange={this.onPaginationChange} size="small" total={this.state.totalCount} showSizeChanger showQuickJumper /></div>:''}
                <div style={{marginTop:10,width:'100%',height:'100%',overflow:'auto'}}>
                    <Table scroll={{x: 1620}} size='small' pagination={false} columns={this.state.columns} dataSource={this.state.plantList} />
                </div>
                <Modal width='800px' title="Register Miner" open={this.state.showRegister} destroyOnClose={true} onOk={this.handleRegisterOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleRegisterCancel}>
                    <Row>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Provider:</span>
                            </Col>
                            <Col span={20}>
                            <Select style={{width:'100%'}} placeholder="Select a provider" onChange={this.onProviderChange} allowClear options={this.state.providerList} />
                            </Col>
                            <Col span={24} style={{height:10}}></Col>
                            {this.state.registerMiner.provider == 'ShineMonitor'?<>
                                <Col span={24} style={{height:10}}></Col>
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>pn:</span></Col>
                                <Col span={20}><TextArea rows={2} onChange={this.onDevicePnChange} /></Col>
                                <Col span={24} style={{height:10}}></Col>
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>sn:</span></Col>
                                <Col span={20}><TextArea rows={2} onChange={this.onDeviceSnChange} /></Col>
                                </>:<></>
                            }
                            {
                                this.state.registerMiner.provider == 'owon'?<>
                                <Col span={24} style={{height:10}}></Col>
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>mac:</span></Col>
                                <Col span={20}><TextArea rows={2} onChange={this.onDeviceMacChange} /></Col>
                                </>:<></>
                                
                            }
                            {
                                this.state.registerMiner.provider == 'homeAssistant' || this.state.registerMiner.provider == 'enphase' || this.state.registerMiner.provider == 'common'?<>
                                <Col span={24} style={{height:10}}></Col>
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>deviceId:</span></Col>
                                <Col span={20}><TextArea rows={2} onChange={this.onDeviceIdChange} /></Col>
                                </>:<></>
                                
                            }

                        <Col span={24} style={{height:10}}></Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Panel Link:</span></Col>
                        {/* <Col span={20}><TextArea rows={2} onChange={this.onPanelLinkChange} /></Col> */}
                        <Col span={0}><input id="fileInput" type="file" onChange={this.handleFileChange}  style={{ display: 'none' }} /> </Col>
                        {/* {this.state.fileName && (<Col span={14}><TextArea  type="text"  value={this.state.fileName} readOnly /> </Col>)} */}
                        { this.state.uploadedUrl? <Col span={14}><TextArea  type="text"  value={this.state.registerMiner.panelLink}  readOnly /> </Col> :
                            this.state.selectedFile? <Col span={14}><TextArea  type="text"  value={this.state.fileName} readOnly /> </Col> :
                            <div></div>}
                        <Col span={3}> <Button type="primary" onClick={this.handleClick}>选择文件</Button> </Col>
                        <Col span={3}> <Button type="primary" onClick={this.handleUpload}>上传文件</Button> </Col>

                        
                        <Col span={24} style={{height:10}}></Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Country:</span></Col>
                        <Col span={20}>
                            {/* <TextArea rows={2} onChange={this.onCountryChange} /> */}
                            <Select mode="tags" style={{width:'100%'}}  onChange={this.onCountryChange} allowClear options={this.state.countryList} 
                                optionRender={(option) => (<Space>{option.data.value}</Space>)}  />
                        </Col>
                        <Col span={24} style={{height:10}}>
                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>City:</span></Col>
                        <Col span={20}><TextArea rows={2} onChange={this.onCityChange} /></Col>
                        <Col span={24} style={{height:10}}></Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>ratedPower:</span></Col>
                        <Col span={20}><Input readOnly={this.state.confirmLoading} type='number' placeholder="rated power of this miner" onChange={this.onMinerRatedPowerChange} suffix="W"/></Col>
                        <Col span={24} style={{height:10}}></Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Expired Time:</span>
                        </Col>
                        <Col span={20}>
                            <Input readOnly={this.state.confirmLoading} type='number' placeholder="expired time of this miner" onChange={this.onMinerExpiredTimeChange} suffix="days"/>
                        </Col>
                    </Row>
                </Modal>

                <Modal width='800px' title="Onboard Miner" open={this.state.showOnboard} destroyOnClose={true} onOk={this.handleOnboardOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleOnboardCancel}>
                    <Row>
                        {
                            <Col span={24} style={{height:10}}></Col>
                        }
                        {
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Owner Address:</span></Col>
                        }
                        {
                            <Col span={20}><TextArea rows={2} onChange={this.onOwnerAddressChange} /></Col>
                        }
                    </Row>
                </Modal>

                <Modal width='800px' title="Install Plant" destroyOnClose={true} open={this.state.showAdd} onOk={this.handleAddOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleAddCancel}>
                    <Row>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Provider:</span>
                        </Col>
                        <Col span={20}>
                            {/* <Select style={{width:'100%'}} placeholder="Select a provider" onChange={this.onProviderChange} allowClear options={this.state.providerList} /> */}
                            <Input placeholder="devaddr"  value={this.state.installPlant.provider}/>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        {
                            (this.state.installPlant.provider == 'ShineMonitor' || this.state.installPlant.provider == 'ShineMonitor_1') ? <>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>PN:</span>
                                </Col>
                                <Col span={20}>
                                    <Input placeholder="pn"  value={this.state.installPlant.pn} />
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>SN:</span>
                                </Col>
                                <Col span={20}>
                                    <Input placeholder="sn"  value={this.state.installPlant.sn}/>
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>Devaddr:</span>
                                </Col>
                                <Col span={20}>
                                    <Input placeholder="devaddr"  value={this.state.installPlant.devaddr}/>
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>Devcode:</span>
                                </Col>
                                <Col span={20}>
                                    <Input placeholder="devcode"  value={this.state.installPlant.devcode}/>
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                            </>:<></>
                        }
                        {
                            this.state.installPlant.provider == 'owon'  ? <>
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>mac:</span>
                                </Col>
                                <Col span={20}>
                                    <Input placeholder="mac"  value={this.state.installPlant.mac} />
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                            </>:<></>
                        }
                        {
                            this.state.installPlant.provider == 'homeAssistant' || this.state.installPlant.provider == 'enphase' || this.state.installPlant.provider == 'common'  ? <>
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>deviceId:</span>
                                </Col>
                                <Col span={20}>
                                    <Input placeholder="deviceId"  value={this.state.installPlant.deviceId} />
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                            </>:<></>
                        }
                                
                                
                         
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Location:</span>
                        </Col>
                        <Col span={20}>
                            <Row>
                                <Col span={8} style={{paddingLeft:5}}>
                                    <Input type='number' placeholder="latitude" onChange={this.onLatitudeChange} value={this.state.installPlant.latitude} />
                                </Col>
                                <Col span={8} style={{paddingLeft:5}}>
                                    <Input type='number' placeholder="longitude" onChange={this.onLongitudeChange} value={this.state.installPlant.longitude}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Power:</span>
                        </Col>
                        <Col span={20}>
                            <Input placeholder="plant total power" onChange={this.onPowerChange} suffix="W"  value={this.state.installPlant.power}/>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Region:</span>
                        </Col>
                        <Col span={20}>
                            {/* <Input placeholder="region" onChange={this.onRegionChange} value={this.state.installPlant.region}/> */}
                            <Select mode="tags" style={{width:'100%'}} placeholder={this.state.installPlant.region} onChange={this.onRegionChange} allowClear options={this.state.countryList} 
                                optionRender={(option) => (<Space>{option.data.value}</Space>)}  />
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Miner Amount:</span>
                        </Col>
                        <Col span={20}>
                            <Input type='number' placeholder="miner amount of this plant" onChange={this.onAmountChange} />
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Expired Time:</span>
                        </Col>
                        <Col span={20}>
                            <Input type='number' placeholder="expired time of this plant" onChange={this.onExpiredTimeChange} suffix="days"/>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Owner:</span>
                        </Col>
                        <Col span={20}>
                            <Input  placeholder="owner's address of this plant" onChange={this.onPlantOwnerChange} value={this.state.installPlant.owner} />
                        </Col>
                    </Row>
                </Modal>
            </div>
        )
    }
}

export default PlantMiner;

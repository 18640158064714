import HttpUtils from '../utils/HttpUtils'
import {getFilters} from "../utils/browserUtils";
const baseUrl = process.env.REACT_APP_BASE_API

async function queryMinerList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_plantminer_list", params,token)
}

async function registerMiners(params){
    console.log(params)
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_plantminer_register", params,token)
}

async function onboardMiners(address,owner){
    let params = {
        address:address,
        owner:owner
    }
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_plantminer_onboard", params,token)
}

async function deleteMiner(deviceId){
    let params = {
        mac:deviceId,
    }
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_plantminer_delete", params,token)
}




const PlantMinerApi ={
    queryMinerList:queryMinerList,
    registerMiners:registerMiners,
    onboardMiners:onboardMiners,
    deleteMiner:deleteMiner,
}

export default PlantMinerApi;

import Login from '../../views/login/Login';
import Index from '../../views/index/Index';

const mainRouter = [
    {
        path: "/login",
        name: "User Login",
        component: <Login />,
        auth: false
    },
    {
        path: "/index",
        name: "index",
        component: <Index />,
        auth: true
    },
    {
        path: "/",
        name: "index",
        component: <Index />,
        auth: true
    }
]

export default mainRouter;

import React from "react";
import { Space, Table, Tag,Row,Col,Button,Modal,Input,Select,message,Pagination } from 'antd';
import {SearchOutlined, EditOutlined, StopOutlined, ExclamationCircleOutlined,PlusOutlined} from '@ant-design/icons';
import RemoteMinerApi from "../../../api/remoteMinerApi";
import MarketServiceApi from "../../../api/MarketServiceApi"
import {copyText, formatDate} from '../../../utils/browserUtils';
import {symbol} from '../../../utils/contractUtils';


class RemoteMinerQuality extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ownerAddress:undefined,
            minerData:undefined,
            confirmLoading:false,
        };
        this.check = this.check.bind(this)
        this.SearchOwner = this.SearchOwner.bind(this)
        this.onOwnerAddressChange = this.onOwnerAddressChange.bind(this)
    }
    async componentWillMount() {
        await this.check();
    }
    async queryMinerList(minerSearch){
        let minerList = await RemoteMinerApi.queryMinerList(minerSearch)
        return minerList;
    }
    
    async check() {
        
    }

    async SearchOwner(){
        const owner = this.state.ownerAddress;
        if(!owner){
            message.error("owner 地址错误 ！")
        }
        this.setState({confirmLoading:true})
        const res  = await this.powerQualityCheckByOwner(owner);
        console.log(res)
        if(!res.error){
            let result = res.result
            if(!result){
                this.setState({minerData:undefined})
                // return;
            }else{
                this.setState({minerData:JSON.stringify(result,null,2)})
            }
            // const data = JSON.stringify(res,null,2);
            // console.log(data)
            // this.setState({minerData:data})
        }else{
            this.setState({minerData:undefined})
        }
        this.setState({confirmLoading:false})
    }

    async powerQualityCheckByOwner(ownerSearch){
        const params = {
            owner:ownerSearch
        }
        let res = await MarketServiceApi.powerQualityCheckByOwner(params)
        return res;
    }

    onOwnerAddressChange(e){
        console.log(e)
        let ownerAddress = this.state.ownerAddress
        ownerAddress = e.target.value
        this.setState({ownerAddress:ownerAddress})
    }

    handleCopy = (text) => {
        if(!text){
            message.info('内容为空');
            return;
        }
        navigator.clipboard.writeText(text).then(() => {
            message.info('复制成功!');
        }).catch(err => {
          message.error('复制失败:', err);
        });
      }
    
    render() {
        return (
            <div style={{width:'100%',height:'100%',overflow:"hidden"}}>
                <Row>
                <Col span={4}>
                            <span style={{fontWeight:'bold',fontSize:18}}>
                                Data Quality
                            </span>
                    </Col>
                </Row>
                <Row>

                    <Col span={24}>
                        <div style={{textAlign:'right'}}>
                            <span>
                                Address:<Input onChange={this.onOwnerAddressChange} size='small' style={{width:180}} placeholder="miner address" allowClear />
                            </span>
                            <Button style={{marginLeft:10}} size='small' onClick={this.SearchOwner} type="primary" icon={<SearchOutlined />}>Search</Button>
                        </div>
                    </Col>
                </Row>
                
                    <div style={{display:'flex',width:'100%',height:'700px',backgroundColor:'#ffffff',color:'#101020',borderRadius:'8px',padding:'16px',marginTop:'20px',overflow:'auto',justifyContent:'center' }}   onClick = {() => this.handleCopy(this.state.minerData)} >
                        {this.state.confirmLoading? <div className="loader">Loading...</div>:<pre>{this.state.minerData}</pre>}
                        
                    </div>
                
                
                
            </div>
        )
    }
}

export default RemoteMinerQuality;

import React from 'react'
import logo from '../../assets/svg/maker-arkreen.svg';
import metamask from '../../assets/images/metamask-logo.png';
import './Login.css';
import {setFilters} from '../../utils/browserUtils';
import { Button,Row,Col,message } from 'antd';
import {loginWithMetaMask, personalSign} from "../../utils/metamaskUtils";
import UserApi from "../../api/userApi";

async function login(data){
    console.log("login")
    let result = await UserApi.userLogin(data)
    console.log(result)
    if(!result.error){
        if(result.result){
            setFilters("token",result.result);
            return true;
        }else {
         return false;
        }
    }
    return false;
}

class Login extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    async LoginWithMetamask(){
        const address = await loginWithMetaMask();
        console.log("login user address:"+address)
        if(address){
            let timestamp = Math.round(new Date() / 1000)
            let messageStr = "{address:\""+address+"\",timestamp:\""+timestamp+"\"}"
            let sign = await personalSign(messageStr)
            console.log("sign:"+sign)
            if(sign){
                const data = {
                    address:address,
                    timestamp:timestamp,
                    sign:sign
                }
                const flag = await login(data)
                if(flag){
                    window.location.reload();
                }else {
                    message.error("Login failed,please try again!")
                }
            }else {
                message.error("Login failed,please try again!")
            }
        }else {

        }
    }



    render() {
        return (
            <div className="App">
                <Row className="App-header">
                    <Col span={12} offset={6}>
                        <div className="contextBody">
                            <Row>
                                <Col span={24}>
                                    <img src={logo} className="App-logo" alt="logo" />
                                </Col>
                                <Col span={12} style={{textAlign:"center",paddingTop:10}}>
                                    <div>
                                        <span style={{fontSize:24,fontWeight:"bold"}}>Arkreen Admin</span>
                                    </div>
                                    <div style={{textAlign:"left",padding:'15px'}}>
                                        <span style={{fontSize:20}}>Build A Decentralized Renewable Energy Resources Network</span>
                                        <br/>
                                        <br/>
                                        <span style={{fontSize:16}}>Arkreen Network is a Web3-powered infrastructure for globally-distributed renewable energy resources, allowing the connection and monetization of carbon-reduction applications.</span>
                                        <br/>
                                    </div>
                                </Col>
                                <Col span={12} style={{textAlign:"center",paddingTop:10}}>
                                    <div>
                                        <span style={{fontSize:24,fontWeight:"bold"}}>Connect your wallet</span>
                                    </div>
                                    <div style={{padding:20}}>
                                        <div style={{background:'#3C3C3C',borderRadius:5,height: '30vh',paddingTop:5}}>
                                            <Row style={{padding:'10px 20px',}}>
                                                <Col span={16} style={{textAlign:"left"}}>
                                                    <div style={{height: 35,position: 'absolute',top: 0,right: 0, left: 0, bottom: 0, margin: 'auto'}}>
                                                        <img style={{height:35,verticalAlign:'top'}} src={metamask}/>
                                                        <span style={{fontWeight:'bold',lineHeight:'35px',marginLeft:8,fontSize:18}}>MetaMask</span>
                                                    </div>
                                                </Col>
                                                <Col span={8} style={{textAlign:"right"}}>
                                                    <Button type="primary"  onClick={this.LoginWithMetamask}>Popular</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Login;

import HttpUtils from '../utils/HttpUtils'
import {getFilters} from "../utils/browserUtils";
const baseUrl = process.env.REACT_APP_BASE_API

async function queryMinerList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_socketminer_list", params,token)
}

async function registerMiners(params){
    console.log(params)
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_socketminer_register", params,token)
}

async function onboardMiners(deviceId,owner){
    let params = {
        mac:deviceId,
        owner:owner
    }
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_socketminer_onboard", params,token)
}

async function deleteMiner(deviceId){
    let params = {
        mac:deviceId,
    }
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_socketminer_delete", params,token)
}

async function queryPlugList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_socketminer_plug_list", params,token)
}




const SocketMinerApi ={
    queryMinerList:queryMinerList,
    registerMiners:registerMiners,
    onboardMiners:onboardMiners,
    deleteMiner:deleteMiner,
    queryPlugList:queryPlugList,
}

export default SocketMinerApi;

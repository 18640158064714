import React from "react";
import { Space, Table, Tag,Row,Col,Button,Modal,Input,Select,message,Pagination } from 'antd';
import {SearchOutlined, EditOutlined, StopOutlined, ExclamationCircleOutlined,PlusOutlined,PlusCircleOutlined,DeleteOutlined} from '@ant-design/icons';
import {copyText, formatDate} from '../../../utils/browserUtils';
import SocketMinerApi from "../../../api/SocketMinerApi"
import * as ethers from 'ethers'
const { TextArea } = Input;

class SocketMinerPlugs extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            columns:true,
            totalCount:0,
            showRegister:false,
            confirmLoading:false,
            statusList:[
                {value: true, label: "已注册"},
                {value: false, label: "未注册"}
            ],
            plugSearch:{

            },
            providerList:[],
            registerMiner:{
                deviceId:undefined,
                ownerAddress:undefined,
                expired_time:undefined
            },
        };
        this.check = this.check.bind(this)
        this.onStatusChange = this.onStatusChange.bind(this)
        this.onPlugIeeeChange = this.onPlugIeeeChange.bind(this)
        this.onPaginationChange = this.onPaginationChange.bind(this)
        this.SearchPlug = this.SearchPlug.bind(this)
        this.handleEditOk = this.handleEditOk.bind(this)
        this.handleEditCancel = this.handleEditCancel.bind(this)
        this.onEditExpiredTimeChange = this.onEditExpiredTimeChange.bind(this)
        this.handleShowEdit = this.handleShowEdit.bind(this)
        this.onCurrencyChange = this.onCurrencyChange.bind(this)
        this.registerMiner = this.registerMiner.bind(this)
        this.handleRegisterCancel = this.handleRegisterCancel.bind(this)
        this.onDeviceIdChange = this.onDeviceIdChange.bind(this)
        this.handleRegisterOk = this.handleRegisterOk.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.onMinerExpiredTimeChange = this.onMinerExpiredTimeChange.bind(this)
    }
    async componentWillMount() {
        await this.check();
    }
    async queryPlugList(plugSearch){
        console.log(plugSearch.offset)
        let plugList = await SocketMinerApi.queryPlugList(plugSearch)
        return plugList;
    }
    async check() {
        const columns = [
            {
                title: 'plug',
                dataIndex: 'ieee',
                key: 'ieee',
                width: 200,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'gateWay',
                dataIndex: 'mac',
                key: 'mac',
                width: 200,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'energy',
                dataIndex: 'energy',
                width: 200,
                key: 'energy',
                render: (text) => <a onClick={() => {copyText(text)}}>{text}</a>
                
            },
            {
                title: 'power',
                dataIndex: 'power',
                width: 200,
                key: 'power',
                render: (text) => <a onClick={() => {copyText(text)}}>{text}</a>
                
            },
            {
                title: 'Status',
                key: 'status',
                width: 200,
                dataIndex: 'status',
                render: (status) => {
                    let color = 'red'
                    let text = '已终止'
                    if(status === false){
                        color = 'green'
                        text = '未注册'
                    }
                    if(status === true){
                        color = 'red'
                        text = '已注册'
                    }
                    return (
                        <Tag color={color} key={text}>{text}</Tag>
                    );
                }
            },
            {
                title: 'Action',
                key: 'action',
                fixed: 'right',
                render: (_, record) => (
                    <Space size="middle">
                        {
                            (record.status=== true)?"":<Button
                                type="primary"
                                size='small'
                                disabled={false}
                                icon={<EditOutlined />}
                                onClick={() => {this.handleShowEdit(record)}}>
                                Register
                            </Button>

                            
                        }
                    </Space>
                ),
            },
        ];
        this.setState({columns:columns})
        // 查询电站列表
        let resp = await this.queryPlugList(this.state.plugSearch);
        if(!resp.error){
            let result = resp.result
            console.log('result : ',result)
            if(!result){
                return;
            }
            const data=[]
            if(result.count>0){
                let plugSearch = this.state.plugSearch
                this.setState({totalCount:result.count})
                for (const item of result.list) {
                    if((plugSearch.status == true || plugSearch.status == false) && plugSearch.status !== item.onboard){
                        continue;
                    }
                    if(plugSearch.ieee && plugSearch.ieee !== item.ieee){
                        continue;
                    }
                    const plug = {
                        key:item.ieee,
                        ieee:item.ieee,
                        mac:item.mac,
                        energy:item.energy,
                        power:item.power,
                        status:item.onboard
                    }
                    data.push(plug)
                }
            }else {
                this.setState({totalCount:0})
            }
            this.setState({plugList:data})
        }else {
            this.setState({plugList:undefined})
        }
    }
    onStatusChange(value){
        let plugSearch = this.state.plugSearch
        plugSearch.status = value
        this.setState({plugSearch:plugSearch})
    }
    onPlugIeeeChange(e){
        let plugSearch = this.state.plugSearch
        plugSearch.ieee = e.target.value
        this.setState({plugSearch:plugSearch})
        console.log(plugSearch)
    }
    onPaginationChange(page, pageSize){
        let plugSearch = this.state.plugSearch
        plugSearch.offset = page
        plugSearch.size = pageSize
        this.setState({plugSearch:plugSearch})
        this.SearchPlug()
    }
    onDeviceIdChange(e){
        let registerMiner = this.state.registerMiner
        console.log(registerMiner);
        registerMiner.deviceId = e.target.value
        this.setState({registerMiner:registerMiner})
    }

    onMinerExpiredTimeChange(e){
        let registerMiner = this.state.registerMiner
        registerMiner.expired_time = e.target.value
        this.setState({registerMiner:registerMiner})
    }


    SearchPlug(){
        this.check()
    }
    formatDateStr(value){
        if(value){
            return value.replace("T"," ").replace(".000Z","")
        }else {
            return ''
        }
    }
    handleEditOk(){
        const editMinerInfo = this.state.editMinerInfo;
        console.log("editMinerInfo:"+JSON.stringify(editMinerInfo))
        if(!editMinerInfo.address || editMinerInfo.address.length !== 42){
            message.error("Miner信息异常，请刷新页面后重试！")
            return;
        }
        if(!editMinerInfo.expired_time){
            message.error("过期天数不能为空！")
            return;
        }
        if(!editMinerInfo.price.amount){
            message.error("过期天数不能为空！")
            return;
        }
        if(Number(editMinerInfo.price.amount)<=0){
            message.error("请输入正确的过期天数！")
            return;
        }
        if(!editMinerInfo.price.currency){
            message.error("请选择用于支付的货币！")
            return;
        }
        //
        RemoteMinerApi.updateMiner(editMinerInfo).then(resp => {
            console.log(resp)
            if(!resp.error){
                message.success("Miner信息修改成功！")
                this.setState({showEdit:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
        })
    }

    handleEditCancel(){
        this.setState({showEdit:false})
    }
    onEditExpiredTimeChange(e){
        let editMinerInfo = this.state.editMinerInfo
        editMinerInfo.expired_time = e.target.value
        this.setState({editMinerInfo:editMinerInfo})
    }
    handleShowEdit(record){
        let registerMiner = this.state.registerMiner
        registerMiner.deviceId = record.ieee
        this.setState({registerMiner:registerMiner})
        this.setState({showRegister:true})
    }

    handleDelete(record){

        if(!record.minerDeviceId){
            message.error("Miner所属的deviceId 为空！")
            return;
        }
        this.setState({confirmLoading:true})
        SocketMinerApi.deleteMiner(record.minerDeviceId).then(resp => {
            if(!resp.error){
                message.success("Socket Miner删除成功！")
                this.check()
            }else {
                message.error(resp.error.message)
            }
            this.setState({confirmLoading:false})
        })
    }
    onCurrencyChange(value){
        let editMinerInfo = this.state.editMinerInfo;
        editMinerInfo.price.currency = value
        this.setState({editMinerInfo:editMinerInfo})
    }

    registerMiner(){
        this.setState({showRegister:true})
    }
    handleRegisterOk(){
        let registerMiner = this.state.registerMiner
        if(!registerMiner.deviceId){
            message.error("请输入Miner所属的deviceId！")
            return;
        }
        if(!registerMiner.expired_time){
            message.error("请输入Miner所属的expired_time！")
            return;
        }
        this.setState({confirmLoading:true})
        SocketMinerApi.registerMiners(registerMiner).then(resp => {
            if(!resp.error){
                message.success("Standard Miner注册成功！")
                this.setState({showRegister:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
            this.setState({confirmLoading:false})
        })
    }
    handleRegisterCancel(){
        this.setState({showRegister:false})
    }


    render() {
        return (
            <div style={{width:'100%',height:'100%',overflow:"hidden"}}>
                <Row>
                    <Col span={4}>
                        <span style={{fontWeight:'bold',fontSize:18}}>
                            Plug Manage
                        </span>
                    </Col>
                    <Col span={20}>
                        <div style={{textAlign:'right'}}>
                            <span>
                                ieee:<Input onChange={this.onPlugIeeeChange} size='small' style={{width:180}} placeholder="miner address" allowClear />
                            </span>
                            <span style={{marginLeft:10}}>Status:
                            <Select size='small' style={{width:150}} placeholder="Select a status" onChange={this.onStatusChange} allowClear options={this.state.statusList} />
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' onClick={this.SearchPlug} type="primary" icon={<SearchOutlined />}>Search</Button>
                            </span>
                            {/* <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' danger onClick={this.registerMiner} icon={<PlusCircleOutlined />}>Register Miner</Button>
                            </span> */}
                        </div>
                    </Col>
                </Row>
                {this.state.totalCount>10?<div style={{textAlign:'right',paddingTop:15}}><Pagination onChange={this.onPaginationChange} size="small" total={this.state.totalCount} showSizeChanger showQuickJumper /></div>:''}
                <div style={{marginTop:10,width:'100%',height:'100%',overflow:'auto'}}>
                    <Table scroll={{x: 1620}} size='small' pagination={false} columns={this.state.columns} dataSource={this.state.plugList} />
                </div>
                <Modal width='800px' title="Register Miner" open={this.state.showRegister} destroyOnClose={true} onOk={this.handleRegisterOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleRegisterCancel}>
                    <Row>
                        {
                            <Col span={24} style={{height:10}}></Col>
                        }
                        {
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Device Id:</span></Col>
                        }
                        {
                            <Col span={20}><TextArea rows={2} onChange={this.onDeviceIdChange} value={this.state.registerMiner.deviceId}/></Col>
                        }
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Expired Time:</span>
                        </Col>
                        <Col span={20}>
                            <Input readOnly={this.state.confirmLoading} type='number' placeholder="expired time of this miner" onChange={this.onMinerExpiredTimeChange} suffix="days"/>
                        </Col>
                    </Row>
                </Modal>
            </div>
        )
    }
}

export default SocketMinerPlugs;

import React from "react";
import {Space, Table, Tag, Row, Col, Button, Modal, Input, Select, message, Radio, Pagination} from 'antd';
import UserApi from "../../../api/userApi";
import {UserAddOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import RoleApi from "../../../api/roleApi";
import {getMetaMaskLoginUserAddress} from "../../../utils/metamaskUtils";


class User extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            columns:true,
            data:false,
            showAdd:false,
            showEdit:false,
            confirmLoading:false,
            roleList:[],
            userinfo:{
                address:undefined,
                roleId:undefined,
            }
        };
        this.check = this.check.bind(this)
        this.showAddModal = this.showAddModal.bind(this)
        this.handleAddCancel = this.handleAddCancel.bind(this)
        this.initRoleList = this.initRoleList.bind(this)
        this.handleAddOk = this.handleAddOk.bind(this)
        this.onRoleChange = this.onRoleChange.bind(this)
        this.handleEditOk = this.handleEditOk.bind(this)
        this.handleEditCancel = this.handleEditCancel.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.onStatusChange = this.onStatusChange.bind(this)
        this.onAddressChange = this.onAddressChange.bind(this)
    }
    async componentWillMount() {
        await this.check();
        this.initRoleList();
    }
    async queryUserList(){
        let userList = await UserApi.queryUserList();
        return userList;
    }
    async check() {
        const columns = [
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Role',
                dataIndex: 'role',
                key: 'role',
            },
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                render: (status) => {
                    let color = 'red'
                    let text = '已禁用'
                    if(status == 1){
                        color = 'green'
                        text = '正常'
                    }
                    return (
                        <Tag color={color} key={text}>{text}</Tag>
                    );
                }
            },
            {
                title: 'UpdateTime',
                dataIndex: 'updateTime',
                key: 'updateTime',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'CreateTime',
                dataIndex: 'createTime',
                key: 'createTime',
                render: (text) => <a>{text}</a>,
            },
            {
                title: 'Action',
                key: 'action',
                fixed: 'right',
                width:200,
                render: (_, record) => (
                    <Space size="middle">
                        <Button
                            type="primary"
                            size='small'
                            icon={<EditOutlined />}
                            onClick={() => {
                                this.handleEdit(record)
                            }}>
                            Edit
                        </Button>
                        <Button size='small' type="primary" icon={<DeleteOutlined />} onClick={()=>{
                                Modal.confirm({
                                    title: 'Are you sure delete user：',
                                    icon: <ExclamationCircleOutlined />,
                                    content: record.address,
                                    okText: 'Yes',
                                    cancelText: 'Cancel',
                                    onOk:()=>{this.handleDelete(record)}
                                });
                            }}>
                            Delete
                        </Button>
                    </Space>
                ),
            },
        ];
        this.setState({columns:columns})
        // 查询用户列表
        let resp = await this.queryUserList();
        if(!resp.error){
            let result = resp.result
            if(!result){
                return;
            }
            const data=[]
            if(result.list.length>0){
                this.setState({totalCount:result.count})
                result.list.forEach(item =>{
                    const user = {
                        key:item.id,
                        address:item.address,
                        role:item.roleName,
                        status:item.status,
                        delFlag:item.delFlag,
                        updateTime:item.updateTime.replace("T"," ").replace(".000Z",""),
                        createTime:item.createTime.replace("T"," ").replace(".000Z",""),
                    }
                    data.push(user)
                })
            }
            this.setState({data:data})
        }else {
            this.setState({data:undefined})
        }
    }
    handleAddOk(){
        const userinfo = this.state.userinfo;
        console.log("userinfo:"+JSON.stringify(userinfo))
        if(!userinfo.address || userinfo.address.length != 42){
            message.error("请输入正确的用户地址！")
            return;
        }
        if(!userinfo.roleId){
            message.error("请选择用户角色！")
            return;
        }
        //
        UserApi.addUser(userinfo).then(resp => {
            console.log(resp)
            if(!resp.error){
                message.success("用户添加成功！")
                this.setState({showAdd:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
        })
    }
    handleAddCancel(){
        this.setState({showAdd:false})
    }
    handleEditOk(){
        const userinfo = this.state.userinfo;
        console.log("userinfo:"+JSON.stringify(userinfo))
        if(!userinfo.address || userinfo.address.length != 42){
            message.error("请输入正确的用户地址！")
            return;
        }
        if(!userinfo.roleId){
            message.error("请选择用户角色！")
            return;
        }
        //
        UserApi.updateUser(userinfo).then(resp => {
            console.log(resp)
            if(!resp.error){
                message.success("用户修改成功！")
                this.setState({showEdit:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
        })
    }
    handleEditCancel(){
        this.setState({showEdit:false})
    }
    showAddModal(){
        this.setState({showAdd:true})
    }
    handleEdit(user){
        console.log(user)
        let roleId = undefined
        this.state.roleList.forEach(item => {
            if(user.role == item.label){
                roleId = item.value
            }
        })
        const userinfo = {
            address:user.address,
            roleId:roleId,
            status:user.status
        }
        this.setState({userinfo:userinfo})
        this.setState({showEdit:true})
    }
    async handleDelete(record) {
        let address = await getMetaMaskLoginUserAddress();
        if (address.toLowerCase() == record.address.toLowerCase()) {
            message.error("不能删除自己")
            return;
        }
        UserApi.delUser({address: record.address}).then(resp => {
            if (!resp.error) {
                message.success("用户删除成功！")
                this.check();
            } else {
                message.error(resp.error.message)
            }
        })
    }
    initRoleList(){
        RoleApi.queryRoleList().then(resp => {
            console.log("resp:"+JSON.stringify(resp))
            if(!resp.error){
                const result = resp.result;
                let roleList = [];
                if(result.length >0){
                    result.forEach(item => {
                        roleList.push({value: item.id, label: item.name})
                    })
                }else {
                    roleList.push({value: result.id, label: result.name})
                }
                this.setState({roleList:roleList})
            }
        })
    }
    onRoleChange(value){
        let userinfo = this.state.userinfo
        userinfo.roleId = value
        this.setState({userinfo:userinfo})
    }
    onAddressChange(e){
        const address = e.target.value
        let userinfo = this.state.userinfo
        userinfo.address = address
        this.setState({userinfo:userinfo})
    }
    onStatusChange(e){
        console.log()
        let userinfo = this.state.userinfo
        userinfo.status = e.target.value
        this.setState({userinfo:userinfo})
    }
    render() {
        //const { getFieldDecorator} = this.props.form
        return (
            <div style={{width:'100%',height:'100%',overflow:"hidden"}}>
                <div>
                    <Row>
                        <Col span={12}>
                            <span style={{fontWeight:'bold',fontSize:18}}>
                                User Manage
                            </span>
                        </Col>
                        <Col span={12}>
                            <div style={{textAlign:'right'}}>
                                <Button size='small' onClick={this.showAddModal} type="primary" icon={<UserAddOutlined />}>Add</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
                {
                    this.state.totalCount>10?<div style={{textAlign:'right',paddingTop:15}}>
                        <Pagination onChange={this.onPaginationChange} size="small" total={this.state.totalCount} showSizeChanger showQuickJumper />
                    </div>:''
                }
                <div style={{marginTop:10,width:'100%',height:'100%',overflow:'auto'}}>
                    <Table pagination={false} size='small' columns={this.state.columns} dataSource={this.state.data} />
                </div>
                <Modal
                    title="Add User"
                    open={this.state.showAdd}
                    onOk={this.handleAddOk}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleAddCancel}>
                    <div>
                        <Row>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Address:</span>
                            </Col>
                            <Col span={20}>
                                <Input onChange={this.onAddressChange} />
                            </Col>
                            <Col span={24} style={{height:10}}>

                            </Col>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Role:</span>
                            </Col>
                            <Col span={20}>
                                <Select style={{width:'100%'}} placeholder="Select a role" onChange={this.onRoleChange} allowClear options={this.state.roleList} />
                            </Col>
                        </Row>
                    </div>
                </Modal>
                <Modal
                    title="Edit User"
                    open={this.state.showEdit}
                    onOk={this.handleEditOk}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleEditCancel}>
                    <div>
                        <Row>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Address:</span>
                            </Col>
                            <Col span={20}>
                                <Input readOnly={true} onChange={this.onAddressChange} value={this.state.userinfo.address}/>
                            </Col>
                            <Col span={24} style={{height:10}}>

                            </Col>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Role:</span>
                            </Col>
                            <Col span={20}>
                                <Select value={this.state.userinfo.roleId} style={{width:'100%'}} placeholder="Select a role" onChange={this.onRoleChange} allowClear options={this.state.roleList} />
                            </Col>
                            <Col span={24} style={{height:10}}>

                            </Col>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Status:</span>
                            </Col>
                            <Col span={20}>
                                <Radio.Group style={{marginTop:5}} onChange={this.onStatusChange} value={this.state.userinfo.status}>
                                    <Radio value={1}>正常</Radio>
                                    <Radio value={2}>禁用</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default User;

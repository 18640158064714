import React from 'react';
import { Layout, Menu,Row,Col,Dropdown,Space,Button,Modal } from 'antd';
import { DownOutlined,ExportOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import logo from '../../assets/svg/maker-arkreen.svg';
import './Index.css';
import {clearFilters, getPath} from '../../utils/browserUtils';
import router from "../../router/menu/router";
import {MenuProps} from "antd";
import UserApi from "../../api/userApi";
import { createBrowserHistory } from 'history';
import {getMetaMaskLoginUserAddress,removeMetaMaskUserAddress} from "../../utils/metamaskUtils";
const {Header, Content, Sider} = Layout;
const history = createBrowserHistory();

let UNLISTEN;

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contentComponent:router[0].component,
            userAddress:undefined,
            userAddress_Fex:undefined,
            menuList:undefined,
            defaultSelectedKeys:[],
            defaultOpenKeys:[],
        };
        this.openMenuItem = this.openMenuItem.bind(this)
        this.clickMenuItem = this.clickMenuItem.bind(this)
        this.pushRouter = this.pushRouter.bind(this)
    }
    logout(){
        Modal.confirm({
            title: 'Logout',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure want to logout?',
            okText: 'Yes',
            cancelText: 'Cancel',
            onOk:async function () {
                const address = await getMetaMaskLoginUserAddress()
                await UserApi.userLogout({address: address})
                removeMetaMaskUserAddress();
                clearFilters("token");
                window.location.reload();
            }
        });
    }
    async getUserAddress() {
        const address = await getMetaMaskLoginUserAddress()
        this.setState({userAddress:address})
        let addressFex = address.substring(0,5)+"..."+address.substring(address.length-4)
        this.setState({userAddress_Fex:addressFex})
    }
    getMenuInfo() {
        UserApi.getUserInfo().then(rsp => {
            let menuList = [];
            const path = getPath();
            if(!rsp.error){
                rsp.result.menuList.forEach(item => {
                    if(item.parentId==null){
                        let menu = {
                            key: item.type==1?item.name:item.url,
                            sort: item.sort,
                            icon: item.icon,
                            label: item.name,
                            url: item.url,
                            children: []
                        }
                        rsp.result.menuList.forEach(row => {
                            if(row.parentId === item.id){
                                let m = {
                                    key: row.type==1?row.name:row.url,
                                    icon: row.icon,
                                    sort: row.sort,
                                    label: row.name,
                                    url: row.url
                                }
                                if(row.url === path){
                                    this.setState({defaultSelectedKeys: [row.url]})
                                    this.setState({defaultOpenKeys: [item.name]})
                                }
                                menu.children.push(m)
                            }
                        })
                        if(menu.children.length===0){
                            menu.children = undefined
                        }
                        if(path === "/" || item.url === path){
                            this.setState({defaultSelectedKeys: path === "/"?['Dashboard']:[item.name]})
                        }
                        menuList.push(menu)
                    }
                })
            }
            // 菜单排序
            menuList = this.sortMenu(menuList)
            this.setState({menuList:menuList})
            let flag = false;
            menuList.forEach(item =>{
                if(item.children){
                    item.children.forEach(children => {
                        if(children.url == path || path=='/'){
                            flag = true;
                        }
                    })
                }
            })
            if(!flag){
                history.push("/")
            }else {
                this.pushRouter(getPath());
            }
        })
    }
    sortMenu(menuList){
        for (let i=0; i<menuList.length-1; i++) {
            for (let j=0; j<menuList.length-i-1; j++) {
                if (menuList[j].sort > menuList[j+1].sort) {
                    const temp = menuList[j];
                    menuList[j] = menuList[j+1];
                    menuList[j+1] = temp;
                }
            }
        }
        menuList.forEach(item => {
            if(item.children){
                item.children = this.sortMenu(item.children)
            }
        })
        return menuList;
    }
    openMenuItem(openKeys){
        if(this.state.defaultOpenKeys){
            let open = this.state.defaultOpenKeys
            openKeys.forEach(key => {
                if(open !== key){
                    open = key
                }
            })
            this.setState({defaultOpenKeys: [open]})
        }
    }
    clickMenuItem(item){
        history.push(item.key)
    }
    pushRouter(path){
        router.forEach(rout => {
            if(rout.path === path){
                this.setState({defaultSelectedKeys: [path]})
                this.setState({contentComponent:rout.component})
            }
        })
    }
    async componentWillMount() {
        this.getMenuInfo();
        await this.getUserAddress();
    }
    componentWillUnmount(){
        UNLISTEN && UNLISTEN();
    }
    componentDidMount(){
        UNLISTEN = history.listen(route => {
            this.pushRouter(route.location.pathname)
        });
    }
    render(){
        const items: MenuProps['items'] = [
            {
                key:1,
                label:(<Button type="link" onClick={this.logout}>Logout</Button>),
                icon: <ExportOutlined />,
                disabled: false
            }
        ]
        return (
            <Layout style={{height:'100vh'}}>
                <Header className="header">
                    <Row>
                        <Col span={12}>
                            <div className="logo">
                                <img src={logo} className="logo-svg" alt="logo" />
                                <span className="logo-text"> Arkreen Admin </span>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="user-body">
                                <Dropdown menu={{ items }}>
                                    <a>
                                        <Space>
                                            {this.state.userAddress_Fex}
                                            <DownOutlined />
                                        </Space>
                                    </a>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                </Header>
                <Layout >
                    <Sider width={200} style={{background: '#001529'}}>
                        <Menu
                            theme='dark'
                            mode="inline"
                            selectedKeys={this.state.defaultSelectedKeys}
                            openKeys={this.state.defaultOpenKeys}
                            style={{height: '100%', borderRight: 0}}
                            items={this.state.menuList}
                            onOpenChange={this.openMenuItem}
                            onClick={this.clickMenuItem}
                        />
                    </Sider>
                    <Layout >
                        <Content style={{padding: 24, margin: 0, minHeight: 280, background: '#FFFFFF'}}>{this.state.contentComponent}</Content>
                    </Layout>
                </Layout>
            </Layout>
        )
    }
}

export default Index;

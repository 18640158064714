import React from "react";

class Miners extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            isLoad:true,
            token:false,
        };
        this.check = this.check.bind(this)
    }
    async componentWillMount() {
        await this.check();
    }
    async check() {
        console.log("miners check" )
    }
    render() {
        return (
            <div>
                Miners
            </div>
        )
    }
}

export default Miners;

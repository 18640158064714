import React from "react";
import { Space, Table, Tag,Row,Col,Button,Modal,Input,Select,message,Pagination } from 'antd';
import {SearchOutlined, EditOutlined, StopOutlined, ExclamationCircleOutlined,PlusOutlined} from '@ant-design/icons';
import RemoteMinerApi from "../../../api/remoteMinerApi";
import {copyText, formatDate} from '../../../utils/browserUtils';
import {symbol} from '../../../utils/contractUtils';

const itemStyle = {backgroundColor:'#66B3FF',borderRadius:'8px',display:'flex',flexDirection:"column",gap:'4px',padding:'8px 16px',textAlign:"center",minWidth:'240px'}

class RemoteMiner extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            columns:true,
            totalCount:0,
            showEdit:false,
            plantList:undefined,
            editMinerInfo: {
                price:{}
            },
            statusList:[
                {value: "created", label: "Created"},
                {value: "orderable", label: "Orderable"},
                {value: "registered", label: "Registered"},
                {value: "onboarded", label: "Onboarded"},
                {value: "terminated", label: "Terminated"}
            ],
            minerSearch:{

            },
            currencyList:[
                {value: "0x6c28fF02d3A132FE52D022db1f25a33d91caeCA2", label: "gAKRE - 0x6c28fF02d3A132FE52D022db1f25a33d91caeCA2"},
                {value: "0x960c67b8526e6328b30ed2c2faea0355beb62a83", label: "gAKRE(生产) - 0x960c67b8526e6328b30ed2c2faea0355beb62a83"},
                {value: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174", label: "USDC - 0x2791bca1f2de4661ed88a30c99a7a9449aa84174"},
                {value: "0x9C5653339E0B3A99262997FbB541E2562f3361C9", label: "USDC(测试) - 0x9C5653339E0B3A99262997FbB541E2562f3361C9"},
                {value: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270", label: "WMATIC - 0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270"},
            ],
            ownerTotal:undefined,
            minerTotal:undefined,
            remoteTotal:undefined,
            liteTotal:undefined,
            ownerSearch:undefined,

        };
        this.check = this.check.bind(this)
        this.onStatusChange = this.onStatusChange.bind(this)
        this.onMinerAddressChange = this.onMinerAddressChange.bind(this)
        this.onPlantAddressChange = this.onPlantAddressChange.bind(this)
        this.onPaginationChange = this.onPaginationChange.bind(this)
        this.SearchMiner = this.SearchMiner.bind(this)
        this.handleEditOk = this.handleEditOk.bind(this)
        this.handleEditCancel = this.handleEditCancel.bind(this)
        this.onEditExpiredTimeChange = this.onEditExpiredTimeChange.bind(this)
        this.handleShowEdit = this.handleShowEdit.bind(this)
        this.onPriceChange = this.onPriceChange.bind(this)
        this.onCurrencyChange = this.onCurrencyChange.bind(this)
        this.onOwnerAddressChange = this.onOwnerAddressChange.bind(this)
    }
    async componentWillMount() {
        await this.check();
    }
    async queryMinerList(minerSearch){
        let minerList = await RemoteMinerApi.queryMinerList(minerSearch)
        return minerList;
    }
    async queryMinersTotal(ownerSearch){
        const params = {
            owner:ownerSearch
        }
        let res = await RemoteMinerApi.queryTotalMinersOnboarded(params)
        return res;
    }
    async check() {
        const columns = [
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                width: 200,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Plant',
                dataIndex: 'plant_id',
                key: 'plant_id',
                width: 200,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Status',
                key: 'status',
                width: 120,
                dataIndex: 'status',
                render: (status) => {
                    let color = 'red'
                    let text = 'Terminated'
                    if(status === 'created'){
                        color = '#6C6C6C'
                        text = 'Created'
                    }
                    if(status === 'registered'){
                        color = '#F75000'
                        text = 'Registered'
                    }
                    if(status === 'orderable'){
                        color = '#66B3FF'
                        text = 'Orderable'
                    }
                    if(status === 'onboarded'){
                        color = 'green'
                        text = 'Onboarded'
                    }
                    if(status === 'terminated'){
                        color = 'red'
                        text = 'Terminated'
                    }
                    return (
                        <Tag color={color} key={text}>{text}</Tag>
                    );
                }
            },
            {
                title: 'Price',
                dataIndex: 'price',
                width: 150,
                key: 'price'
            },
            {
                title: 'Power',
                dataIndex: 'power',
                key: 'power',
                width: 120,
                render: (text) => <span>{Number(text)+" W"}</span>,
            },
            {
                title: 'Expired Time',
                dataIndex: 'expired_time',
                key: 'expired_time',
                width: 200,
                render: (text) => {return <span>{(Number(text) / 86400)+" day(s)"}</span>},
            },
            {
                title: 'Onboard Time',
                dataIndex: 'onboard_time',
                key: 'onboard_time',
                width: 200,
                render: (text) => {return <span>{text?formatDate(text):''}</span>},
            },
            {
                title: 'Create time',
                dataIndex: 'create_time',
                key: 'create_time',
                width: 200,
                render: (text) => {return <span>{formatDate(text)}</span>},
            },
            {
                title: 'Action',
                key: 'action',
                fixed: 'right',
                render: (_, record) => (
                    <Space size="middle">
                        {
                            (record.status==='onboarded'||record.status==='terminated')?"":<Button
                                type="primary"
                                size='small'
                                icon={<EditOutlined />}
                                onClick={() => {this.handleShowEdit(record)}}>
                                Edit
                            </Button>
                        }
                    </Space>
                ),
            },
        ];
        this.setState({columns:columns})
        // 查询电站列表
        let resp = await this.queryMinerList(this.state.minerSearch);
        if(!resp.error){
            let result = resp.result
            if(!result){
                return;
            }
            const data=[]
            if(result.count>0){
                this.setState({totalCount:result.count})
                for (const item of result.list) {
                    const price = JSON.parse(item.price)
                    const name = await symbol(price.currency);
                    const plant = {
                        key:item.address,
                        plant_id:item.plant_id,
                        address:item.address,
                        power:item.power,
                        energy:item.energy,
                        owner:item.owner,
                        price:price.amount+" "+name,
                        priceDetail:price,
                        create_time:item.create_time,
                        onboard_time:item.onboard_time,
                        expired_time:item.expired_time,
                        status:item.status
                    }
                    data.push(plant)
                }
            }else {
                this.setState({totalCount:0})
            }
            this.setState({plantList:data})
        }else {
            this.setState({plantList:undefined})
        }

        // 查询miner信息
        let minerInfo = await this.queryMinersTotal(this.state.ownerSearch);
        if(!minerInfo.error){
            let result = minerInfo.result
            if(!result){
                this.setState({ownerTotal:undefined})
                this.setState({minerTotal:undefined})
                this.setState({remoteTotal:undefined})
                this.setState({liteTotal:undefined})
                return;
            }
            this.setState({ownerTotal:result.ownerTotal})
            this.setState({minerTotal:result.minerTotal})
            this.setState({remoteTotal:result.minerCount100})
            this.setState({liteTotal:result.minerCount5})
        }else {
            this.setState({ownerTotal:undefined})
            this.setState({minerTotal:undefined})
            this.setState({remoteTotal:undefined})
            this.setState({liteTotal:undefined})
        }
    }
    onStatusChange(value){
        let minerSearch = this.state.minerSearch
        minerSearch.status = value
        this.setState({minerSearch:minerSearch})
    }
    onMinerAddressChange(e){
        let minerSearch = this.state.minerSearch
        minerSearch.address = e.target.value
        this.setState({minerSearch:minerSearch})
    }
    onPlantAddressChange(e){
        let minerSearch = this.state.minerSearch
        minerSearch.plant_id = e.target.value
        this.setState({minerSearch:minerSearch})
    }
    onPaginationChange(page, pageSize){
        let minerSearch = this.state.minerSearch
        minerSearch.offset = page
        minerSearch.size = pageSize
        this.setState({minerSearch:minerSearch})
        this.SearchMiner()
    }
    SearchMiner(){
        this.check()
    }

    handleEditOk(){
        const editMinerInfo = this.state.editMinerInfo;
        console.log("editMinerInfo:"+JSON.stringify(editMinerInfo))
        if(!editMinerInfo.address || editMinerInfo.address.length !== 42){
            message.error("Miner信息异常，请刷新页面后重试！")
            return;
        }
        if(!editMinerInfo.expired_time){
            message.error("过期天数不能为空！")
            return;
        }
        if(!editMinerInfo.price.amount){
            message.error("过期天数不能为空！")
            return;
        }
        if(Number(editMinerInfo.price.amount)<=0){
            message.error("请输入正确的过期天数！")
            return;
        }
        if(!editMinerInfo.price.currency){
            message.error("请选择用于支付的货币！")
            return;
        }
        //
        RemoteMinerApi.updateMiner(editMinerInfo).then(resp => {
            console.log(resp)
            if(!resp.error){
                message.success("Miner信息修改成功！")
                this.setState({showEdit:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
        })
    }
    handleEditCancel(){
        this.setState({showEdit:false})
    }
    onEditExpiredTimeChange(e){
        let editMinerInfo = this.state.editMinerInfo
        editMinerInfo.expired_time = e.target.value
        this.setState({editMinerInfo:editMinerInfo})
    }
    handleShowEdit(record){
        let editMinerInfo = this.state.editMinerInfo;
        editMinerInfo.address = record.address
        editMinerInfo.expired_time = Math.round(record.expired_time/86400)
        editMinerInfo.price = record.priceDetail
        this.setState({editMinerInfo:editMinerInfo})
        console.log("editMinerInfo:"+JSON.stringify(editMinerInfo))
        this.setState({showEdit:true})
    }
    onPriceChange(e){
        let editMinerInfo = this.state.editMinerInfo;
        editMinerInfo.price.amount = e.target.value
        this.setState({editMinerInfo:editMinerInfo})
    }
    onCurrencyChange(value){
        let editMinerInfo = this.state.editMinerInfo;
        editMinerInfo.price.currency = value
        this.setState({editMinerInfo:editMinerInfo})
    }

    onOwnerAddressChange(e){
        let ownerSearch = this.state.ownerSearch
        ownerSearch = e.target.value
        this.setState({ownerSearch:ownerSearch})
    }


    render() {
        return (
            <div style={{width:'100%',height:'100%',overflow:"hidden"}}>
                <Row>
                <Col span={4}>
                            <span style={{fontWeight:'bold',fontSize:18}}>
                                Miner Manage
                            </span>
                    </Col>
                </Row>
                <Row>
                <Col span={16}>
                    <div style={{display: 'flex', gap: '20px', paddingBottom: '20px', color: '#101020'}}>
                        <div style={itemStyle}>
                            <div style={{fontSize: '24px', fontWeight: "bold"}}>{this.state.ownerTotal != undefined ? this.state.ownerTotal :
                                '?'}</div>
                            <div>Owner Total</div>
                        </div>
                        <div style={itemStyle}>
                            <div style={{fontSize: '24px', fontWeight: "bold"}}>{this.state.minerTotal!=undefined?this.state.minerTotal:'?'}</div>
                            <div>Miner Total</div>
                        </div>
                        <div style={itemStyle}>
                            <div style={{fontSize: '24px', fontWeight: "bold"}}>{this.state.minerTotal!=undefined?this.state.remoteTotal:'?'}</div>
                            <div>Remote Total</div>
                        </div>
                        <div style={itemStyle}>
                            <div style={{fontSize: '24px', fontWeight: "bold"}}>{this.state.minerTotal!=undefined?this.state.liteTotal:'?'}</div>
                            <div>Lite Total</div>
                        </div>
                    </div>
                </Col>
                    <Col span={8}>
                        <div style={{textAlign:'right'}}>
                            <span>
                                Owner:<Input onChange={this.onOwnerAddressChange} size='small' style={{width:180}} placeholder="miner address" allowClear />
                            </span>
                            <Button style={{marginLeft:10}} size='small' onClick={this.SearchMiner} type="primary" icon={<SearchOutlined />}>Query</Button>
                        </div>
                    </Col>
                
                </Row>
                
                <Row>

                    <Col span={24}>
                        <div style={{textAlign:'right'}}>
                            <span>
                                Address:<Input onChange={this.onMinerAddressChange} size='small' style={{width:180}} placeholder="miner address" allowClear />
                            </span>
                            <span style={{marginLeft:10}}>
                                Plant Address:<Input onChange={this.onPlantAddressChange} size='small' style={{width:180}} placeholder="plant address " allowClear />
                            </span>
                            <span style={{marginLeft:10}}>Status:
                            <Select size='small' style={{width:150}} placeholder="Select a status" onChange={this.onStatusChange} allowClear options={this.state.statusList} />
                            </span>
                            <Button style={{marginLeft:10}} size='small' onClick={this.SearchMiner} type="primary" icon={<SearchOutlined />}>Search</Button>
                        </div>
                    </Col>
                </Row>
                
                {this.state.totalCount>10?<div style={{textAlign:'right',paddingTop:15}}>
                        <Pagination onChange={this.onPaginationChange} size="small" total={this.state.totalCount} showSizeChanger showQuickJumper />
                    </div>:''}
                <div style={{marginTop:10,width:'100%',height:'100%',overflow:'auto'}}>
                    <Table scroll={{x: 1480}} size='small' pagination={false} columns={this.state.columns} dataSource={this.state.plantList} />
                </div>
                <Modal width='800px' title="Edit Miner Info" open={this.state.showEdit} onOk={this.handleEditOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleEditCancel}>
                    <Row>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>目标Miner:</span>
                        </Col>
                        <Col span={20}>
                            <span style={{lineHeight:'32px'}}>{this.state.editMinerInfo.address}</span>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Price:</span>
                        </Col>
                        <Col span={20}>
                            <Input value={this.state.editMinerInfo.price.amount} readOnly={this.state.confirmLoading} type='number' placeholder="price" onChange={this.onPriceChange} />
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Currency:</span>
                        </Col>
                        <Col span={20}>
                            <Select value={this.state.editMinerInfo.price.currency} style={{width:'100%'}} placeholder="Select a currency" onChange={this.onCurrencyChange} allowClear options={this.state.currencyList} />
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>剩余天数:</span>
                        </Col>
                        <Col span={20}>
                            <Input value={this.state.editMinerInfo.expired_time} type='number' placeholder="expired days of this miner" onChange={this.onEditExpiredTimeChange} suffix="days"/>
                        </Col>
                    </Row>
                </Modal>
            </div>
        )
    }
}

export default RemoteMiner;

import axios from "axios";
import {removeMetaMaskUserAddress} from "./metamaskUtils";
import {clearFilters} from "./browserUtils";
import { message } from 'antd';

const config = {
    baseURL: "",
    timeout: 600000,
    headers: {'Content-Type': 'application/json',}
};

const jsonrpcRequest = axios.create(config);

jsonrpcRequest.interceptors.response.use(
    async response => {
        if (response.data.error) {
            if (response.data.error.code == 90000) {
                await message.error("登录状态已过期，请重新授权登录！")
                removeMetaMaskUserAddress();
                clearFilters("token");
                window.location.reload();
            }
        }
        return response.data
    },
    error => {
        let { message } = error;
        if (message === "Network Error") {
            //message = "后端接口连接异常";
            message = "Network Error,can't connect to Service";
        }
        else if (message.includes("timeout")) {
            //message = "系统接口请求超时";
            message = "System interface request timeout";
        }
        else if (message.includes("Request failed with status code")) {
            //message = "系统接口" + message.substr(message.length - 3) + "异常";
            message = "System interface " + message.substr(message.length - 3) + " error";
        }
        console.error(message);
        return Promise.reject(error)
    })

/**
 * 发送http请求
 * @param url
 * @param method
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
async function sendRequest(url,method,params,token) {
    let data = {
        jsonrpc: "2.0",
        id: Number(Math.random().toString().substring(2)),
        method: method,
        params: params
    }
    console.info(method + " requset data:", JSON.stringify(data))
    let headers = {
        'Content-Type': 'application/json'
    };
    if(token){
        token = token.substring(1)
        token = token.substring(0,token.length-1)
        headers = {
            'Content-Type': 'application/json',
            'Authorization':"bearer "+token
        }
    }
    return await jsonrpcRequest({url: url, method: 'post', data: data,headers:headers})
}

async function post(url,params){
    return await jsonrpcRequest({url: url, method: 'post', data: params})
}

async function get(url){
    return await jsonrpcRequest({url: url, method: 'get'})
}

const HttpUtils ={
    sendRequest:sendRequest,
    post:post,
    get:get,
}

export default HttpUtils;

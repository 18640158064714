import Buffer from "vue-buffer";

export async function getMetaMaskLoginUserAddress() {
    let address = await getAccountAddressWithMetaMask()
    let userAddress = window.localStorage.getItem('MetaMaskAddress');
    if(address === userAddress){
        return userAddress
    }else {
        if(userAddress){
            removeMetaMaskUserAddress();
        }
        return null;
    }
}

export function saveMetaMaskUserAddress(userAddress) {
    console.log("saveMetaMaskUserAddress:"+userAddress)
    window.localStorage.setItem('MetaMaskAddress', userAddress);
}

export function removeMetaMaskUserAddress() {
    console.log("清除账户缓存")
    window.localStorage.removeItem('MetaMaskAddress')
}

export async function getAccountAddressWithMetaMask() {
    // eslint-disable-next-line no-undef
    const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
    });
    return newAccounts[0];
}

export async function loginWithMetaMask() {
    // eslint-disable-next-line no-undef
    const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
    });
    saveMetaMaskUserAddress(newAccounts[0]);
    return newAccounts[0];
}

export async function personalSign(message) {
    try {
        console.log("personalSign message:"+message)
        const msg = `0x${Buffer.from(message, 'utf8').toString('hex')}`;
        console.log("msg:"+msg)
        let address = await getMetaMaskLoginUserAddress();
        console.log("personalSign address:"+address)
        // eslint-disable-next-line no-undef
        const sign = await ethereum.request({
            method: 'personal_sign',
            params: [msg,address, ''],
        });
        //console.log("personalSign result:" + sign);
        return sign;
    }catch (e){
        console.log(e)
        return undefined;
    }
}

export async function ethSign(message){
    try {
        let address = await getMetaMaskLoginUserAddress()
        // eslint-disable-next-line no-undef
        const ethResult = await ethereum.request({
            method: 'eth_sign',
            params: [address, message],
        });
        return ethResult;
    } catch (err) {
        console.error(err);
        return null;
    }
}

export async function personalEcRecover(message,sign) {
    // eslint-disable-next-line no-undef
    const ecRecoverAddress = await ethereum.request({
        method: 'personal_ecRecover',
        params: [message, sign],
    });
    return ecRecoverAddress;
}

export async function getNetworkAndChainId() {
    try {
        // eslint-disable-next-line no-undef
        const chainId = await ethereum.request({
            method: 'eth_chainId',
        });
        // eslint-disable-next-line no-undef
        const networkId = await ethereum.request({
            method: 'net_version',
        });
        const chainInfo = {
            "chainId":chainId,
            "networkId":networkId
        }
        return chainInfo;
    } catch (err) {
        console.error(err);
        return undefined;
    }
}

export async function signTypedDataV3(signTypedData){
    try {
        let address = await getMetaMaskLoginUserAddress()
        // eslint-disable-next-line no-undef
        const sign = await ethereum.request({
            method: 'eth_signTypedData_v3',
            params: [address, signTypedData],
        });
        return sign.toString();
    } catch (err) {
        console.error(err);
        return null;
    }
}

export async function signTypedDataV4(signTypedData){
    try {
        let address = await getMetaMaskLoginUserAddress()
        // eslint-disable-next-line no-undef
        const sign = await ethereum.request({
            method: 'eth_signTypedData_v4',
            params: [address, signTypedData],
        });
        return sign.toString();
    } catch (err) {
        console.error(err);
        return null;
    }
}

export async function addNetwork(chainCfg){
    console.log("addNetwork chainCfg:"+JSON.stringify(chainCfg))
    // eslint-disable-next-line no-undef
    const result = await ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [chainCfg]
    })
    console.info("addNetwork result:"+JSON.stringify(result))
}

export async function switchNetwork(){
    try {
        let cid = process.env.VUE_APP_NET_ID;
        //判断当前网络节点
        const chainInfo = await getNetworkAndChainId();
        if(chainInfo.chainId === cid){
            return true;
        }
        console.info("当前网络["+chainInfo.chainId+"]不一致：需要切换！");
        // eslint-disable-next-line no-undef
        await ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: cid }],
        });
        return true;
    }catch (e){
        const err = e;
        console.log(err);
        if (err.code === 4902) {
            try {
                // 添加
                let chainNo = (process.env.VUE_APP_NET_ID === "0x89"?137:80001);
                await addNetwork(ChainCfg[chainNo])
                return true;
            } catch (addError) {
                console.error(addError);
                return false
            }
        } else {
            console.error("switch metamask network failed:"+err.message)
            return false
        }
    }
}


/**
 * 网络信息
 * @type {{"1": {chainName: string, blockExplorerUrls: string[], chainId: string, nativeCurrency: {symbol: string, decimals: number, name: string}, rpcUrls: string[]}, "56": {chainName: string, blockExplorerUrls: string[], chainId: string, nativeCurrency: {symbol: string, decimals: number, name: string}, rpcUrls: string[]}, "3": {chainName: string, blockExplorerUrls: string[], chainId: string, nativeCurrency: {symbol: string, decimals: number, name: string}, rpcUrls: string[]}, "137": {chainName: string, blockExplorerUrls: string[], chainId: string, nativeCurrency: {symbol: string, decimals: number, name: string}, rpcUrls: string[]}, "1088": {chainName: string, blockExplorerUrls: string[], chainId: string, nativeCurrency: {symbol: string, decimals: number, name: string}, rpcUrls: string[]}, "2088": {chainName: string, blockExplorerUrls: string[], chainId: string, nativeCurrency: {symbol: string, decimals: number, name: string}, rpcUrls: string[]}, "42": {chainName: string, blockExplorerUrls: string[], chainId: string, nativeCurrency: {symbol: string, decimals: number, name: string}, rpcUrls: string[]}, "97": {chainName: string, blockExplorerUrls: string[], chainId: string, nativeCurrency: {symbol: string, decimals: number, name: string}, rpcUrls: string[]}}}
 */
const ChainCfg = {
    1: {
        chainId: '0x1',
        chainName: 'Ethereum Mainnet',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
        blockExplorerUrls: ['https://etherscan.io'],
    },
    3: {
        chainId: '0x3',
        chainName: 'Ropsten testNet',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
        blockExplorerUrls: ['https://ropsten.etherscan.io'],
    },
    42: {
        chainId: '0x2a',
        chainName: 'Kovan testNet',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
        blockExplorerUrls: ['https://kovan.etherscan.io'],
    },
    56: {
        chainId: '0x38',
        chainName: 'Binance Smart Chain',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
        },
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com/'],
    },
    97: {
        chainId: '0x61',
        chainName: 'Binance Smart Chain - TestNet',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
        },
        rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
        blockExplorerUrls: ['https://testnet.bscscan.com/'],
    },
    1088: {
        chainId: '0x440',
        chainName: 'Maas - TestNet',
        nativeCurrency: {
            name: 'Maas',
            symbol: 'Maas',
            decimals: 18,
        },
        rpcUrls: ['https://maas-test-node.onchain.com/'],
        blockExplorerUrls: ['https://maas-test-explorer.onchain.com/'],
    },
    2088: {
        chainId: '0x828',
        chainName: 'Maas',
        nativeCurrency: {
            name: 'Maas',
            symbol: 'Maas',
            decimals: 18,
        },
        rpcUrls: ['https://maas-node.onchain.com/'],
        blockExplorerUrls: ['https://maas-explorer.onchain.com/'],
    },
    137: {
        chainId: '0x89',
        chainName: 'Polygon Mainnet',
        nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18,
        },
        rpcUrls: ['https://polygon-rpc.com/'],
        blockExplorerUrls: ['https://polygonscan.com/'],
    },
    80001: {
        chainId: '0x13881',
            chainName: 'Matic Mumbai',
            nativeCurrency: {
            name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18,
        },
        rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
        blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
    }
};

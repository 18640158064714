import Buffer from "vue-buffer";
function reverseHexStringBytes(hexString) {
    const bytes = new Uint8Array(Buffer.from(hexString, 'hex'));
    bytes.reverse();
    return Buffer.from(bytes).toString('hex');
  }

  const CommonUtils ={
    reverseHexStringBytes:reverseHexStringBytes,
}

export default CommonUtils;
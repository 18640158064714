import { message } from 'antd';
let base_filters_key = "arkreen_opweb_filters_"

export function setKey(key,value){
    window.localStorage.setItem(key, value);
}

export function getKey(key){
    return window.localStorage.getItem(key)
}

export function delKey(key){
    return window.localStorage.removeItem(key)
}

export function setFilters(key,data){
    if(key.startsWith("/")){
        key = key.substring(1)
    }
    window.sessionStorage.setItem(base_filters_key+key,JSON.stringify(data));
}

export function getFilters(key){
    if(key.startsWith("/")){
        key = key.substring(1)
    }
    return window.sessionStorage.getItem(base_filters_key+key);
}

export function clearFilters(key){
    if(key.startsWith("/")){
        key = key.substring(1)
    }
    return window.sessionStorage.removeItem(base_filters_key+key)
}

export function copyText(text){
    const input = document.createElement('input')
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand("Copy");
    document.body.removeChild(input);
    message.success("Copied!")
}

export function getPath(){
    const host = window.location.host
    let href = window.location.href
    href = href.replace("http://","")
    href = href.replace("https://","")
    href = href.replace(host,"")
    return href;
}

export function formatDate(value, format) {
    //value: 需要格式化的数据
    //format: 指定格式 yyyy-MM-dd hh:mm:ss
    if(!format){
        format = "yyyy-MM-dd hh:mm:ss"
    }

    let date = new Date(value*1000);
    // 获取年份
    let year = date.getFullYear();

    if (/(y+)/.test(format)) {
        // 获取匹配组的内容
        let content = RegExp.$1;
        format = format.replace(content, year.toString().slice(4 - content.length));
    }

    let o = {
        // y: date.getFullYear(),  // 用这一句也行，但只适用于四位数显示时候用
        M: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        m: date.getMinutes(),
        s: date.getSeconds()
    };

    for (let key in o) {
        // 构造动态正则
        let reg = new RegExp(`(${key}+)`);

        if (reg.test(format)) {
            // 获取匹配组的内容
            let content = RegExp.$1;
            let k = o[key] >= 10 ? o[key] : content.length === 2 ? '0' + o[key] : o[key];
            format = format.replace(content, k);
        }
    }
    return format;
}

import React from "react";
import { Space, Table, Tag,Row,Col,Button,Modal,Input,Select,message,Pagination } from 'antd';
import {
    SearchOutlined,
    EditOutlined,
    PlusCircleOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import {copyText, formatDate} from '../../../utils/browserUtils';
import ProviderApi from "../../../api/ProviderApi";
const { TextArea } = Input;

class ProviderManage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            columns:true,
            totalCount:0,
            showRegister:false,
            showModify:false,
            statusList:[
                {value: "1", label: "正常"},
                {value: "2", label: "停止"},
            ],
            providerSearch:{

            },
            registerProvider:{
                name:undefined,
                providerConfig:undefined,
            },
            providerModify:{
                name:undefined,
                providerConfig:undefined,
            }
        };
        this.check = this.check.bind(this)
        this.onProviderAddressChange = this.onProviderAddressChange.bind(this)
        this.onProviderNameChange = this.onProviderNameChange.bind(this)
        this.onPaginationChange = this.onPaginationChange.bind(this)
        this.onProviderConfigChange = this.onProviderConfigChange.bind(this)
        this.SearchProvider = this.SearchProvider.bind(this)
        this.handleModifyOk = this.handleModifyOk.bind(this)
        this.handleRegisterOk = this.handleRegisterOk.bind(this)
        this.handleModifyCancel = this.handleModifyCancel.bind(this)
        this.handleShowEdit = this.handleShowEdit.bind(this)
        this.registerProvider = this.registerProvider.bind(this)
        this.handleRegisterCancel = this.handleRegisterCancel.bind(this)
        this.onModifyProviderNameChange = this.onModifyProviderNameChange.bind(this)
        this.onModifyProviderConfigChange = this.onModifyProviderConfigChange.bind(this)
    }
    async componentWillMount() {
        await this.check();
    }
    async queryProviderList(providerSearch){
        let providerList = await ProviderApi.queryProviderList(providerSearch)
        return providerList;
    }
    async check() {
        const columns = [
            {
                title: 'Address',
                dataIndex: 'address',
                key: 'address',
                width: 200,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: 150,
                ellipsis:true,
            },
            {
                title: 'Status',
                key: 'status',
                width: 120,
                dataIndex: 'status',
                render: (status) => {
                    let color = 'red'
                    let text = 'Terminated'
                    if(status === 1){
                        color = 'green'
                        text = '正常'
                    }
                    if(status === 2){
                        color = 'red'
                        text = '已停止'
                    }
                    return (
                        <Tag color={color} key={text}>{text}</Tag>
                    );
                }
            },
            {
                title: 'Provide Config',
                dataIndex: 'config',
                key: 'config'
            },
            {
                title: 'Create time',
                dataIndex: 'create_time',
                key: 'create_time',
                width: 200,
                render: (text) => {return <span>{this.formatDateStr(text)}</span>},
            },
            {
                title: 'Action',
                key: 'action',
                fixed: 'right',
                render: (_, record) => (
                    <Space size="middle">
                        <Button type="primary" size='small' icon={<EditOutlined />} onClick={() => {this.handleShowEdit(record)}}>Edit</Button>
                        {
                            record.status === 1?(<Button type="primary" size='small' icon={<DeleteOutlined />} onClick={() =>
                            {
                                Modal.confirm({
                                    title: 'Are you sure remove this provider:',
                                    icon: <ExclamationCircleOutlined />,
                                    content: record.address,
                                    okText: 'Yes',
                                    cancelText: 'Cancel',
                                    onOk:()=>{this.handleRemoveProvider(record)}
                                });
                            }
                            }>Remove</Button>):''
                        }
                    </Space>
                ),
            },
        ];
        this.setState({columns:columns})
        // 查询Provider列表
        let resp = await this.queryProviderList(this.state.providerSearch);
        if(!resp.error){
            let result = resp.result
            if(!result){
                return;
            }
            const data=[]
            if(result.count>0){
                this.setState({totalCount:result.count})
                for (const item of result.list) {
                    const provider = {
                        key:item.address,
                        name:item.name,
                        address:item.address,
                        config:JSON.stringify(item.config),
                        create_time:item.create_time,
                        update_time:item.update_time,
                        status:item.status
                    }
                    data.push(provider)
                }
            }else {
                this.setState({totalCount:0})
            }
            this.setState({providerList:data})
        }else {
            this.setState({providerList:undefined})
        }
    }
    onProviderAddressChange(e){
        let providerSearch = this.state.providerSearch
        providerSearch.address = e.target.value
        this.setState({providerSearch:providerSearch})
    }
    onProviderNameChange(e){
        let registerProvider = this.state.registerProvider
        registerProvider.name = e.target.value
        this.setState({registerProvider:registerProvider})
    }
    onProviderConfigChange(e){
        let registerProvider = this.state.registerProvider
        registerProvider.providerConfig = e.target.value
        this.setState({registerProvider:registerProvider})
    }
    onPaginationChange(page, pageSize){
        let providerSearch = this.state.providerSearch
        providerSearch.offset = page
        providerSearch.size = pageSize
        this.setState({providerSearch:providerSearch})
        this.SearchProvider()
    }
    SearchProvider(){
        this.check()
    }
    formatDateStr(value){
        if(value){
            return value.replace("T"," ").replace(".000Z","")
        }else {
            return ''
        }
    }

    handleModifyOk(){
        const providerModify = this.state.providerModify;
        if(!providerModify.providerAddress){
            message.error("Provider信息异常，请刷新页面后重试！")
            return;
        }
        if(!providerModify.providerName){
            message.error("Provider Name不能为空！")
            return;
        }
        if(!providerModify.providerConfig){
            message.error("Provider Config不能为空！")
            return;
        }
        //
        const data = {
            address:providerModify.providerAddress,
            name:providerModify.providerName,
            config:providerModify.providerConfig,
        }
        ProviderApi.providerModify(data).then(resp => {
            if(!resp.error){
                message.success("Provider信息修改成功！")
                this.setState({showModify:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
        })
    }
    handleModifyCancel(){
        this.setState({showModify:false})
    }

    handleShowEdit(record){
        let providerModify = this.state.providerModify;
        providerModify.providerAddress = record.address
        providerModify.providerName = record.name
        providerModify.providerConfig = record.config
        this.setState({providerModify:providerModify})
        this.setState({showModify:true})
    }
    onModifyProviderNameChange(e){
        let providerModify = this.state.providerModify
        providerModify.providerName = e.target.value
        this.setState({providerModify:providerModify})
    }
    onModifyProviderConfigChange(e){
        let providerModify = this.state.providerModify
        providerModify.providerConfig = e.target.value
        this.setState({providerModify:providerModify})
    }

    registerProvider(){
        this.setState({showRegister:true})
    }
    handleRegisterOk(){
        let registerProvider = this.state.registerProvider;
        if(!registerProvider.name){
            message.error("Provider Name不能为空！")
            return;
        }
        if(!registerProvider.providerConfig){
            message.error("Provider Config不能为空！")
            return;
        }
        const params = {
            providerName:registerProvider.name,
            providerConfig:registerProvider.providerConfig
        }
        ProviderApi.providerRegister(params).then(resp => {
            if(!resp.error){
                message.success("Provider注册成功")
                this.setState({showRegister:false})
                this.check();
            }else {
                message.error("Provider注册失败："+resp.error.message)
            }
        })
    }
    handleRegisterCancel(){
        this.setState({showRegister:false})
    }

    handleRemoveProvider(record){
        const address = record.address;
        ProviderApi.removeProvider({address:address}).then(resp => {
            if(!resp.error){
                message.success("Provider删除成功")
                this.check();
            }else {
                message.error("Provider删除失败："+resp.error.message)
            }
        })
    }

    render() {
        return (
            <div style={{width:'100%',height:'100%',overflow:"hidden"}}>
                <Row>
                    <Col span={4}>
                        <span style={{fontWeight:'bold',fontSize:18}}>Provider Manage</span>
                    </Col>
                    <Col span={20}>
                        <div style={{textAlign:'right'}}>
                            <span>
                                Address:<Input onChange={this.onProviderAddressChange} size='small' style={{width:180}} placeholder="provider address" allowClear />
                            </span>
                            <span style={{marginLeft:10}}>
                                <Button style={{marginLeft:10}} size='small' onClick={this.SearchProvider} type="primary" icon={<SearchOutlined />}>Search</Button>
                            </span>
                            <span style={{marginLeft:10}}>
                                <Button style={{marginLeft:10}} size='small' danger onClick={this.registerProvider} icon={<PlusCircleOutlined />}>Register Provider</Button>
                            </span></div>
                    </Col>
                </Row>
                {this.state.totalCount>10?<div style={{textAlign:'right',paddingTop:15}}>
                    <Pagination onChange={this.onPaginationChange} size="small" total={this.state.totalCount} showSizeChanger showQuickJumper />
                </div>:''}
                <div style={{marginTop:10,width:'100%',height:'100%',overflow:'auto'}}>
                    <Table size='small' pagination={false} columns={this.state.columns} dataSource={this.state.providerList} />
                </div>
                <Modal width='800px' title="Register Provider" open={this.state.showRegister} onOk={this.handleRegisterOk} destroyOnClose={true} confirmLoading={this.state.confirmLoading} onCancel={this.handleRegisterCancel}>
                    <Row>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Provider Name:</span>
                        </Col>
                        <Col span={20}>
                            <Input placeholder="provider name" onChange={this.onProviderNameChange}/>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Provider Config:</span>
                        </Col>
                        <Col span={20}>
                            <TextArea rows={6} placeholder="Provider JSON Config" onChange={this.onProviderConfigChange} />
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                    </Row>
                </Modal>
                <Modal width='800px' title="Modify Provider" open={this.state.showModify} onOk={this.handleModifyOk} destroyOnClose={true} confirmLoading={this.state.confirmLoading} onCancel={this.handleModifyCancel}>
                    <Row>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Provider Address:</span>
                        </Col>
                        <Col span={20}>
                            <span style={{lineHeight:'32px'}}>{this.state.providerModify.providerAddress}</span>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Provider Name:</span>
                        </Col>
                        <Col span={20}>
                            <Input value={this.state.providerModify.providerName} readOnly={true} placeholder="provider name" onChange={this.onModifyProviderNameChange}/>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Provider Config:</span>
                        </Col>
                        <Col span={20}>
                            <TextArea value={this.state.providerModify.providerConfig} rows={6} placeholder="Provider JSON Config" onChange={this.onModifyProviderConfigChange} />
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                    </Row>
                </Modal>
            </div>
        )
    }
}

export default ProviderManage;

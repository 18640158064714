import HttpUtils from '../utils/HttpUtils'
import {getFilters} from "../utils/browserUtils";
const baseUrl = process.env.REACT_APP_BASE_API

async function queryAllPlantsList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_plant_queryAllPlantList", params,token)
}

async function queryPlantDataByDeviceId(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_plant_querydataByDevicdId", params,token)
}

async function queryAllApiPlantsList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_plant_queryAllApiPlantList", params,token)
}

async function registerApiPlant(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_plant_registerApiPlant", params,token)
}

async function queryCommonPlantsListByModel(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_plant_queryDeviceListByModel", params,token)
}




const PlantServiceApi ={
    queryAllPlantsList:queryAllPlantsList,
    queryPlantDataByDeviceId:queryPlantDataByDeviceId,
    queryAllApiPlantsList:queryAllApiPlantsList,
    registerApiPlant:registerApiPlant,
    queryCommonPlantsListByModel:queryCommonPlantsListByModel,
}

export default PlantServiceApi;

import {getMetaMaskLoginUserAddress} from "./metamaskUtils";
import contract from "./contract.json";
import {ethers} from 'ethers';

let provider = null;

if (window.ethereum) {
    provider = new ethers.providers.Web3Provider(window.ethereum)
}

/**
 * 查询原生币余额
 * @returns {Promise<void>}
 */
export async function getBalance() {
    let address = await getMetaMaskLoginUserAddress();
    let balance = await provider.getBalance(address);
    return balance
}

export async function contractBalanceOf(contractAddress) {
    let address = await getMetaMaskLoginUserAddress();
    const tokenContract = new ethers.Contract(contractAddress, contract.erc20Abi.balanceOf, provider);
    return tokenContract.balanceOf(address);
}

/**
 * 查询代币名称
 * @returns balance
 */
export async function symbol(tokenAddress) {
    if(!tokenAddress){
        return undefined;
    }
    let symbolName = CurrencyType[tokenAddress.toLowerCase()];
    if(symbolName){
        return symbolName;
    }else {
        const tokenContract = new ethers.Contract(tokenAddress, contract.erc20Abi.symbol, provider);
        return tokenContract.symbol();
    }
}

const CurrencyType = {
    "0x6c28ff02d3a132fe52d022db1f25a33d91caeca2":"gAKRE",
    "0x960c67b8526e6328b30ed2c2faea0355beb62a83":"gAKRE",
    "0x2791bca1f2de4661ed88a30c99a7a9449aa84174":"USDC",
    "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270":"WMATIC",
    "0x9c5653339e0b3a99262997fbb541e2562f3361c9":"USDC(测试)"
}

import React from "react";
import { Space, Table, Tag,Row,Col,Button,Modal,Input,Select,message,Pagination } from 'antd';
import {SearchOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined,PlusOutlined,PlusCircleOutlined} from '@ant-design/icons';
import {formatDate,copyText} from '../../../utils/browserUtils';
import CouponApi from "../../../api/CouponApi";
const { TextArea } = Input;

class CouponManage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            columns:true,
            totalCount:0,
            showEdit:false,
            showCreate:false,
            statusList:[
                {value: "0", label: "未使用"},
                {value: "1", label: "已使用"}
            ],
            couponSearch:{

            },
            createCouponInfo:{

            }
        };
        this.check = this.check.bind(this)
        this.onStatusChange = this.onStatusChange.bind(this)
        this.onAddressChange = this.onAddressChange.bind(this)
        this.onPaginationChange = this.onPaginationChange.bind(this)
        this.SearchCoupon = this.SearchCoupon.bind(this)
        this.createCoupon = this.createCoupon.bind(this)
        this.handleCreateCancel = this.handleCreateCancel.bind(this)
        this.onEmailChange = this.onEmailChange.bind(this)
        this.onCountChange = this.onCountChange.bind(this)
        this.handleCreateOk = this.handleCreateOk.bind(this)
        this.onExpiredTimeChange = this.onExpiredTimeChange.bind(this)
    }
    async componentWillMount() {
        await this.check();
    }
    async queryCouponList(couponSearch){
        let minerList = await CouponApi.queryCouponList(couponSearch)
        return minerList;
    }
    async check() {
        const columns = [
            {
                title: 'Coupon Code',
                dataIndex: 'coupon',
                key: 'coupon',
                width: 320,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Status',
                key: 'status',
                width: 150,
                dataIndex: 'status',
                render: (status) => {
                    let color = 'red'
                    let text = 'Terminated'
                    if(status === 0){
                        color = '#FF9224'
                        text = '未使用'
                    }
                    if(status === 1){
                        color = 'green'
                        text = '已购买GamingMiner'
                    }
                    if(status === 2){
                        color = 'green'
                        text = '已购买RemoteMiner'
                    }
                    if(status === 3){
                        color = 'red'
                        text = '已使用'
                    }
                    return (
                        <Tag color={color} key={text}>{text}</Tag>
                    );
                }
            },
            {
                title: 'Owner',
                dataIndex: 'owner',
                width: 200,
                ellipsis:true,
                key: 'owner',
            },
            {
                title: '分发人',
                dataIndex: 'email',
                width: 280,
                ellipsis:true,
                key: 'email',
            },
            {
                title: 'Expired Time',
                dataIndex: 'expired_time',
                key: 'expired_time',
                width: 200,
                render: (text) => {return <span>{text?formatDate(text):''}</span>},
            },
            {
                title: 'Create time',
                dataIndex: 'create_time',
                key: 'create_time',
                width: 200,
                render: (text) => {return <span>{this.formatDateStr(text)}</span>},
            },
            {
                title: 'Action',
                key: 'action',
                fixed: 'right',
                render: (_, record) => (
                    <Space size="middle">
                        {
                            (record.status!==0)?"":<Button
                                type="primary"
                                size='small'
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    Modal.confirm({
                                        title: 'Are you sure delete this coupon：',
                                        icon: <ExclamationCircleOutlined />,
                                        content: record.coupon,
                                        okText: 'Yes',
                                        cancelText: 'Cancel',
                                        onOk:()=>{this.handleDelete(record)}
                                    });}}>
                                删除
                            </Button>
                        }
                    </Space>
                ),
            },
        ];
        this.setState({columns:columns})
        // 查询电站列表
        let resp = await this.queryCouponList(this.state.couponSearch);
        if(!resp.error){
            let result = resp.result
            if(!result){
                return;
            }
            const data=[]
            if(result.count>0){
                this.setState({totalCount:result.count})
                for (const item of result.list) {
                    const plant = {
                        key:item.coupon,
                        coupon:item.coupon,
                        owner:item.owner,
                        email:item.email,
                        create_time:item.create_time,
                        expired_time:item.expired_time,
                        update_time:item.update_time,
                        status:item.status
                    }
                    data.push(plant)
                }
            }else {
                this.setState({totalCount:0})
            }
            this.setState({couponList:data})
        }else {
            this.setState({couponList:undefined})
        }
    }
    onStatusChange(value){
        let couponSearch = this.state.couponSearch
        couponSearch.status = value
        this.setState({couponSearch:couponSearch})
    }
    onAddressChange(e){
        let couponSearch = this.state.couponSearch
        couponSearch.address = e.target.value
        this.setState({couponSearch:couponSearch})
    }
    onPaginationChange(page, pageSize){
        let couponSearch = this.state.couponSearch
        couponSearch.offset = page
        couponSearch.size = pageSize
        this.setState({couponSearch:couponSearch})
        this.SearchCoupon()
    }
    SearchCoupon(){
        this.check()
    }
    formatDateStr(value){
        if(value){
            return value.replace("T"," ").replace(".000Z","")
        }else {
            return ''
        }
    }
    createCoupon(){
        this.setState({showCreate:true})
    }
    handleCreateCancel(){
        this.setState({showCreate:false})
        this.setState({createCouponInfo:{}})
    }
    onExpiredTimeChange(e){
        let createCouponInfo = this.state.createCouponInfo
        createCouponInfo.expired_time = Number(e.target.value)
        this.setState({createCouponInfo:createCouponInfo})
    }
    onEmailChange(e){
        let createCouponInfo = this.state.createCouponInfo
        createCouponInfo.email = e.target.value
        this.setState({createCouponInfo:createCouponInfo})
    }
    onCountChange(e){
        let createCouponInfo = this.state.createCouponInfo
        createCouponInfo.count = e.target.value
        this.setState({createCouponInfo:createCouponInfo})
    }
    handleCreateOk(){
        let createCouponInfo = this.state.createCouponInfo
        console.log("createCouponInfo:"+JSON.stringify(createCouponInfo))
        CouponApi.createCoupon(createCouponInfo).then(resp => {
            console.log("createCoupon result:"+JSON.stringify(resp))
            if(!resp.error){
                message.success("Coupon发送成功！")
                this.check();
                this.handleCreateCancel()
            }else {
                message.error(resp.error.message)
            }
        })
    }
    handleDelete(record){
        console.log(record)
        CouponApi.deleteCoupon(record.coupon).then(resp => {
            if(!resp.error){
                message.success("删除成功！")
                this.check();
            }else {
                message.error(resp.error.message)
            }
        })
    }

    render() {
        return (
            <div style={{width:'100%',height:'100%',overflow:"hidden"}}>
                <Row>
                    <Col span={4}>
                            <span style={{fontWeight:'bold',fontSize:18}}>
                                Coupon Manage
                            </span>
                    </Col>
                    <Col span={20}>
                        <div style={{textAlign:'right'}}>
                            <span>
                                Owner:<Input onChange={this.onAddressChange} size='small' style={{width:300}} placeholder="owner address" allowClear />
                            </span>
                            <span style={{marginLeft:10}}>Status:
                            <Select size='small' style={{width:150,textAlign:'left'}} placeholder="Select a use status" onChange={this.onStatusChange} allowClear options={this.state.statusList} />
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' onClick={this.SearchCoupon} type="primary" icon={<SearchOutlined />}>Search</Button>
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' danger onClick={this.createCoupon} icon={<PlusCircleOutlined />}>发放Coupon</Button>
                            </span></div>
                    </Col>
                </Row>
                {this.state.totalCount>10?<div style={{textAlign:'right',paddingTop:15}}>
                        <Pagination onChange={this.onPaginationChange} size="small" total={this.state.totalCount} showSizeChanger showQuickJumper />
                    </div>:''}
                <div style={{marginTop:10,width:'100%',height:'100%',overflow:'auto'}}>
                    <Table scroll={{x: 1435}} size='small' pagination={false} columns={this.state.columns} dataSource={this.state.couponList} />
                </div>
                <Modal okText={this.state.createText} width='800px' title="发放Coupon" open={this.state.showCreate} onOk={this.handleCreateOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleCreateCancel}>
                    <Row>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Expired Time:</span>
                        </Col>
                        <Col span={20}>
                            <Input readOnly={this.state.confirmLoading} type='number' placeholder="expired time of this coupon" onChange={this.onExpiredTimeChange} suffix="days"/>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Coupon数量:</span>
                        </Col>
                        <Col span={20}>
                            <Input readOnly={this.state.confirmLoading} type='number' placeholder="本次生成的Coupon数量" onChange={this.onCountChange} suffix="张"/>
                        </Col>
                        <Col span={24} style={{height:20}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>接收邮箱:</span>
                        </Col>
                        <Col span={20}>
                            <Input readOnly={this.state.confirmLoading} type='text' placeholder="接收Coupon码的邮箱地址" onChange={this.onEmailChange} />
                        </Col>
                        <Col span={24} style={{height:20}}>

                        </Col>
                    </Row>
                </Modal>
            </div>
        )
    }
}

export default CouponManage;

import React from "react";
import { Space, Table, Tag,Row,Col,Button,Modal,Input,Select,message,Pagination } from 'antd';
import {SearchOutlined, EditOutlined, StopOutlined, ExclamationCircleOutlined,PlusOutlined,PlusCircleOutlined,DeleteOutlined} from '@ant-design/icons';
import {copyText, formatDate} from '../../../utils/browserUtils';
import MarketServiceApi from "../../../api/MarketServiceApi"
import * as ethers from 'ethers'
const { TextArea } = Input;

class Preorder extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            columns:true,
            totalCount:0,
            showInsert:false,
            confirmLoading:false,
            showOnboard:false,
            showSendEmail:false,
            minerList:[
                {value: 9, label: "socketMiner"},
                {value: 8, label: "standardMiner"}
            ],
            minerSearch:{

            },
            preorderMsg:{
                emailAddress:undefined,
                minerType:undefined,
                offset:undefined,
                size:undefined
            },
            resendEmailInfo:{
                emailAddress:undefined,
                minerType:undefined,
            },
        };
        this.check = this.check.bind(this)
        this.onStatusChange = this.onStatusChange.bind(this)
        this.onEmailAddressChange = this.onEmailAddressChange.bind(this)
        this.onPaginationChange = this.onPaginationChange.bind(this)
        this.SearchMiner = this.SearchMiner.bind(this)
        this.insertPreorder = this.insertPreorder.bind(this)
        this.handleRegisterCancel = this.handleRegisterCancel.bind(this)
        this.onEmailaddressChange = this.onEmailaddressChange.bind(this)
        this.handleInsertOk = this.handleInsertOk.bind(this)
        this.handleOnboardCancel = this.handleOnboardCancel.bind(this)
        this.onMinerTypeChange = this.onMinerTypeChange.bind(this)
        this.resendEmail = this.resendEmail.bind(this)
        this.handleSendEmailOk = this.handleSendEmailOk.bind(this)
        this.handleSendEmailCancel = this.handleSendEmailCancel.bind(this)
    }
    async componentWillMount() {
        await this.check();
    }
    async queryPreorderList(minerSearch){
        let preorderList = await MarketServiceApi.queryPreorderList(minerSearch)
        return preorderList;
    }
    async check() {
        const columns = [
            {
                title: 'index',
                dataIndex: 'index',
                key: 'index',
                width: 100,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'emailAddress',
                dataIndex: 'emailAddress',
                key: 'emailAddress',
                width: 300,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Miner Type',
                key: 'minerType',
                width: 200,
                dataIndex: 'minerType',
                render: (minerType) => {
                    let color = 'red'
                    let text = ''
                    if(minerType === 9){
                        color = 'green'
                        text = 'socketMiner'
                    }
                    if(minerType === 8){
                        color = 'orange'
                        text = 'standardMiner'
                    }
                    return (
                        <Tag color={color} key={text}>{text}</Tag>
                    );
                }
            },
            {
                title: 'Create time',
                dataIndex: 'create_time',
                key: 'create_time',
                width: 300,
                render: (text) => {return <span>{text?formatDate(text):''}</span>},
            },
            {
                title: 'Email status',
                dataIndex: 'emailStatus',
                key: 'emailStatus',
                width: 200,
                render: (emailStatus) => {
                    let color = 'red'
                    let text = ''
                    if(emailStatus === 1){
                        color = 'green'
                        text = '已发送'
                    }
                    if(emailStatus === 0){
                        color = 'red'
                        text = '未发送'
                    }
                    return (
                        <Tag color={color} key={text}>{text}</Tag>
                    );
                }
            },

            {
                title: 'Action',
                key: 'action',
                fixed: 'right',
                render: (_, record) => (
                    <Space size="middle">
                        {
                            <Button size='small' type="primary" icon={<DeleteOutlined />} onClick={()=>{
                                Modal.confirm({
                                title: 'Are you sure delete this message',
                                icon: <ExclamationCircleOutlined />,
                                content: record.name,
                                okText: 'Yes',
                                cancelText: 'Cancel',
                                onOk:()=>{this.handleDelete(record)}
                                    });
                                }}>
                                Delete
                                </Button>
                        }

                        {
                            (record.emailStatus=== 0)?<Button
                            type="primary"
                            size='small'
                            disabled={false}
                            icon={<EditOutlined />}
                            onClick={() => {this.resendEmail(record)}}>
                            resendEmail
                            </Button>:""

                            
                        }
                    </Space>
                ),
            },
        ];
        this.setState({columns:columns})
        // 查询电站列表
        let resp = await this.queryPreorderList(this.state.minerSearch);
        if(!resp.error){
            let result = resp.result
            if(!result){
                return;
            }
            const data=[]
            if(result.count>0){
                let minerSearch = this.state.minerSearch
                this.setState({totalCount:result.count});
                let index = 0;
                for (const item of result.list) {
                    if(minerSearch.minerType && minerSearch.minerType !== item.minerType){
                        continue;
                    }
                    if(minerSearch.emailAddress && minerSearch.emailAddress !== item.emailAddress){
                        continue;
                    }
                    const preroderList = {
                        // key:item.emailAddress,
                        emailAddress:item.emailAddress,
                        minerType:item.minerType,
                        create_time:item.createTime,
                        emailStatus:item.emailStatus,
                        index:++index,
                        key:index
                    }
                    data.push(preroderList)
                }
            }else {
                this.setState({totalCount:0})
            }
            this.setState({plantList:data})
        }else {
            this.setState({plantList:undefined})
        }
    }
    onStatusChange(value){
        let minerSearch = this.state.minerSearch
        minerSearch.minerType = value
        this.setState({minerSearch:minerSearch})
    }
    onEmailAddressChange(e){
        let minerSearch = this.state.minerSearch
        minerSearch.emailAddress = e.target.value
        this.setState({minerSearch:minerSearch})
    }
    onPaginationChange(page, pageSize){
        let minerSearch = this.state.minerSearch
        minerSearch.offset = page
        minerSearch.size = pageSize
        this.setState({minerSearch:minerSearch})
        this.SearchMiner()
    }
    onEmailaddressChange(e){
        let preorderMsg = this.state.preorderMsg
        console.log(preorderMsg);
        preorderMsg.emailAddress = e.target.value
        this.setState({preorderMsg:preorderMsg})
    }

    onMinerTypeChange(e){
        let preorderMsg = this.state.preorderMsg
        console.log(preorderMsg);
        console.log(e)
        preorderMsg.minerType = e
        this.setState({preorderMsg:preorderMsg})
    }
    SearchMiner(){
        this.check()
    }
    formatDateStr(value){
        if(value){
            return value.replace("T"," ").replace(".000Z","")
        }else {
            return ''
        }
    }
    

    insertPreorder(){
        this.setState({showInsert:true})
    }
    async handleInsertOk(){
        let preorderMsg = this.state.preorderMsg
        console.log(preorderMsg)
        if(!preorderMsg.emailAddress){
            message.error("请输入email address！")
            return;
        }
        if(!preorderMsg.minerType){
            message.error("请选择MinerType！")
            return;
        }
        this.setState({confirmLoading:true})
        let params = {
            emailAddress:preorderMsg.emailAddress,
            minerType:preorderMsg.minerType
        }
        // MarketServiceApi.inserPreorderMsg(params).then(resp => {
        //     if(!resp.error){
        //         message.success("Standard Miner注册成功！")
        //         this.setState({showInsert:false})
        //         this.check()
        //     }else {
        //         message.error(resp.error.message)
        //     }
        //     this.setState({confirmLoading:false})
        // })
        try {
            const result = await MarketServiceApi.inserPreorderMsg(params);
            if(!result.error){
                message.success("insert preorder message 成功！")
                this.setState({showInsert:false})
                this.check()
            }else{
                message.error(result.error.message)
            }
            this.setState({confirmLoading:false})
        } catch (error) {
            message.error(error.message)
            this.setState({confirmLoading:false})
        }
    }
    handleRegisterCancel(){
        this.setState({showInsert:false})
    }

    handleDelete(record){
        if(!record.emailAddress){
            message.error("email adress 为空！")
            return;
        }
        if(!record.minerType){
            message.error("miner Type 为空！")
            return;
        }
        console.log(record.emailAddress);
        this.setState({confirmLoading:true})
        let params = {
            emailAddress:record.emailAddress,
            minerType:record.minerType
        };
        MarketServiceApi.deletePreorderMsg(params).then(resp => {
            if(!resp.error){
                message.success("preorder message删除成功！")
                this.check()
            }else {
                message.error(resp.error.message)
            }
            this.setState({confirmLoading:false})
        })
    }

    handleOnboardCancel(){
        this.setState({showOnboard:false})
    }

    async handleSendEmailOk(e){
        console.log(e)
        let resendEmailInfo = this.state.resendEmailInfo;
        this.setState({confirmLoading:true})
        let params = {
            emailAddress:resendEmailInfo.emailAddress,
            minerType:resendEmailInfo.minerType
        };
        // await MarketServiceApi.resendEmailForPreorder(params).then(resp => {
        //     if(!resp.error){
        //         message.success("Email 发送成功！")
        //         this.check()
        //     }else {
        //         message.error(resp.error.message)
        //     }
        //     this.setState({confirmLoading:false})
        //     this.setState({showSendEmail:false});
        // })
        
        try {
            const result = await MarketServiceApi.resendEmailForPreorder(params);
            if(!result.error){
                message.success("Email 发送成功！")
                this.setState({showSendEmail:false});
                this.check()
            }else{
                message.error(result.error.message)
            }
            this.setState({confirmLoading:false})
            
        } catch (error) {
            message.error(error.message)
            this.setState({confirmLoading:false})
        }
    }
    handleSendEmailCancel(){
        this.setState({showSendEmail:false});
    }

    resendEmail(record){
        let resendEmailInfo = this.state.resendEmailInfo;
        resendEmailInfo.emailAddress = record.emailAddress;
        resendEmailInfo.minerType = record.minerType;
        this.setState({resendEmailInfo:resendEmailInfo});
        this.setState({showSendEmail:true});
        
    }

    render() {
        return (
            <div style={{width:'100%',height:'100%',overflow:"hidden"}}>
                <Row>
                    <Col span={4}>
                        <span style={{fontWeight:'bold',fontSize:18}}>
                            Preorder Message
                        </span>
                    </Col>
                    <Col span={20}>
                        <div style={{textAlign:'right'}}>
                            <span>
                                emailAddress:<Input onChange={this.onEmailAddressChange} size='small' style={{width:180}} placeholder="email address" allowClear />
                            </span>
                            <span style={{marginLeft:10}}>MinerType:
                            <Select size='small' style={{width:150}} placeholder="Select miner type" onChange={this.onStatusChange} allowClear options={this.state.minerList} />
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' onClick={this.SearchMiner} type="primary" icon={<SearchOutlined />}>Search</Button>
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' danger onClick={this.insertPreorder} icon={<PlusCircleOutlined />}>Insert preorder</Button>
                            </span>
                        </div>
                    </Col>
                </Row>
                {this.state.totalCount>10?<div style={{textAlign:'right',paddingTop:15}}><Pagination onChange={this.onPaginationChange} size="small" total={this.state.totalCount} showSizeChanger showQuickJumper /></div>:''}
                <div style={{marginTop:10,width:'100%',height:'100%',overflow:'scroll'}}>
                    <Table scroll={{x: 1620}} size='small' pagination={false} columns={this.state.columns} dataSource={this.state.plantList} />
                </div>
                <Modal width='800px' title="Insert Preorder" open={this.state.showInsert} destroyOnClose={true} onOk={this.handleInsertOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleRegisterCancel}>
                    <Row>
                        {
                            <Col span={24} style={{height:10}}></Col>
                        }
                        {
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>email address:</span></Col>
                        }
                        {
                            <Col span={20}><TextArea rows={2} onChange={this.onEmailaddressChange} /></Col>
                        }
                    </Row>
                    <Row>
                        {
                            <Col span={24} style={{height:10}}></Col>
                        }
                        {
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>minerType:</span></Col>
                        }
                        {
                            <Select size='small' style={{width:150}} placeholder="Select miner type" onChange={this.onMinerTypeChange} allowClear options={this.state.minerList} />
                        }
                    </Row>
                    
                </Modal>
                <Modal width='400px' title="Send Email" open={this.state.showSendEmail} destroyOnClose={true} onOk={this.handleSendEmailOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleSendEmailCancel}>
                    
                    <Row>
                        {
                            <Col span={24} style={{height:10}}></Col>
                        }
                        {
                            <Col span={10}  style={{textAlign:'middle',paddingRight:8}}><span style={{lineHeight:'32px'}}> {this.state.resendEmailInfo.emailAddress}</span></Col>
                        }
                        
                    </Row>
                    
                </Modal>

            </div>
        )
    }
}

export default Preorder;

import React from "react";
import { Space, Table, Tag,Row,Col,Button,Modal,Input,Select,message,Pagination } from 'antd';
import {SearchOutlined, EditOutlined, StopOutlined, ExclamationCircleOutlined,PlusOutlined,PlusCircleOutlined,DeleteOutlined} from '@ant-design/icons';
import {copyText, formatDate} from '../../../utils/browserUtils';
import PlantServiceApi from '../../../api/PlantServiceApi'
import PlantMinerApi from '../../../api/PlantMinerApi'
import MarketServiceApi from '../../../api/MarketServiceApi'
import * as ethers from 'ethers'
import {getFilters} from "../../../utils/browserUtils";
import axios from "axios";
const { TextArea } = Input;

const s3Url = process.env.REACT_APP_S3_URL

class ApiPlant extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showRegister:false,
            totalCount:0,
            showCreate:false,
            selectedFile:null,
            uploadedUrl:'',
            fileName:'',
            plantSearch:{
                deviceId:undefined,
                provider:undefined
            },
            providerList: [
                {value: "homeAssistant", label: "HomeAssistant"},
                {value: "enphase", label: "enphase"},
                {value: "common", label: "common"},
            ],
            plantList:[],
            registerPlant:{
                provider:undefined,
                country:undefined,
                city:undefined,
                deviceModel:undefined,
                deviceName:undefined,
                ratedPower:undefined,
                clientId:undefined,
                plantform:undefined,
            },
            createPlantMiner:{
                deviceId:undefined,
                provider:undefined,
                country:undefined,
                city:undefined,
                deviceModel:undefined,
                deviceName:undefined,
                ratedPower:undefined,
                clientId:undefined,
                panelLink:undefined,
                expired_time:undefined,
                ratedPower:undefined
            },
            countryList : [
                {value: "Afghanistan",label: "Afghanistan"},
                {value: "Albania",label: "Albania"},
                {value: "Algeria",label: "Algeria"},
                {value: "Andorra",label: "Andorra"},
                {value: "Angola",label: "Angola"},
                {value: "Antarctica",label: "Antarctica"},
                {value: "Antigua and Barbuda",label: "Antigua and Barbuda"},
                {value: "Argentina",label: "Argentina"},
                {value: "Armenia",label: "Armenia"},
                {value: "Ashmore and Cartier Islands",label: "Ashmore and Cartier Islands"},
                {value: "Australia",label: "Australia"},
                {value: "Indian Ocean Territories",label: "Indian Ocean Territories"},
                {value: "Heard Island and McDonald Islands",label: "Heard Island and McDonald Islands"},
                {value: "Norfolk Island",label: "Norfolk Island"},
                {value: "Austria",label: "Austria"},
                {value: "Azerbaijan",label: "Azerbaijan"},
                {value: "Bahrain",label: "Bahrain"},
                {value: "Bangladesh",label: "Bangladesh"},
                {value: "Barbados",label: "Barbados"},
                {value: "Belarus",label: "Belarus"},
                {value: "Belgium",label: "Belgium"},
                {value: "Belize",label: "Belize"},
                {value: "Benin",label: "Benin"},
                {value: "Bhutan",label: "Bhutan"},
                {value: "Bolivia",label: "Bolivia"},
                {value: "Bosnia and Herzegovina",label: "Bosnia and Herzegovina"},
                {value: "Botswana",label: "Botswana"},
                {value: "Brazil",label: "Brazil"},
                {value: "Brunei",label: "Brunei"},
                {value: "Bulgaria",label: "Bulgaria"},
                {value: "Burkina Faso",label: "Burkina Faso"},
                {value: "Burundi",label: "Burundi"},
                {value: "Cambodia",label: "Cambodia"},
                {value: "Cameroon",label: "Cameroon"},
                {value: "Canada",label: "Canada"},
                {value: "Cape Verde",label: "Cape Verde"},
                {value: "Central African Republic",label: "Central African Republic"},
                {value: "Chad",label: "Chad"},
                {value: "Chile",label: "Chile"},
                {value: "China",label: "China"},
                {value: "Hong Kong S.A.R.",label: "Hong Kong S.A.R."},
                {value: "Macao S.A.R",label: "Macao S.A.R"},
                {value: "Colombia",label: "Colombia"},
                {value: "Comoros",label: "Comoros"},
                {value: "Costa Rica",label: "Costa Rica"},
                {value: "Croatia",label: "Croatia"},
                {value: "Cuba",label: "Cuba"},
                {value: "Cyprus",label: "Cyprus"},
                {value: "Czech Republic",label: "Czech Republic"},
                {value: "Democratic Republic of the Congo",label: "Democratic Republic of the Congo"},
                {value: "Denmark",label: "Denmark"},
                {value: "Greenland",label: "Greenland"},
                {value: "Faroe Islands",label: "Faroe Islands"},
                {value: "Djibouti",label: "Djibouti"},
                {value: "Dominica",label: "Dominica"},
                {value: "Dominican Republic",label: "Dominican Republic"},
                {value: "East Timor",label: "East Timor"},
                {value: "Ecuador",label: "Ecuador"},
                {value: "Egypt",label: "Egypt"},
                {value: "El Salvador",label: "El Salvador"},
                {value: "Equatorial Guinea",label: "Equatorial Guinea"},
                {value: "Eritrea",label: "Eritrea"},
                {value: "Estonia",label: "Estonia"},
                {value: "Ethiopia",label: "Ethiopia"},
                {value: "Federated States of Micronesia",label: "Federated States of Micronesia"},
                {value: "Fiji",label: "Fiji"},
                {value: "Aland",label: "Aland"},
                {value: "Finland",label: "Finland"},
                {value: "French Southern and Antarctic Lands",label: "French Southern and Antarctic Lands"},
                {value: "Saint Barthelemy",label: "Saint Barthelemy"},
                {value: "France",label: "France"},
                {value: "Wallis and Futuna",label: "Wallis and Futuna"},
                {value: "Saint Martin",label: "Saint Martin"},
                {value: "Saint Pierre and Miquelon",label: "Saint Pierre and Miquelon"},
                {value: "New Caledonia",label: "New Caledonia"},
                {value: "French Polynesia",label: "French Polynesia"},
                {value: "Gabon",label: "Gabon"},
                {value: "Gambia",label: "Gambia"},
                {value: "Georgia",label: "Georgia"},
                {value: "Germany",label: "Germany"},
                {value: "Ghana",label: "Ghana"},
                {value: "Greece",label: "Greece"},
                {value: "Grenada",label: "Grenada"},
                {value: "Guatemala",label: "Guatemala"},
                {value: "Guinea",label: "Guinea"},
                {value: "Guinea Bissau",label: "Guinea Bissau"},
                {value: "Guyana",label: "Guyana"},
                {value: "Haiti",label: "Haiti"},
                {value: "Honduras",label: "Honduras"},
                {value: "Hungary",label: "Hungary"},
                {value: "Iceland",label: "Iceland"},
                {value: "India",label: "India"},
                {value: "Indonesia",label: "Indonesia"},
                {value: "Iran",label: "Iran"},
                {value: "Iraq",label: "Iraq"},
                {value: "Ireland",label: "Ireland"},
                {value: "Israel",label: "Israel"},
                {value: "Palestine",label: "Palestine"},
                {value: "Italy",label: "Italy"},
                {value: "Ivory Coast",label: "Ivory Coast"},
                {value: "Jamaica",label: "Jamaica"},
                {value: "Japan",label: "Japan"},
                {value: "Jordan",label: "Jordan"},
                {value: "Siachen Glacier",label: "Siachen Glacier"},
                {value: "Kazakhstan",label: "Kazakhstan"},
                {value: "Kenya",label: "Kenya"},
                {value: "Kiribati",label: "Kiribati"},
                {value: "Kosovo",label: "Kosovo"},
                {value: "Kuwait",label: "Kuwait"},
                {value: "Kyrgyzstan",label: "Kyrgyzstan"},
                {value: "Laos",label: "Laos"},
                {value: "Latvia",label: "Latvia"},
                {value: "Lebanon",label: "Lebanon"},
                {value: "Lesotho",label: "Lesotho"},
                {value: "Liberia",label: "Liberia"},
                {value: "Libya",label: "Libya"},
                {value: "Liechtenstein",label: "Liechtenstein"},
                {value: "Lithuania",label: "Lithuania"},
                {value: "Luxembourg",label: "Luxembourg"},
                {value: "Macedonia",label: "Macedonia"},
                {value: "Madagascar",label: "Madagascar"},
                {value: "Malawi",label: "Malawi"},
                {value: "Malaysia",label: "Malaysia"},
                {value: "Maldives",label: "Maldives"},
                {value: "Mali",label: "Mali"},
                {value: "Malta",label: "Malta"},
                {value: "Marshall Islands",label: "Marshall Islands"},
                {value: "Mauritania",label: "Mauritania"},
                {value: "Mauritius",label: "Mauritius"},
                {value: "Mexico",label: "Mexico"},
                {value: "Moldova",label: "Moldova"},
                {value: "Monaco",label: "Monaco"},
                {value: "Mongolia",label: "Mongolia"},
                {value: "Montenegro",label: "Montenegro"},
                {value: "Morocco",label: "Morocco"},
                {value: "Mozambique",label: "Mozambique"},
                {value: "Myanmar",label: "Myanmar"},
                {value: "Namibia",label: "Namibia"},
                {value: "Nauru",label: "Nauru"},
                {value: "Nepal",label: "Nepal"},
                {value: "Aruba",label: "Aruba"},
                {value: "Curaçao",label: "Curaçao"},
                {value: "Netherlands",label: "Netherlands"},
                {value: "Sint Maarten",label: "Sint Maarten"},
                {value: "Cook Islands",label: "Cook Islands"},
                {value: "New Zealand",label: "New Zealand"},
                {value: "Niue",label: "Niue"},
                {value: "Nicaragua",label: "Nicaragua"},
                {value: "Niger",label: "Niger"},
                {value: "Nigeria",label: "Nigeria"},
                {value: "North Korea",label: "North Korea"},
                {value: "Northern Cyprus",label: "Northern Cyprus"},
                {value: "Norway",label: "Norway"},
                {value: "Oman",label: "Oman"},
                {value: "Pakistan",label: "Pakistan"},
                {value: "Palau",label: "Palau"},
                {value: "Panama",label: "Panama"},
                {value: "Papua New Guinea",label: "Papua New Guinea"},
                {value: "Paraguay",label: "Paraguay"},
                {value: "Peru",label: "Peru"},
                {value: "Philippines",label: "Philippines"},
                {value: "Poland",label: "Poland"},
                {value: "Portugal",label: "Portugal"},
                {value: "Qatar",label: "Qatar"},
                {value: "Republic of Congo",label: "Republic of Congo"},
                {value: "Republic of Serbia",label: "Republic of Serbia"},
                {value: "Romania",label: "Romania"},
                {value: "Russia",label: "Russia"},
                {value: "Rwanda",label: "Rwanda"},
                {value: "Saint Kitts and Nevis",label: "Saint Kitts and Nevis"},
                {value: "Saint Lucia",label: "Saint Lucia"},
                {value: "Saint Vincent and the Grenadines",label: "Saint Vincent and the Grenadines"},
                {value: "Samoa",label: "Samoa"},
                {value: "San Marino",label: "San Marino"},
                {value: "Sao Tome and Principe",label: "Sao Tome and Principe"},
                {value: "Saudi Arabia",label: "Saudi Arabia"},
                {value: "Senegal",label: "Senegal"},
                {value: "Seychelles",label: "Seychelles"},
                {value: "Sierra Leone",label: "Sierra Leone"},
                {value: "Singapore",label: "Singapore"},
                {value: "Slovakia",label: "Slovakia"},
                {value: "Slovenia",label: "Slovenia"},
                {value: "Solomon Islands",label: "Solomon Islands"},
                {value: "Somalia",label: "Somalia"},
                {value: "Somaliland",label: "Somaliland"},
                {value: "South Africa",label: "South Africa"},
                {value: "South Korea",label: "South Korea"},
                {value: "South Sudan",label: "South Sudan"},
                {value: "Spain",label: "Spain"},
                {value: "Sri Lanka",label: "Sri Lanka"},
                {value: "Sudan",label: "Sudan"},
                {value: "Suriname",label: "Suriname"},
                {value: "Swaziland",label: "Swaziland"},
                {value: "Sweden",label: "Sweden"},
                {value: "Switzerland",label: "Switzerland"},
                {value: "Syria",label: "Syria"},
                {value: "Tajikistan",label: "Tajikistan"},
                {value: "Thailand",label: "Thailand"},
                {value: "The Bahamas",label: "The Bahamas"},
                {value: "Togo",label: "Togo"},
                {value: "Tonga",label: "Tonga"},
                {value: "Trinidad and Tobago",label: "Trinidad and Tobago"},
                {value: "Tunisia",label: "Tunisia"},
                {value: "Turkey",label: "Turkey"},
                {value: "Taiwan",label: "Taiwan"},
                {value: "Turkmenistan",label: "Turkmenistan"},
                {value: "Uganda",label: "Uganda"},
                {value: "Ukraine",label: "Ukraine"},
                {value: "United Arab Emirates",label: "United Arab Emirates"},
                {value: "Anguilla",label: "Anguilla"},
                {value: "Bermuda",label: "Bermuda"},
                {value: "Falkland Islands",label: "Falkland Islands"},
                {value: "United Kingdom",label: "United Kingdom"},
                {value: "Guernsey",label: "Guernsey"},
                {value: "Isle of Man",label: "Isle of Man"},
                {value: "Cayman Islands",label: "Cayman Islands"},
                {value: "Jersey",label: "Jersey"},
                {value: "British Indian Ocean Territory",label: "British Indian Ocean Territory"},
                {value: "Montserrat",label: "Montserrat"},
                {value: "Pitcairn Islands",label: "Pitcairn Islands"},
                {value: "South Georgia and South Sandwich Islands",label: "South Georgia and South Sandwich Islands"},
                {value: "Saint Helena",label: "Saint Helena"},
                {value: "British Virgin Islands",label: "British Virgin Islands"},
                {value: "Turks and Caicos Islands",label: "Turks and Caicos Islands"},
                {value: "United Republic of Tanzania",label: "United Republic of Tanzania"},
                {value: "American Samoa",label: "American Samoa"},
                {value: "Guam",label: "Guam"},
                {value: "Northern Mariana Islands",label: "Northern Mariana Islands"},
                {value: "Puerto Rico",label: "Puerto Rico"},
                {value: "United States of America",label: "United States of America"},
                {value: "United States Virgin Islands",label: "United States Virgin Islands"},
                {value: "Uruguay",label: "Uruguay"},
                {value: "Uzbekistan",label: "Uzbekistan"},
                {value: "Vanuatu",label: "Vanuatu"},
                {value: "Vatican",label: "Vatican"},
                {value: "Venezuela",label: "Venezuela"},
                {value: "Vietnam",label: "Vietnam"},
                {value: "Western Sahara",label: "Western Sahara"},
                {value: "Yemen",label: "Yemen"},
                {value: "Zambia",label: "Zambia"},
                {value: "Zimbabwe",label: "Zimbabwe"},
        ]
        };
        this.check = this.check.bind(this)
        this.onDeviceIdChange = this.onDeviceIdChange.bind(this);
        this.onProviderChange = this.onProviderChange.bind(this);
        this.SearchPlant = this.SearchPlant.bind(this);
        this.registerPlant = this.registerPlant.bind(this);
        this.handleRegisterCancel = this.handleRegisterCancel.bind(this);
        this.onProviderChangeRegister = this.onProviderChangeRegister.bind(this);
        this.onCountryChange = this.onCountryChange.bind(this);
        this.onCityChange = this.onCityChange.bind(this);
        this.onDeviceModelChange = this.onDeviceModelChange.bind(this);
        this.onDeviceNameChange = this.onDeviceNameChange.bind(this);
        this.onRatedPowerChange = this.onRatedPowerChange.bind(this);
        this.onClientIdChange = this.onClientIdChange.bind(this);
        this.handleRegisterOk = this.handleRegisterOk.bind(this);
        this.handleCreatePlantMIner = this.handleCreatePlantMIner.bind(this);
        this.handleCreateOk = this.handleCreateOk.bind(this);
        this.handleCreateCancel = this.handleCreateCancel.bind(this);
        this.onPanelLinkChange = this.onPanelLinkChange.bind(this);
        this.onMinerExpiredTimeChange = this.onMinerExpiredTimeChange.bind(this);
        this.onPlantFormChange = this.onPlantFormChange.bind(this);
        this.onMinerRatedPowerChange = this.onMinerRatedPowerChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this)
        
    }
    async componentWillMount() {
        await this.check();
    }
    
    async check() {
        const columns = [
            {
                title: 'Device Id',
                dataIndex: 'deviceId',
                key: 'deviceId',
                width: 200,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Provider',
                dataIndex: 'provider',
                width: 150,
                key: 'provider',
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
                
            },
            {
                title: 'Country',
                dataIndex: 'country',
                width: 150,
                key: 'country',
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
                
            },
            {
                title: 'City',
                dataIndex: 'city',
                width: 100,
                key: 'city',
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
                
            },
            {
                title: 'deviceModel',
                dataIndex: 'deviceModel',
                width: 150,
                key: 'deviceModel',
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
                
            },
            {
                title: 'deviceName',
                dataIndex: 'deviceName',
                width: 150,
                key: 'deviceName',
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
                
            },
            {
                title: 'clientId',
                dataIndex: 'clientId',
                width: 150,
                key: 'clientId',
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
                
            },
            {
                title: 'ratedPower(Kw)',
                dataIndex: 'ratedPower',
                width: 150,
                ellipsis:true,
                key: 'ratedPower',
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Create time',
                dataIndex: 'create_time',
                key: 'create_time',
                width: 250,
                render: (text) => {return <span>{text?formatDate(text):''}</span>},
            },
            {
                title: 'Action',
                key: 'action',
                fixed: 'right',
                render: (_, record) => (
                    <Space size="middle">
                        {
                            (record.status==='unCreated')?<Button
                                type="primary"
                                size='small'
                                disabled={false}
                                icon={<EditOutlined />}
                                onClick={() => {this.handleCreatePlantMIner(record)}}>
                                create
                            </Button>:""

                            
                        }
                    </Space>
                ),
            },
        ];
        this.setState({columns:columns})
        // 查询电站列表
        let plantMInerList = [];
        const resPlantMiner = await this.queryPlantMinerList({});
        
        if(!resPlantMiner.error){
            plantMInerList = resPlantMiner.result.list;
        }
        console.log('plantMInerList = ',plantMInerList)
        let resp = await this.queryPlantList(this.state.plantSearch);
        if(!resp.error){
            let result = resp.result
            if(!result){
                return;
            }
            const data=[]
            if(result.length>0){
                // this.setState({totalCount:result.length})
                for (const item of result) {
                    const miner = plantMInerList.find((item1)=> item1.deviceId == item.deviceId);
                    const plant = {
                        provider:item.provider,
                        deviceId:item.deviceId,
                        deviceModel:item.deviceModel,
                        deviceName:item.deviceName,
                        country:item.country,
                        city:item.city,
                        create_time:item.updateTime  ,
                        ratedPower:item.ratedPower/1000,
                        status:miner? 'created' : 'unCreated',
                        clientId:item.clientId
                    }
                    data.push(plant)
                }
            }else {
                this.setState({totalCount:0})
            }
            this.setState({plantList:data})
        }else{
            this.setState({plantList:[]})
        }
        
    }
    
    formatDateStr(value){
        if(value){
            return value.replace("T"," ").replace(".000Z","")
        }else {
            return ''
        }
    }
    handleEditOk(){
        const editMinerInfo = this.state.editMinerInfo;
        console.log("editMinerInfo:"+JSON.stringify(editMinerInfo))
        if(!editMinerInfo.address || editMinerInfo.address.length !== 42){
            message.error("Miner信息异常，请刷新页面后重试！")
            return;
        }
        if(!editMinerInfo.expired_time){
            message.error("过期天数不能为空！")
            return;
        }
        if(!editMinerInfo.price.amount){
            message.error("过期天数不能为空！")
            return;
        }
        if(Number(editMinerInfo.price.amount)<=0){
            message.error("请输入正确的过期天数！")
            return;
        }
        if(!editMinerInfo.price.currency){
            message.error("请选择用于支付的货币！")
            return;
        }
        //
        RemoteMinerApi.updateMiner(editMinerInfo).then(resp => {
            console.log(resp)
            if(!resp.error){
                message.success("Miner信息修改成功！")
                this.setState({showEdit:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
        })
    }

    handleOnboardOk(){
        let registerMiner = this.state.registerMiner
        if(!registerMiner.ownerAddress){
            message.error("请输入Miner 钱包地址！")
            return;
        }
        if(!ethers.utils.isAddress(registerMiner.ownerAddress)){
            message.error("请输入正确的钱包地址！")
            return;
        }

        PlantMinerApi.onboardMiners(registerMiner.address,registerMiner.ownerAddress).then(resp => {
            if(!resp.error && !resp.result.error){
                message.success("Standard Miner onboard成功！")
                this.setState({showOnboard:false})
                this.check()
            }else {
                if(resp.error){
                    message.error(resp.error.message)
                }else{
                    message.error(resp.result.error.message)
                }
                
            }
            this.setState({confirmLoading:false})
        })

    }
    
    
    onDeviceIdChange(e){
        let plantSearch = this.state.plantSearch
        plantSearch.deviceId = e.target.value
        this.setState({plantSearch:plantSearch})
    }
    onProviderChange(e){
        let plantSearch = this.state.plantSearch
        plantSearch.provider = e
        this.setState({plantSearch:plantSearch})
    }
    SearchPlant(){
        this.check()
    }
    registerPlant(){
        this.setState({showRegister:true})
    }
    handleRegisterCancel(){
        this.setState({showRegister:false})
        this.setState({selectedFile:null})
        this.setState({uploadedUrl:''})
        this.setState({fileName:''})
    }
    onProviderChangeRegister(e){
        console.log(e)
        let registerPlant = this.state.registerPlant
        registerPlant.provider = e
        this.setState({registerPlant:registerPlant})
    }
    onCountryChange(value){
        let registerPlant = this.state.registerPlant
        if(!value || value.length == 0){
            error.message('region error')
            registerPlant.country = undefined
            this.setState({registerPlant:registerPlant})
            return;
        }
        const country = value[0]
        registerPlant.country = country
        this.setState({registerPlant:registerPlant})
    }
    onCityChange(e){
        let registerPlant = this.state.registerPlant
        registerPlant.city = e.target.value
        this.setState({registerPlant:registerPlant})
    }
    onDeviceModelChange(e){
        let registerPlant = this.state.registerPlant
        registerPlant.deviceModel = e.target.value
        this.setState({registerPlant:registerPlant})
    }
    onDeviceNameChange(e){
        let registerPlant = this.state.registerPlant
        registerPlant.deviceName = e.target.value
        this.setState({registerPlant:registerPlant})
    }
    onRatedPowerChange(e){
        let registerPlant = this.state.registerPlant
        registerPlant.ratedPower = Number(e.target.value)
        this.setState({registerPlant:registerPlant})
    }
    onClientIdChange(e){
        let registerPlant = this.state.registerPlant
        registerPlant.clientId = e.target.value
        this.setState({registerPlant:registerPlant})
    }

    onPlantFormChange(e){
        let registerPlant = this.state.registerPlant
        registerPlant.plantform = e.target.value
        this.setState({registerPlant:registerPlant})
    }

    handleRegisterOk(){
        let registerPlant = this.state.registerPlant
        if(!registerPlant.provider){
            message.error("请输入选择provider！")
            return;
        }
        if(!registerPlant.country){
            message.error("请输入输入国家名称！")
            return;
        }
        if(!registerPlant.city){
            message.error("请输入输入城市名称！")
            return;
        }
        if(!registerPlant.deviceModel){
            message.error("请输入设备型号！")
            return;
        }
        if(!registerPlant.deviceName){
            message.error("请输入设备名称！")
            return;
        }
        if(!registerPlant.clientId){
            message.error("请输入clientId！")
            return;
        }
        if(registerPlant.provider == 'common'){
            if(!registerPlant.plantform){
                message.error("请输入plantform！")
                return;
            }
        }
       
        this.setState({confirmLoading:true})
        PlantServiceApi.registerApiPlant(registerPlant).then(resp => {
            if(!resp.error && !resp.result.error){
                message.success("Api Plant注册成功！")
                this.setState({showRegister:false})
                this.check()
            }else {
                if(resp.error){
                    message.error(resp.error.message)
                }else{
                    message.error(resp.result.error.message)
                }
                
            }
            this.setState({confirmLoading:false})
        })
    }

    async queryPlantList(minerSearch){
        let minerList = await PlantServiceApi.queryAllApiPlantsList(minerSearch)
        return minerList;
    }

    async queryPlantMinerList(minerSearch){
        let res = await PlantMinerApi.queryMinerList(minerSearch)
        return res;
    }

    handleCreatePlantMIner(record){
        let createPlantMiner = this.state.createPlantMiner
        createPlantMiner.city = record.city;
        createPlantMiner.country = record.country;
        createPlantMiner.deviceModel = record.deviceModel;
        createPlantMiner.deviceName = record.deviceName;
        createPlantMiner.provider = record.provider;
        createPlantMiner.deviceId = record.deviceId;
        createPlantMiner.ratedPower = Number(record.ratedPower) * 1000

        this.setState({createPlantMiner:createPlantMiner})
        this.setState({showCreate:true})
    }
    

    handleCreateOk(){
        let registerMiner = this.state.createPlantMiner
        if(!registerMiner.provider){
            message.error("请输入选择provider！")
            return;
        }
        if(!registerMiner.expired_time){
            message.error("请输入Miner所属的expired_time！")
            return;
        }
        if(!registerMiner.ratedPower){
            message.error("请输入Miner所属的ratedPower！")
            return;
        }
        switch (registerMiner.provider) {
            case 'homeAssistant':
                if(!registerMiner.deviceId){
                    message.error("请输入Miner所属的deviceId！")
                    return;
                }
                
                registerMiner.deviceId = registerMiner.deviceId;
                break;
            case 'enphase':
                if(!registerMiner.deviceId){
                    message.error("请输入Miner所属的deviceId！")
                    return;
                }
                
                registerMiner.deviceId = registerMiner.deviceId;
                break;
            case 'common':
                if(!registerMiner.deviceId){
                    message.error("请输入Miner所属的deviceId！")
                    return;
                }
                
                registerMiner.deviceId = registerMiner.deviceId;
                break;
        
            default:
                message.error("请输入Miner所属的provider 错误！")
                return;
                
        }
        if(!registerMiner.panelLink){
            message.error("请输入Miner所属的光伏板图片链接！")
            return;
        }
        if(!registerMiner.country){
            message.error("请输入Miner所属的光伏板所在国家信息！")
            return;
        }
        if(!registerMiner.city){
            message.error("请输入Miner所属的光伏板所在城市信息！")
            return;
        }
        this.setState({confirmLoading:true})
        PlantMinerApi.registerMiners(registerMiner).then(resp => {
            if(!resp.error && !resp.result.error){
                message.success("Plant Miner注册成功！")
                this.setState({showCreate:false})
                this.check()
            }else {
                if(resp.error){
                    message.error(resp.error.message)
                }else{
                    message.error(resp.result.error.message)
                }
                
            }
            this.setState({confirmLoading:false})
        })
    }

    handleCreateCancel(){
        this.setState({showCreate:false})
    }

    onPanelLinkChange(e){
        let createPlantMiner = this.state.createPlantMiner
        createPlantMiner.panelLink = e.target.value
        this.setState({createPlantMiner:createPlantMiner})
    }

    onMinerExpiredTimeChange(e){
        let createPlantMiner = this.state.createPlantMiner
        createPlantMiner.expired_time = e.target.value
        this.setState({createPlantMiner:createPlantMiner})
    }

    onMinerRatedPowerChange(e){
        let createPlantMiner = this.state.createPlantMiner
        createPlantMiner.ratedPower = Number(e.target.value)
        this.setState({createPlantMiner:createPlantMiner})
    }
    handleClick(){
        document.getElementById('fileInput').click();
      };
    handleUpload(){
        if (!this.state.selectedFile) {
          message.error('请先选择一个文件!');
          return;
        }
        let token = getFilters("token");
    
        const formData = new FormData();
        formData.append('file', this.state.selectedFile);
        formData.append('filePath','public/plant/');
        // token = token.replace(/"/g,'');
        token = token.substring(1);
        token = token.substring(0,token.length-1)
        console.log('token : ',token)
        try {
            console.log('s3Url : ',s3Url);
        axios.post(s3Url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'authorization':'Bearer '+ token
            },
          }).then(response => {
                // 假设响应包含已上传文件的 URL
                // setUploadedUrl(response.data.url);
                console.log('response : ',response);
                const code = response.data.code;
                if(code == 0){
                    this.setState({uploadedUrl:response.data.data})
                    let createPlantMiner = this.state.createPlantMiner
                    createPlantMiner.panelLink = response.data.data
                    this.setState({createPlantMiner:createPlantMiner})
                }else{
                    message.error('upload failed !!!');
                }
                
          });
    

        } catch (error) {
          console.error('上传文件时出错:', error);
          message.error('upload failed !!!');
        }
      };

      handleFileChange(event){
        const file = event.target.files[0];
        if(file){
            this.setState({selectedFile:file});
            this.setState({fileName:file.name});
        }
      };
    

    

    render() {
        return (
            <div style={{width:'100%',height:'100%',overflow:"hidden"}}>
                <Row>
                    <Col span={4}>
                        <span style={{fontWeight:'bold',fontSize:18}}>
                            Plant Manage
                        </span>
                    </Col>
                    <Col span={20}>
                        <div style={{textAlign:'right'}}>
                            <span>
                                DeviceId:<Input onChange={this.onDeviceIdChange} size='small' style={{width:180}} placeholder="device id" allowClear />
                            </span>
                            <span style={{marginLeft:10}}>Provider:
                            <Select size='small' style={{width:150}} placeholder="Select a provider" onChange={this.onProviderChange} allowClear options={this.state.providerList} />
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' onClick={this.SearchPlant} type="primary" icon={<SearchOutlined />}>Search</Button>
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' danger onClick={this.registerPlant} icon={<PlusCircleOutlined />}>Register Plant</Button>
                            </span>
                        </div>
                    </Col>
                </Row>
                {this.state.totalCount>10?<div style={{textAlign:'right',paddingTop:15}}><Pagination onChange={this.onPaginationChange} size="small" total={this.state.totalCount} showSizeChanger showQuickJumper /></div>:''}
                <div style={{marginTop:10,width:'100%',height:'100%',overflow:'auto'}}>
                    <Table scroll={{x: 1620}} size='small' pagination={false} columns={this.state.columns} dataSource={this.state.plantList} />
                </div>

                <Modal width='800px' title="Register Miner" open={this.state.showRegister} destroyOnClose={true} onOk={this.handleRegisterOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleRegisterCancel}>
                    <Row>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Provider:</span>
                            </Col>
                            <Col span={20}>
                            <Select style={{width:'100%'}} placeholder="Select a provider" onChange={this.onProviderChangeRegister} allowClear options={this.state.providerList} />
                            </Col>
                            <Col span={24} style={{height:10}}></Col>
                            

                        <Col span={24} style={{height:10}}></Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Country:</span></Col>
                        <Col span={20}>
                            {/* <TextArea rows={2} onChange={this.onCountryChange} /> */}
                            <Select mode="tags" style={{width:'100%'}}  onChange={this.onCountryChange} allowClear options={this.state.countryList} 
                                optionRender={(option) => (<Space>{option.data.value}</Space>)}  />
                        </Col>
                        <Col span={24} style={{height:10}}></Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>City:</span></Col>
                        <Col span={20}><TextArea rows={2} onChange={this.onCityChange} /></Col>
                        <Col span={24} style={{height:10}}></Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>deviceModel:</span></Col>
                        <Col span={20}><TextArea rows={2} onChange={this.onDeviceModelChange} /></Col>
                        <Col span={24} style={{height:10}}></Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>deviceName:</span></Col>
                        <Col span={20}><TextArea rows={2} onChange={this.onDeviceNameChange} /></Col>
                        <Col span={24} style={{height:10}}></Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>ratedPower(W):</span></Col>
                        <Col span={20}><Input  type='number'  onChange={this.onRatedPowerChange} /></Col>
                        <Col span={24} style={{height:10}}></Col>

                        <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>clientId:</span></Col>
                        <Col span={20}><TextArea rows={2} onChange={this.onClientIdChange} /></Col>
                        <Col span={24} style={{height:10}}></Col>
                         {
                            this.state.registerPlant.provider == 'common' ? 
                            <>
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>plantform:</span></Col>
                                <Col span={20}><TextArea rows={2} onChange={this.onPlantFormChange} /></Col>
                                <Col span={24} style={{height:10}}></Col>
                            </>:<></>
                         }
                        
                    </Row>
                </Modal>

                <Modal width='800px' title="Register Miner" open={this.state.showCreate} destroyOnClose={true} onOk={this.handleCreateOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleCreateCancel}>
                    <Row>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Provider:</span>
                            </Col>
                            <Col span={20}>
                            <Select style={{width:'100%'}} placeholder="Select a provider"  allowClear options={this.state.providerList} value={this.state.createPlantMiner.provider}/>
                            </Col>

                            <Col span={24} style={{height:10}}></Col>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>deviceId:</span></Col>
                            <Col span={20}><TextArea rows={2}  value={this.state.createPlantMiner.deviceId}/></Col>


                        <Col span={24} style={{height:10}}></Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Panel Link:</span></Col>
                        {/* <Col span={20}><TextArea rows={2} onChange={this.onPanelLinkChange} /></Col> */}
                        <Col span={0}><input id="fileInput" type="file" onChange={this.handleFileChange}  style={{ display: 'none' }} /> </Col>
                        { this.state.uploadedUrl? <Col span={14}><TextArea  type="text"  value={this.state.createPlantMiner.panelLink}  readOnly /> </Col> :
                            this.state.selectedFile? <Col span={14}><TextArea  type="text"  value={this.state.fileName} readOnly /> </Col> :
                            <div></div>}
                        <Col span={3}> <Button type="primary" onClick={this.handleClick}>选择文件</Button> </Col>
                        <Col span={3}> <Button type="primary" onClick={this.handleUpload}>上传文件</Button> </Col>
                        <Col span={24} style={{height:10}}></Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Country:</span></Col>
                        <Col span={20}><TextArea rows={2}  value={this.state.createPlantMiner.country}/></Col>
                        <Col span={24} style={{height:10}}>
                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>City:</span></Col>
                        <Col span={20}><TextArea rows={2}  value={this.state.createPlantMiner.city}/></Col>
                        <Col span={24} style={{height:10}}>
                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>ratedPower:</span>
                        </Col>
                        <Col span={20}>
                            <Input readOnly={this.state.confirmLoading} type='number' placeholder="rated power of this miner" onChange={this.onMinerRatedPowerChange} suffix="W" value={this.state.createPlantMiner.ratedPower}/>
                        </Col>

                        <Col span={24} style={{height:10}}>
                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Expired Time:</span>
                        </Col>
                        <Col span={20}>
                            <Input readOnly={this.state.confirmLoading} type='number' placeholder="expired time of this miner" onChange={this.onMinerExpiredTimeChange} suffix="days"/>
                        </Col>
                    </Row>
                </Modal>
                
            </div>
        )
    }
}

export default ApiPlant;

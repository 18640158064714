import HttpUtils from '../utils/HttpUtils'
import {getFilters} from "../utils/browserUtils";
const baseUrl = process.env.REACT_APP_BASE_API

async function queryMinerList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_sm_list", params,token)
}

async function registerMiners(params){
    console.log(params)
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_sm_register", params,token)
}


const RemoteMinerApi ={
    queryMinerList:queryMinerList,
    registerMiners:registerMiners,
}

export default RemoteMinerApi;

import React from "react";
import { Space, Table, Tag,Row,Col,Button,Modal,Input,Select,message,Pagination } from 'antd';
import {SearchOutlined, EditOutlined, StopOutlined, ExclamationCircleOutlined,PlusOutlined,PlusCircleOutlined,DeleteOutlined} from '@ant-design/icons';
import {copyText, formatDate} from '../../../utils/browserUtils';
import * as ethers from 'ethers'
import TokenServiceApi from '../../../../src/api/TokenServiceApi';
import {getMetaMaskLoginUserAddress} from "../../../utils/metamaskUtils";
const { TextArea } = Input;

class AkreAirdrop extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            showAirdropInfo:false,
            airdropInfo:{
                address:undefined,
                amount:undefined,
                operator:undefined,
            },
            recordList:[],
            totalCount:0,
            recordParam:{
                offset:1,
                limit:25
            }
            
        };
        this.check = this.check.bind(this)
        this.showAirdropInfo = this.showAirdropInfo.bind(this);
        this.showAirdropCancel = this.showAirdropCancel.bind(this);
        this.onAddressChange = this.onAddressChange.bind(this);
        this.onAmountChange = this.onAmountChange.bind(this);
        this.airdropOk = this.airdropOk.bind(this);
        this.onPaginationChange = this.onPaginationChange.bind(this);
        
    }
    async componentWillMount() {
        await this.check();
    }
    
    async check() {
        const columns = [
            {
                title: 'Operator',
                dataIndex: 'operator',
                key: 'operator',
                width: 400,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Address',
                dataIndex: 'address',
                width: 400,
                key: 'address',
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
                
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                width: 150,
                key: 'amount',
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
                
            },
            {
                title: 'Create time',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 250,
                render: (text) => {return <span>{text?formatDate(text):''}</span>},
            },
            {
                title: 'Action',
                key: 'action',
                fixed: 'right',
                render: (_, record) => (
                    <Space size="middle">
                        {
                            (record.status==='unCreated')?<Button
                                type="primary"
                                size='small'
                                disabled={false}
                                icon={<EditOutlined />}
                                onClick={() => {this.handleCreatePlantMIner(record)}}>
                                create
                            </Button>:""

                            
                        }
                    </Space>
                ),
            },
        ];
        this.setState({columns:columns})
        
        //query record
        let resRecordList = [];
        let recordList = [];
        const res = await this.queryAllRecordList();
        
        if(!res.error){
            resRecordList = res.result.list;
            const total = res.result.total;
            this.setState({totalCount:total});
        }
        if(resRecordList.length > 0){
            for(const item of resRecordList){
                const data = {
                    address:item.address,
                    operator:item.operator,
                    amount:item.amount,
                    createTime:item.createTime,
                }
                recordList.push(data)
            }
            this.setState({recordList:recordList})
            
        }else{
            this.setState({recordList:[]})
        }
        

        
    }
    
    formatDateStr(value){
        if(value){
            return value.replace("T"," ").replace(".000Z","")
        }else {
            return ''
        }
    }

    showAirdropInfo(){
        this.setState({showAirdropInfo:true})
    }
    showAirdropCancel(){
        this.setState({showAirdropInfo:false})
    }

    onAddressChange(e){
        let airdropInfo = this.state.airdropInfo
        airdropInfo.address = e.target.value
        this.setState({airdropInfo:airdropInfo})
    }

    onAmountChange(e){
        let airdropInfo = this.state.airdropInfo
        airdropInfo.amount = e.target.value
        this.setState({airdropInfo:airdropInfo})
    }

    async airdropOk(){
        const operator = await getMetaMaskLoginUserAddress();
        let airdropInfo = this.state.airdropInfo
        console.log(airdropInfo)
        if(!operator){
            message.error("请链接钱包")
            return;
        }
        if(!airdropInfo.address){
            message.error("请输入Airdrop的address ！")
            return;
        }
        if(!airdropInfo.amount){
            message.error("请输入Airdrop的数量！")
            return;
        }
        const requestParams = {            
            operator:operator,
            address:airdropInfo.address,
            amount:airdropInfo.amount
        }
        this.setState({confirmLoading:true})
        TokenServiceApi.airdropAkre(requestParams).then(resp => {
            console.log(resp)
            if(!resp.error && !resp.result.error){
                message.success("Airdrop成功！")
                this.setState({showAirdropInfo:false})
                this.check()
            }else {
                if(resp.error){
                    message.error(resp.error.message)
                }else{
                    message.error(resp.result.error.message)
                }
                
            }
            this.setState({confirmLoading:false})
        })
    }

    onPaginationChange(page, pageSize){
        let recordParam = this.state.recordParam
        recordParam.offset = page
        recordParam.limit = pageSize
        this.setState({recordParam:recordParam})
        this.componentWillMount()
    }

    async queryAllRecordList(){
        let res = await TokenServiceApi.queryAirdropRecord(this.state.recordParam); 
        return res;
    }



    

    render() {
        return (
            <div style={{width:'100%',height:'100%',overflow:"hidden"}}>
                <div style={{textAlign:'center',fontSize:'36px',fontWeight:'700',paddingTop:'50px'}}>
                <span>AKRE Airdrop</span>
                </div>
                <Row style={{marginTop:40 ,marginBottom:40,display:'flex' ,alignContent:'center',alignItems:'center'}} >
                    <Col span={1} style={{}}>
                        Address:
                    </Col>
                    <Col span={10}>
                        <Input onChange={this.onAddressChange} size='small' style={{}} placeholder="owner address" allowClear /> 
                    </Col>
                    <Col span={2} >
                    </Col>
                    <Col span={1} style={{paddingLeft:10}}>
                        amount:
                    </Col>
                    <Col span={4}>
                        <Input onChange={this.onAmountChange} type='number' size='small' style={{}} placeholder="amount of token" allowClear />
                    </Col>
                    <Col span={2} >
                    </Col>
                    <Col span={2}>
                        <Button style={{marginLeft:10}} size='small' danger onClick={this.showAirdropInfo} icon={<PlusCircleOutlined />}>Airdrop</Button>
                    </Col>
                </Row>
                {this.state.totalCount>10?<div style={{textAlign:'right',paddingTop:15}}><Pagination onChange={this.onPaginationChange} size="small" total={this.state.totalCount} showSizeChanger showQuickJumper /></div>:''}
                <div style={{marginTop:10,width:'100%',height:'100%',overflow:'auto'}}>
                    <Table scroll={{x: 1620}} size='small' pagination={false} columns={this.state.columns} dataSource={this.state.recordList} />
                </div>


                <Modal width='800px' title="Airdrop Info" open={this.state.showAirdropInfo} destroyOnClose={true} onOk={this.airdropOk} confirmLoading={this.state.confirmLoading} onCancel={this.showAirdropCancel}>
                    <Row>
                        {
                            <Col span={24} style={{height:10}}></Col>
                        }
                        {
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Address:</span></Col>
                        }
                        {
                            <Col span={20}><TextArea rows={1} value={this.state.airdropInfo.address} /></Col>
                        }
                    </Row>
                    <Row>
                        {
                            <Col span={24} style={{height:10}}></Col>
                        }
                        {
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Amount:</span></Col>
                        }
                        {
                            <Col span={20}><TextArea rows={1} value={this.state.airdropInfo.amount} /></Col>
                        }
                    </Row>
                </Modal>
                
            </div>
        )
    }
}

export default AkreAirdrop;

import React from "react";
import {Space, Table, Tag, Row, Col, Button, Modal, Input, message, Radio, Tree, Pagination,Select} from 'antd';
import {PlusCircleOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import MenuApi from "../../../api/menuApi";


class Menu extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            columns:true,
            data:false,
            showAdd:false,
            showEdit:false,
            confirmLoading:false,
            selectedKeys:[],
            menuList:[],
            menuinfo:{
                name:undefined,
                menu:[],
            },
            menuSearch:{

            },
            parentMenuList:[]
        };
        this.check = this.check.bind(this)
        this.onCheck = this.onCheck.bind(this)
        this.showAddModal = this.showAddModal.bind(this)
        this.handleAddCancel = this.handleAddCancel.bind(this)
        this.handleAddOk = this.handleAddOk.bind(this)
        this.onNameChange = this.onNameChange.bind(this)
        this.onSortChange = this.onSortChange.bind(this)
        this.onUrlChange = this.onUrlChange.bind(this)
        this.handleEditOk = this.handleEditOk.bind(this)
        this.handleEditCancel = this.handleEditCancel.bind(this)
        this.handleEdit = this.handleEdit.bind(this)
        this.onStatusChange = this.onStatusChange.bind(this)
        this.onTypeChange = this.onTypeChange.bind(this)
        this.onShowChange = this.onShowChange.bind(this)
        this.checkMenu = this.checkMenu.bind(this)
        this.onPaginationChange = this.onPaginationChange.bind(this)
        this.onParentMenuChange = this.onParentMenuChange.bind(this)
        this.updateParentMenu = this.updateParentMenu.bind(this)
    }
    async componentWillMount() {
        await this.check();
        await this.updateParentMenu();
    }
    async queryMenuList(menuSearch){
        let menuList = await MenuApi.queryAllMenuList(menuSearch);
        return menuList;
    }
    async check() {
        const columns = [
            {
                width: 180,
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Parent Menu',
                dataIndex: 'parent_name',
                key: 'parent_name',
                width: 180,
            },
            {
                title: 'Type',
                key: 'type',
                dataIndex: 'type',
                width: 80,
                render: (type) => {
                    let text = '目录'
                    if(type == 1){
                        text = '目录'
                    }
                    if(type == 2){
                        text = '菜单'
                    }
                    if(type == 3){
                        text = '按钮'
                    }
                    return (
                        <span>{text}</span>
                    );
                }
            },
            {
                title: 'Sort',
                dataIndex: 'sort',
                key: 'sort',
                width: 80,
            },
            {
                title: 'Url',
                dataIndex: 'url',
                key: 'url',
                width: 200,
            },
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                width: 80,
                render: (status) => {
                    let color = 'red'
                    let text = '已禁用'
                    if(status == 1){
                        color = 'green'
                        text = '正常'
                    }
                    return (
                        <Tag color={color} key={text}>{text}</Tag>
                    );
                }
            },
            {
                title: '是否显示',
                key: 'show',
                width: 80,
                dataIndex: 'show',
                render: (status) => {
                    let color = 'red'
                    let text = '不显示'
                    if(status == 1){
                        color = 'green'
                        text = '显示'
                    }
                    return (
                        <Tag color={color} key={text}>{text}</Tag>
                    );
                }
            },
            {
                title: 'UpdateTime',
                dataIndex: 'updateTime',
                key: 'updateTime',
                width: 180,
            },
            {
                title: 'CreateTime',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 180,
            },
            {
                title: 'Action',
                fixed: 'right',
                key: 'action',
                width: 180,
                render: (_, record) => (
                    <Space size="middle">
                        <Button
                            type="primary"
                            size='small'
                            icon={<EditOutlined />}
                            onClick={() => {
                                this.handleEdit(record)
                            }}>
                            Edit
                        </Button>
                        <Button size='small' type="primary" icon={<DeleteOutlined />} onClick={()=>{
                            Modal.confirm({
                                title: 'Are you sure delete menu：',
                                icon: <ExclamationCircleOutlined />,
                                content: record.name,
                                okText: 'Yes',
                                cancelText: 'Cancel',
                                onOk:()=>{this.handleDelete(record)}
                            });
                        }}>
                            Delete
                        </Button>
                    </Space>
                ),
            },
        ];
        this.setState({columns:columns})
        // 查询菜单列表
        let menuSearch = this.state.menuSearch
        let resp = await this.queryMenuList(menuSearch);
        if(!resp.error){
            let result = resp.result
            if(!result){
                return;
            }
            const data=[]
            if(result.list.length > 0){
                this.setState({totalCount:result.count})
                result.list.forEach(item =>{
                    const user = {
                        id:item.id,
                        key:item.id,
                        parent_id:item.parentId,
                        parent_name:item.parentName,
                        name:item.name,
                        type:item.type,
                        sort:item.sort,
                        icon:item.icon,
                        url:item.url,
                        show:item.show,
                        status:item.status,
                        updateTime:item.updateTime.replace("T"," ").replace(".000Z",""),
                        createTime:item.createTime.replace("T"," ").replace(".000Z",""),
                    }
                    data.push(user)
                })
            }
            this.setState({data:data})
        }else {
            this.setState({data:undefined})
        }
    }
    onCheck(checkedKeys, e){
        this.setState({selectedKeys:checkedKeys})
        let menuinfo = this.state.menuinfo
        menuinfo.menu = checkedKeys
        this.setState({menuinfo:menuinfo})
    }
    handleAddCancel(){
        this.setState({showAdd:false})
        this.setState({selectedKeys:[]})
    }
    onNameChange(e){
        const name = e.target.value
        let menuinfo = this.state.menuinfo
        menuinfo.name = name
        this.setState({menuinfo:menuinfo})
    }
    onSortChange(e){
        let menuinfo = this.state.menuinfo
        menuinfo.sort = e.target.value
        this.setState({menuinfo:menuinfo})
    }
    onUrlChange(e){
        let menuinfo = this.state.menuinfo
        menuinfo.url = e.target.value
        this.setState({menuinfo:menuinfo})
    }
    handleAddOk(){
        const menuinfo = this.state.menuinfo;
        console.log("Add menuinfo:"+JSON.stringify(menuinfo))
        if(!menuinfo.name){
            message.error("菜单名称不能为空！")
            return;
        }
        MenuApi.addMenu(menuinfo).then(resp => {
            console.log(resp)
            if(!resp.error){
                message.success("菜单添加成功！")
                this.setState({showAdd:false})
                this.check()
                this.updateParentMenu()
            }else {
                message.error(resp.error.message)
            }
        })
    }
    checkMenu(menus){
        const menuList = this.state.menuList
        menus.forEach(item => {
            menuList.forEach(menu => {

            })
        })
    }
    async handleDelete(record) {
        MenuApi.delMenu({menuId: record.id}).then(resp => {
            if (!resp.error) {
                message.success("菜单删除成功！")
                this.check();
                this.updateParentMenu();
            } else {
                message.error(resp.error.message)
            }
        })
    }
    showAddModal(){
        const menuinfo={
            show:1,
            sort:1,
            type:1,
            status:1
        }
        this.setState({menuinfo:menuinfo})
        this.setState({showAdd:true})
    }
    handleEdit(menu){
        if(menu){
            console.log(menu)
            const menuinfo={
                id:menu.id,
                name:menu.name,
                parent_id:menu.parent_id,
                parent_name:menu.parent_name,
                show:menu.show,
                sort:menu.sort,
                type:menu.type,
                status:menu.status,
                url:menu.url
            }
            this.setState({menuinfo:menuinfo})
            this.setState({showEdit:true})
        }else {
            console.log("menu info failed!")
        }
    }
    handleEditCancel(){
        this.setState({showEdit:false})
    }
    onStatusChange(e){
        let menuinfo = this.state.menuinfo
        menuinfo.status = e.target.value
        this.setState({menuinfo:menuinfo})
    }
    onTypeChange(e){
        let menuinfo = this.state.menuinfo
        menuinfo.type = e.target.value
        this.setState({menuinfo:menuinfo})
    }
    onShowChange(e){
        let menuinfo = this.state.menuinfo
        menuinfo.show = e.target.value
        this.setState({menuinfo:menuinfo})
    }
    handleEditOk(){
        let menuinfo = this.state.menuinfo;
        console.log("Edit menuinfo to:"+JSON.stringify(menuinfo))
        if(!menuinfo.name){
            message.error("菜单名称不能为空！")
            return;
        }
        MenuApi.updateMenu(menuinfo).then(resp => {
            console.log(resp)
            if(!resp.error){
                message.success("菜单修改成功！")
                this.setState({showEdit:false})
                this.check()
                this.updateParentMenu()
            }else {
                message.error(resp.error.message)
            }
        })
    }
    onPaginationChange(page, pageSize){
        let menuSearch = this.state.menuSearch
        menuSearch.offset = page
        menuSearch.size = pageSize
        this.setState({menuSearch:menuSearch})
        this.check();
    }
    onParentMenuChange(value){
        let menuinfo = this.state.menuinfo;
        console.log(value)
        menuinfo.parent_id = value
        this.setState({menuinfo:menuinfo})
    }
    updateParentMenu(){
        MenuApi.queryParentMenu().then(resp => {
            if(!resp.error){
                let parentMenuList = []
                resp.result.forEach(item => {
                    const parentMenu = {
                        value:item._id,
                        label:item.name,
                    }
                    parentMenuList.push(parentMenu)
                })
                this.setState({parentMenuList:parentMenuList})
            }
        })
    }

    render() {
        return (
            <div style={{width:'100%',height:'100%',overflow:"hidden"}}>
                <div>
                    <Row>
                        <Col span={12}>
                            <span style={{fontWeight:'bold',fontSize:18}}>
                                Menu Manage
                            </span>
                        </Col>
                        <Col span={12}>
                            <div style={{textAlign:'right'}}>
                                <Button size='small' onClick={this.showAddModal} type="primary" icon={<PlusCircleOutlined />}>Add</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
                {
                    this.state.totalCount>10?<div style={{textAlign:'right',paddingTop:15}}>
                        <Pagination onChange={this.onPaginationChange} size="small" total={this.state.totalCount} showSizeChanger showQuickJumper />
                    </div>:''
                }
                <div style={{marginTop:10,width:'100%',height:'100%',overflow:'auto'}}>
                    <Table scroll={{x: 1150}} pagination={false} size='small' columns={this.state.columns} dataSource={this.state.data} />
                </div>
                <Modal
                    title="Add Menu"
                    open={this.state.showAdd}
                    destroyOnClose={true}
                    onOk={this.handleAddOk}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleAddCancel}
                    width = {600}>
                    <div>
                        <Row>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Name:</span>
                            </Col>
                            <Col span={20}>
                                <Input onChange={this.onNameChange} value={this.state.menuinfo.name}/>
                            </Col>
                            <Col span={24} style={{height:10}}>

                            </Col>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Parent Menu:</span>
                            </Col>
                            <Col span={20}>
                                <Select  style={{width:'100%'}} placeholder="Select a Parent Menu" onChange={this.onParentMenuChange} allowClear options={this.state.parentMenuList} />
                            </Col>
                            <Col span={24} style={{height:10}}>

                            </Col>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Sort:</span>
                            </Col>
                            <Col span={20}>
                                <Input type='number' onChange={this.onSortChange} value={this.state.menuinfo.sort}/>
                            </Col>
                            <Col span={24} style={{height:10}}>

                            </Col>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Type:</span>
                            </Col>
                            <Col span={20}>
                                <Radio.Group style={{marginTop:5}} onChange={this.onTypeChange} value={this.state.menuinfo.type}>
                                    <Radio value={1}>目录</Radio>
                                    <Radio value={2}>菜单</Radio>
                                </Radio.Group>
                            </Col>
                            <Col span={24} style={{height:10}}>

                            </Col>
                            {
                                this.state.menuinfo.type==2?(<Col  span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Url:</span></Col>):null
                            }
                            {
                                this.state.menuinfo.type==2?(<Col span={20}><Input onChange={this.onUrlChange} value={this.state.menuinfo.url}/></Col>):null
                            }
                            {
                                this.state.menuinfo.type==2?(<Col span={24} style={{height:10}}>
                                </Col>):null
                            }
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>是否显示:</span>
                            </Col>
                            <Col span={20}>
                                <Radio.Group style={{marginTop:5}} onChange={this.onShowChange} value={this.state.menuinfo.show}>
                                    <Radio value={1}>显示</Radio>
                                    <Radio value={2}>隐藏</Radio>
                                </Radio.Group>
                            </Col>
                            <Col span={24} style={{height:10}}>

                            </Col>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Status:</span>
                            </Col>
                            <Col span={20}>
                                <Radio.Group style={{marginTop:5}} onChange={this.onStatusChange} value={this.state.menuinfo.status}>
                                    <Radio value={1}>正常</Radio>
                                    <Radio value={2}>禁用</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </div>
                </Modal>
                <Modal
                    title="Edit Menu"
                    open={this.state.showEdit}
                    onOk={this.handleEditOk}
                    destroyOnClose={true}
                    width = {600}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleEditCancel}>
                    <div>
                        <Row>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Name:</span>
                            </Col>
                            <Col span={20}>
                                <Input onChange={this.onNameChange} value={this.state.menuinfo.name}/>
                            </Col>
                            <Col span={24} style={{height:10}}>

                            </Col>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Parent Menu:</span>
                            </Col>
                            <Col span={20}>
                                <Select style={{width:'100%'}} placeholder="Select a Parent Menu" onChange={this.onParentMenuChange} allowClear options={this.state.parentMenuList} />
                            </Col>
                            <Col span={24} style={{height:10}}>

                            </Col>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Sort:</span>
                            </Col>
                            <Col span={20}>
                                <Input type='number' onChange={this.onSortChange} value={this.state.menuinfo.sort}/>
                            </Col>
                            <Col span={24} style={{height:10}}>

                            </Col>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Type:</span>
                            </Col>
                            <Col span={20}>
                                <Radio.Group style={{marginTop:5}} onChange={this.onTypeChange} value={this.state.menuinfo.type}>
                                    <Radio value={1}>目录</Radio>
                                    <Radio value={2}>菜单</Radio>
                                </Radio.Group>
                            </Col>
                            <Col span={24} style={{height:10}}>

                            </Col>
                            {
                                this.state.menuinfo.type==2?(<Col  span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Url:</span></Col>):null
                            }
                            {
                                this.state.menuinfo.type==2?(<Col span={20}><Input onChange={this.onUrlChange} value={this.state.menuinfo.url}/></Col>):null
                            }
                            {
                                this.state.menuinfo.type==2?(<Col span={24} style={{height:10}}>
                                </Col>):null
                            }
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>是否显示:</span>
                            </Col>
                            <Col span={20}>
                                <Radio.Group style={{marginTop:5}} onChange={this.onShowChange} value={this.state.menuinfo.show}>
                                    <Radio value={1}>显示</Radio>
                                    <Radio value={2}>隐藏</Radio>
                                </Radio.Group>
                            </Col>
                            <Col span={24} style={{height:10}}>

                            </Col>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                <span style={{lineHeight:'32px'}}>Status:</span>
                            </Col>
                            <Col span={20}>
                                <Radio.Group style={{marginTop:5}} onChange={this.onStatusChange} value={this.state.menuinfo.status}>
                                    <Radio value={1}>正常</Radio>
                                    <Radio value={2}>禁用</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default Menu;
